@mixin _button() {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font: normal normal 16px/16px $f-avenir;
    letter-spacing: 0.025em;
    min-width: 130px;
    padding: 11px 41px;
    text-decoration: none;
    text-transform: uppercase;


    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }

    &:focus,
    &:hover {
        outline: 0;
    }

    &[disabled],
    &.disabled {
        background: $clr_input_notactive;
        border-color: $clr_input_notactive;
        color: $clr_dark_mid;
        cursor: default;
        pointer-events: none;

        &:active,
        &:focus,
        &:hover {
            background: $clr_input_notactive;
            border-color: $clr_input_notactive;
            color: $clr_dark_mid;
        }

        span {
            border-color: inherit;
        }
    }

    &.small {
        font: normal normal 14px/14px $f-avenir;
        letter-spacing: 0.015em;
        min-width: 90px;
        padding: 7px 19px;
    }

    &#update-cart {
        margin-left: 20px;
    }

    &.large {
        font-size: 16px;
        line-height: 16px;
        height: 50px;
        padding: 7px 33px 10px;

    }

    &.no-left-padding {
        padding-left: 0;
    }

}

@mixin primary-button() {
    background: $clr_brand_black;
    border: 1px solid $clr_brand_black;
    color: $clr_white;
    @include _button();

    &:active,
    &:focus,
    &:hover {
        background: $clr_white;
        border-color: $clr_brand_black;
        color: $clr_brand_black;
    }
}

@mixin outline-button() {
    background: $clr_white;
    border: 1px solid $clr_brand_black;
    color: $clr_brand_black;
    @include _button();

    &:active,
    &:focus,
    &:hover {
        background: $clr_brand_black;
        border-color: $clr_white;
        color: $clr_white;
    }
}

@mixin tertiary-button() {
    background: transparent;
    border: 0;
    color: $black;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font: normal normal 16px/16px $f-avenir;
    letter-spacing: 0.025em;
    min-width: initial;
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;

    &:active,
    &:focus,
    &:hover {
        text-decoration: underline;
    }

    &[disabled],
    &.disabled {
        background: transparent;
        color: $clr_dark_mid;
        cursor: default;
        pointer-events: none;

        &:active,
        &:focus,
        &:hover {
            background: transparent;
            color: $clr_dark_mid;
        }
    }

    &.small {
        font: normal normal 14px/14px $f-avenir;
        letter-spacing: 0.015em;
    }
}

@mixin video-button() {
    @include _button();

    &::after {
        content: $icon-button-play;
        position: absolute;
        top: 8px;
        right: 7px;
        @extend %icon-font;
        line-height: normal;
        font-size: 23px;
        margin-left: 18px;
        display: block;
    }

    &:hover::after {
        transform: rotateX(0deg);
    }
}

// used for text-only buttons (arrows)
@mixin plain-button() {
    @include _button();

    background: none;
    border: none;
    color: $coal;
    padding: 0 32px 0 0;
    height: 39px;

    &::after {
        content: " ";
        background: url("../images/button-arrow.svg") no-repeat 6px 14px;
        filter: $filter-black-to-coal;
        width: 26px;
        height: 39px;
        position: absolute;
        top: 0;
        right: 8px;
        transition: all 0.4s ease;
        transform-style: preserve-3d;
    }

    &:focus,
    &:hover {
        background: none;
        border: none;
        color: $black-two;

        &::after {
            transform: rotateX(180deg);
            filter: $filter-black-to-black-two;
        }
    }

    &[style*="color: #FFFFFF"],
    &[style*="color:#FFFFFF"],
    &[style*="color: #ffffff"],
    &[style*="color:#ffffff"],
    &[style*="color: #FFF"],
    &[style*="color:#FFF"],
    &[style*="color: #fff"],
    &[style*="color:#fff"] {
        &::after {
            filter: $filter-black-to-white;
        }
        &:focus,
        &:hover {
            &::after {
                filter: $filter-black-to-white;
            }
        }
    }

    &[style*="color: #2e2d2a"],
    &[style*="color:#2e2d2a"],
    &[style*="color: #2E2D2A"],
    &[style*="color:#2E2D2A"] {
        color: $coal !important;

        &::after {
            filter: $filter-black-to-coal;
        }

        &:focus,
        &:hover {
            color: $black-two !important;
            &::after {
                filter: $filter-black-to-black-two;
            }
        }
    }
}

@mixin plain-button-white() {
    @include plain-button();
    color: $white;

    &::after {
        filter: $filter-black-to-white;
    }

    &:focus,
    &:hover {
        color: $white;

        &::after {
            filter: $filter-black-to-white;
        }
    }
}
