.mini-cart-total {
    position: static;

    .mini-cart-link {

        i::before {
            display: none;
            font-size: 20px;
            content: $icon-cart-buggie;
            color: #000;
        }
    }

    .mini-cart-empty {
        transition: color $hover-transition-duration $hover-transition-function;

        &:hover {
            color: $brand-primary-dark;
            text-decoration: none;
        }

    }
}

// Hack for IE specifically
html.IE .mini-cart-total .mini-cart-link .minicart-quantity {
    padding-top: 0.1px;
}

// Hack for IE 10- specifically
html.IE.ten .mini-cart-total .mini-cart-link .minicart-quantity,
html.IE.nine .mini-cart-total .mini-cart-link .minicart-quantity {
    padding-top: 0.1px;
    padding-right: 0.1px;
}

// Hack for IE Edge specifically
html.IE.edge .mini-cart-total .mini-cart-link .minicart-quantity {
    padding-right: 0.3px;
}

body.has-image .mini-cart-total .mini-cart-empty .minicart-quantity {
    color: $brand-primary-dark;
    background-color: $white;
}

.mini-cart-content {
    background-color: rgba(46, 45, 42, 0.8); // IE fallback
    background: $clr_white;
    @include blur-backdrop();
    color: $clr_brand_black;
    display: flex;
    position: fixed;
    top: 0;
    height: 100vh;
    max-width: 375px;
    z-index: 99992;
    width: 100%;
    right: -375px;
    transition: all $navigation-transition-duration $navigation-transition-function;
    box-sizing: content-box;
    flex-flow: column nowrap;
    overflow: hidden;

    @supports (-webkit-touch-callout: none) {
        height: calc(100vh - 60px);
    }

    &.open {
        right: 0;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 1;
        z-index: 12;

        &::before {
            @extend %icon-font;
            font-size: 21px;
            content: $icon-close-x;
            color: $brand-v2-off-black;
            background: transparent;
            padding: 0;
            line-height: 1;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .equal-pay-logo {
        width: 28px;
        padding-right: 10px;
        margin-bottom: -5px;
        padding-left: 1px;
    }

    .equal-pay-price {
        margin-top: 20px;
        padding: 20px 0 10px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            width: calc(100% - 40px);
            margin: 0 auto;
            border-bottom: 1px solid $brand-light-grey-2;
        }
    }

    .equal-pay-price,
    .affirm-as-low-as {
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        font: normal normal 12px/18px $f-primary;
        color: $brand-primary-light;
    }

    .affirm-as-low-as {
        margin: 0;
    }

    .equal-pay-link,
    .affirm-modal-trigger {
        text-decoration: none;
        border-bottom: 1px solid $brand-light-grey-2;
        margin-left: 5px;
        display: inline-block;
    }
}

.mini-cart-header {
    padding: 35px 16px 16px;
    text-transform: uppercase;
    z-index: 11;
    font: 500 normal 28px/28px $f-primary;
    letter-spacing: 0.05em;
    color: $brand-v2-off-black;
    text-align: center;
    background: $white;
}

.promo-approaching {
    background: $alabaster;
    color: $black;
    padding: 15px;

    &__message {
        font: normal normal 14px/18px $f-avenir;
        letter-spacing: 0.05em;
    }

    &__progress {
        background: $alto;
        height: 6px;
        margin-top: 15px;
        max-width: 322px;
    }

    &__progress-bar {
        background: $goblin;
        height: 100%;
        width: var(--promo-bar-width);
    }
}

.mini-cart-products {
    z-index: 11;
    overflow: auto;
    background: $white;

    .mini-cart-product {
        padding: 20px;

        + .mini-cart-product {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 20px;
                right: 20px;
                height: 1px;
                background: $very-middle-gray-v2;
            }
        }
    }
}

.mini-cart-product {
    overflow: hidden;
    padding: 8px 20px 10px;
    position: relative;

    &:last-of-type {
        border: none;
    }

    &__top {
        display: flex;
        justify-content: space-between;

        .mini-cart-name,
        .mini-cart-pricing {
            width: auto;
            float: none;
            margin: 0 0 5px;
        }
    }

    &__main {
        display: flex;

        .mini-cart-image {
            float: none;
            width: 90px;
            padding: 0;
            margin: 0 5px 0 0;
        }
    }

    &__main-details {
        display: flex;
        flex-direction: column;

        .mini-cart-attributes,
        .mini-cart-product-options,
        .mini-cart-qty {
            font: 400 normal 12px/18px $f-primary;
            color: $brand-v2-off-black;
            letter-spacing: 0.05em;
            width: auto;
            float: none;
        }

        .fpp {
            &__see-details {
                display: none;
            }
        }
    }
}

.mini-cart-qty {
    .label {
        font-weight: 800;
    }
}

.mini-cart-image {
    float: left;
    width: 85px;
    min-height: 85px;
    background: $white;
    padding: 5px;
    box-sizing: border-box;
    margin-top: 2px;

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.mini-cart-name {
    float: right;
    width: percentage(162/280);
    margin-bottom: 2px;
    margin-right: 15px;

    a {
        @extend %anchor-no-decoration;
        color: inherit;
        font: inherit;
        letter-spacing: inherit;
        text-transform: uppercase;
    }

    @include bp(min-width, $bp-medium) {
        width: percentage(162/266);
        margin-right: 0;
    }
}

.mini-cart-attributes,
.mini-cart-product .product-availability-list {
    float: right;
    width: percentage(162/280);
    margin-right: 15px;

    @include bp(min-width, $bp-medium) {
        width: percentage(162/266);
        margin-right: 0;
    }
}

.mini-cart-pricing {
    clear: right;
    float: right;
    font-size: 0.9em;
    width: percentage(162/280);
    margin-right: 15px;

    @include bp(min-width, $bp-medium) {
        width: percentage(162/266);
        margin-right: 0;
    }
}

.mini-cart-totals {
    text-align: center;
    z-index: 11;
    margin-top: auto;
    padding: 25px 23px 40px;
    background: $brand-section-grey;

    @include bp(min-width, $bp-medium) {
        padding-bottom: 20px;
    }
}

.mini-cart-attributes,
.mini-cart-pricing,
.mini-cart-totals {
    .cart-summary-vip-atc {
        .add-cc-to-cart {
            margin-bottom: 20px;
            width: 100%;
        }
    }

    .cancelterms {
        width: 100%;
    }
}

.mini-cart-savings {
    &.total-savings {
        display: flex;
        justify-content: space-between;

        p {
            padding: 10px 0;
            letter-spacing: 0.05em;
            margin: 0 8px 0 0;

            + p {
                margin-right: 0;
            }
        }
    }

    .mini-cart-slot {
        p {
            color: $brand-v2-off-black;
            font: 800 normal 18px/22px $f-primary;
            letter-spacing: 0.05em;
            margin-top: 0;

            .can-savings {
                color: $california-gold;
            }
        }
    }
}

.mini-cart-subtotals {
    color: $black;
    display: flex;
    padding: 15px 0;
    justify-content: space-between;

    > span {
        letter-spacing: 0.05em;
    }
}

.mini-cart-slot {
    letter-spacing: 0;
    font: normal normal 16px/24px $f-classical;
    color: $black;

    p {
        font: normal normal 16px/24px $f-classical;
    }
}

.mini-cart-price {
    margin-left: 5px;
}

.mini-cart-link-cart {
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
    font: normal normal 16px/36px $f-primary;
    text-transform: uppercase;
    height: 40px;
    box-sizing: border-box;
    padding: 0 33px;
    position: relative;
    letter-spacing: .5px;
    text-decoration: none;
    width: 100%;
}

.fp-mini-cart {
    &__wrapper {
        display: none;
        flex-flow: column nowrap;
        overflow: auto;
        background: $white;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 15;

        &.open {
            display: flex;
        }
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 1;
        z-index: 12;
        font-size: 24px;

        &::before {
            @extend %icon-font;
            font-size: 21px;
            content: $icon-close-x;
            color: $brand-v2-off-black;
            background: transparent;
            padding: 0;
            line-height: 1;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.75;
        }
    }

    &__header {
        padding: 54px 16px 30px;
        z-index: 11;
        font: 800 normal 14px/20px $f-avenir;
        letter-spacing: 0.5px;
        color: $brand-v2-off-black;
        text-align: center;
    }

    &__products {
        min-height: 175px;

        .mini-cart-pricing {
            display: none;
        }
    }

    &__editable-container {
        z-index: 11;
        margin-top: auto;
    }

    .mini-cart-product {
        display: none;

        &.last-added {
            display: block;
        }
    }
}

.fp-mc-asset {
    padding: 20px 20px 50px 14px;
    color: $black;

    @include bp(min-width, $bp-medium) {
        padding-bottom: 35px;
    }

    &__header {
        font: normal normal 20px/18px $f-avenir;
        letter-spacing: 0.5px;
        text-align: center;
        margin-bottom: 10px;
    }

    &__plan-wrapper {
        background: $light-smoke;
        padding: 12px 4px 14px 8px;
        margin-bottom: 6px;
    }

    &__plan-top {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 20px;
    }

    &__plan-top-img {
        margin-right: 16px;
    }

    &__plan-top-main-container {
        flex: 1;
    }

    &__plan-top-main {
        font: 500 normal 12px/18px $f-avenir;
        letter-spacing: 1px;
    }

    &__plan-top-description {
        font: normal normal 10px/14px $f-avenir;
        color: $brand-v2-dark-gray;
    }

    &__plan-top-price {
        font: 500 normal 12px/18px $f-avenir;
        margin-left: 8px;
        text-align: right;
    }

    &__plan-ul {
        font: normal normal 12px/16px $f-avenir;
        padding-right: 16px;
    }

    &__plan-li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        +.fp-mc-asset__plan-li {
            margin-top: 16px;
        }

        .checkmark {
            margin: 2px 8px 0 0;
        }
    }

    &__plan-li-description {
        font: normal normal 10px/14px $f-avenir;
        color: $brand-v2-dark-gray;
    }

    &__actions {
        max-width: 304px;

        &__add-wrapper {
            margin: 20px auto 0;
        }

        &__no-wrapper {
            margin: 0 auto 14px;
        }

        .button {
            width: 100%;
        }
    }

    &__tc {
        font: normal normal 10px/12px $f-avenir;
        color: $brand-v2-dark-gray;
        padding-left: 6px;

        a {
            font-weight: 500;
            color: inherit;
        }
    }
}
