.ui-dialog {
    .modal-header-3 {
        font: normal normal 26px/26px $f-classical-normal;
        margin: 0 auto;
    }

    .modal-header-4 {
        font: normal normal 14px/14px $f-classical-normal;
        text-transform: none;
    }

    .modal-paragraph {
        font: normal normal 14px/18px $f-primary;
    }

    .modal-ul {
        font: normal normal 16px/28px $f-primary;
    }

    .modal-button {
        display: block;
        border: 1px solid $black;
        color: $white;
        background: $black;
        font: normal normal 12px/16px $f-primary;
        padding: 12px 30px;
        text-decoration: none;
        min-width: 136px;
        height: auto;
        text-align: center;

        &:hover {
            background: transparent;
            color: $black;
        }
    }

    .modal-link {
        border-bottom: 1px $black solid;
        color: $black;
        font: normal normal 12px/18px $f-primary;
        text-decoration: none;

        &:hover {
            border-bottom: none;
        }
    }

    .modal-header-3,
    .modal-header-4,
    .modal-paragraph,
    .modal-ul {
        text-transform: none;
        text-align: left;
        padding-bottom: 15px;
    }

}

@include bp(min-width, $bp-large) {
    .ui-dialog {
        .modal-header-3 {
            font-size: 30px;
            line-height: 30px;
        }

        .modal-header-4 {
            font-size: 20px;
            line-height: 24px;
        }
    }
}
