.footer-container-outer {
    padding: 0 23px;
    @include bp(min-width, $bp-custom-large) {
        padding: 0 3%;
    }
}


.footer-copyright-asset-inner {
    padding: 0 23px;
    @include bp(min-width, $bp-custom-large) {
        padding: 0 3%;
    }

}

.footer-copyright-asset {
    margin: 0 auto;
    max-width: 1130px;
    padding: 70px 0 10px;
    text-align: center;

    @include bp(min-width, $bp-custom-large) {
        text-align: left;
    }

    .footer-copy-links {
        font: inherit;
        color: inherit;
        letter-spacing: inherit;
        margin: 0 20px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .copyright-text {
        @include bp(max-width, $bp-custom-large) {
            display: block;
        }

        #titleHere {
            margin-right: 20px;

            @include bp(max-width, $bp-custom-large) {
                margin-right: 0;
                padding-right: 10px;
            }
        }
    }
}

.footer-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 70px 0 0;

    @include bp(max-width, $bp-custom-large) {
        padding: 48px 0 0;
        border-top: 1px solid $silver;
    }

    .footer-logo {
        padding-bottom: 30px;

        @include bp(min-width, $bp-custom-large) {
            padding-bottom: 40px;
        }

        img {
            width: 142px;
            height: 54px;

            &.trade-membership-logo {
                height: auto;
            }
        }
    }

    .footer-row,
    .footer-item-middle-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .footer-item-middle-container {
        max-width: 1280px;
        margin: 0 auto;

        .footer-item {
            @include bp(max-width, $bp-custom-large) {
                padding-bottom: 28px;
            }
        }
    }

    .footer-row {
        @include bp(min-width, $bp-custom-large) {
            justify-content: space-between;
        }
    }

    .footer-label {
        font: normal normal 36px/38px $f-classical-normal;
    }

    .footer-img-container {
        margin: 0;
        transition: 0.3s;

        figcaption {
            padding: 10px;
            padding-left: 0;
            transition: 0.3s;

            @include bp(min-width, $bp-medium) {
                padding: 20px;
                padding-left: 0;
            }

            @include bp(min-width, $bp-xlarge) {
                padding: 25px;
                padding-left: 0;
            }
        }

        &:hover {
            background: black;
            color: white;

            figcaption {
                padding-left: 10px;

                @include bp(min-width, $bp-medium) {
                    padding-left: 20px;
                }

                @include bp(min-width, $bp-xlarge) {
                    padding-left: 25px;
                }
            }
        }
    }

    .footer-img {
        width: 100%;
        display: block;
    }

    .footer-top-item-column {
        flex: 0 0 50%;
        width: 50%;
        padding: 0 0 20px;
        letter-spacing: 1.3px;
        text-decoration: none;
        font: normal normal 12px $f-neue-bold;

        @include bp(min-width, $bp-custom-large) {
            font-size: 15px;
            flex: 0 0 25%;
            padding: 0 25px 44px;
            width: 25%;
        }

        @include bp(max-width, $bp-custom-large) {
            &:nth-child(2n) {
                padding-left: 10px;
            }

            &:nth-child(2n-1) {
                padding-right: 10px;
            }
        }
    }

    .footer-item-middle {
        flex: 0 0 100%;
        max-width: 100%;

        @include bp(min-width, $bp-custom-large) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        &.active {
            h5 {
                &::after {
                    transform: rotateX(180deg);
                }
            }
        }

        h5 {
            position: relative;
            font-weight: 900;

            @include bp(min-width, $bp-custom-large) {
                padding-bottom: 17px;
            }

            &::after {
                transition: transform $default-transition-duration $default-transition-function;
                content: $icon-select-down-arrow;
                position: absolute;
                top: 50%;
                margin-top: -11.8px;
                right: 0;
                height: 21px;
                @extend %icon-font;
                display: block;
                font-size: 8px;

                @include bp(min-width, $bp-custom-large) {
                    display: none;
                }
            }

            &.email-sign-up-title,
            &.social-icon-title {
                padding-bottom: 7px;

                @include bp(min-width, $bp-custom-large) {
                    padding: 0;
                }

                &::after {
                    display: none;
                }
            }
        }

        .email-sign-up-text {
            margin: 0;

            @include bp(min-width, $bp-custom-large) {
                padding-bottom: 2px;
            }
        }
    }

    .footer-item-top {
        @include bp(max-width, $bp-custom-large) {
            padding-bottom: 10px;
        }
        .menu-slot {
            width: 100%;
            display: block;
            padding: 0;
            margin: 0;
        }
    }

    .footer-top-item-title {
        color: $black;
        font: normal normal 20px/24px $f-classical-normal;
        margin: 0;
        padding: 0 0 6px;
        letter-spacing: -0.01em;
        @include bp(max-width, $bp-custom-large) {
            max-width: 115px;
            margin: 0 auto;
            letter-spacing: normal;
        }

        sup {
            font-size: 50%;
        }
    }

    .footer-top-item-text {
        color: $black;
        font: normal normal 12px/18px $f-primary;
        margin: 0;
        padding: 0 0 6px;
        letter-spacing: 0.05em;
        @include bp(max-width, $bp-custom-large) {
            display: none;
            letter-spacing: normal;
        }

    }

    .footer-top-item-link {
        border-bottom: 1px $black solid;
        color: $black;
        font: normal normal 12px/18px $f-primary;
        text-decoration: none;
        letter-spacing: 0.05em;
        @include bp(max-width, $bp-custom-large) {
            letter-spacing: normal;
        }
        &:hover {
            border-color: transparent;
            color: $black;
            text-decoration: none;
        }

    }

    .footer-items-left {
        @include bp(min-width, $bp-custom-large) {
            flex: 0 0 65%;
            max-width: 65%;
        }
    }

    .footer-items-right {
        @include bp(min-width, $bp-custom-large) {
            flex: 0 0 35%;
            max-width: 35%;
            border-left: 1px solid $silver;
        }
    }

    .footer-items-left-column {
        @include bp(min-width, $bp-custom-large) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
            padding: 0 6px;
        }
    }

    .menu-footer {
        padding: 0;
        margin: 0;
        display: block;

        li {
            display: block;
            line-height: 34px;
            float: none;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .footer-item-middle-container-inner {
        @include bp(min-width, $bp-custom-large) {
            padding-top: 30px;
        }
    }

    .footer-item-middle-newsletter {
        @include bp(min-width, $bp-custom-large) {
            padding-left: 15%;
            position: relative;
            top: -7px;
        }
        .menu-slot {
            width: 100%;
            padding: 0;
            margin: 0;
            display: block;
        }
    }

    .footer-social {
        padding-top: 26px;
        @include bp(min-width, $bp-custom-large) {
            padding-top: 36px;
        }

        .footer-social-link {
            color: $black;
            font: normal normal 12px/18px $f-primary;
            text-decoration: none;
            margin-left: 0;
            @include bp(min-width, $bp-custom-large) {
                font: normal normal 16px/28px $f-primary;
                margin-left: 0;
                letter-spacing: 0.05em;
            }
            &:hover {
                text-decoration: underline;
            }

            &:first-child {
                margin-left: 0;
            }

        }

        .menu-slot {
            display: block;
        }
    }

    .sharing {
        display: flex;
        justify-content: space-between;
        max-width: 380px;
        width: fit-content;
        margin-right: auto;
    }
}

.IE {
    .footer-item.last {
        .sharing {
            flex-direction: row;
            a {
                flex-basis: 40px;
                &.linked-in {
                    height: auto;
                    width: auto;
                }
            }
        }
    }
}

//email sign up modal
.email-signup-dialog,
.email-success-dialog {
    .left-side {
        float: left;
        width: 50%;
        padding: 10px 30px 30px;

        img {
            width: 100%;
        }

        @include bp(max-width, $bp-medium) {
            display: none;
        }
    }

    .right-side,
    .form-wrap {
        float: right;
        width: 50%;
        padding: 20px 35px 0 30px;
        text-align: center;

        @include bp(max-width, $bp-medium) {
            width: 100%;
        }
    }

    label {
        display: none;
    }

    input {
        padding: 0 10px 0 14px;
    }

    h3 {
        margin: 10px 0 0;
    }

    p {
        padding: 5px 10px 10px;
    }

    .form-wrap {
        padding-top: 0;

        @include bp(max-width, $bp-medium) {
            margin-bottom: 40px;
        }
    }

    #email-signup-dialog {
        button,
        button:hover,
        button:focus  {
            width: 100%;
        }
    }

    .logo-wrapper {
        width: 200px;
        margin: 40px auto 0;
    }

    .ui-dialog-titlebar-close {
        top: 10px;
        right: 10px;
        z-index: 100000;
    }
}

.email-success-dialog {
    form {
        display: none;
    }
    .right-side {
        @include bp(min-width, 767px) {
            margin-top: 40px;
        }
    }
}

.renew-member-modal {
    color: $black;
    font: normal normal 14px/22px $f-avenir;
    letter-spacing: 1px;
    padding: 0 20px 20px;

    h3 {
        color: inherit;
        font: normal normal 26px/26px $f-classical-normal;
        letter-spacing: 0.5px;
        margin: 0;
        padding: 0;
        text-transform: none;
    }

    p {
        color: inherit;
        font: inherit;
        margin-left: 20px;
    }

    ul {
        font: inherit;
        margin-left: 20px;
        padding-top: 10px;
    }

    .button {
        align-items: center;
        background: $black;
        border-color: $black;
        color: $white;
        display: flex;
        font: normal normal 16px/26px $f-avenir;
        justify-content: center;
        letter-spacing: 0.01em;
        padding: 0;

        &:hover {
            background: $white;
            color: $black;
        }
    }
}

#email-alert-signup {
    position: relative;

    .form-row {
        margin: 0;
    }

    .button {
        background: none;
        border: 0;
        height: auto;
        margin: 0;
        min-width: initial;
        outline: none;
        padding: 0;
        position: absolute;
        right: 0;
        text-transform: none;
        top: 22px;
        z-index: 2;
    }

    .input-text {
        border: 0;
        border-bottom: 1px solid $silver;
        padding: 20px 74px 0 0;

        &.error {
            background: transparent;
            border-bottom: 1px solid $c-error;
        }
    }

    label {
        top: 24px;
        left: 0;
        font-size: 16px;
        font-family: $f-primary;
    }
}

.show-terms-and-conditions-modal {
    font: inherit;
    color: inherit;
    letter-spacing: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.show-terms-and-conditions-modal-inner {
    padding-top: 52px;
    margin: 0;
}
