body {
    background: none repeat scroll 0 0 $night-rider;
    color: $clr_brand_black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: normal normal 14px/24px $f-avenir;
}

a {
    color: $clr_brand_black;
    font: normal normal 12px/16px $f-avenir;
    letter-spacing: 0.015em;
    text-decoration: underline;

    @include bp(min-width, $bp-large) {
        font-size: 18px;
        letter-spacing: 0.0125em;
        line-height: 28px;
    }
}

em {
    font-style: italic;
}

fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}

table {
    width: 100%;
}

th {
    text-align: left;
}

ol,
ul {
    font: normal normal 14px/22px $f-neue;
}

ul li,
ol li {
    ol,
    ul {
        padding-left: 25px;
    }
}

td {
    padding: 1em;
    vertical-align: top;
}

p,
.paragraph {
    color: $clr_brand_black;
    font: normal normal 16px/22px $f-avenir;
    letter-spacing: 0.015em;

    @include bp(min-width, $bp-large) {
        font-size: 18px;
        letter-spacing: 0.0125em;
        line-height: 28px;
    }

    a {
        font: normal normal 12px/16px $f-avenir;
        letter-spacing: inherit;
        text-decoration: underline;

        @include bp(min-width, $bp-large) {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &.strong {
        font-weight: 800;
    }
}

.body {
    &-large,
    &-medium,
    &-14,
    &-small {
        color: $clr_brand_black;
        letter-spacing: 0.0025em;
    }

    &-large {
        font: normal normal 16px/20px $f-avenir;
    }

    &-medium {
        font: normal normal 14px/20px $f-avenir;

        @include bp(min-width, $bp-large) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &-14 {
        font: normal normal 14px/16px $f-avenir;
    }

    &-small {
        font: normal normal 12px/16px $f-avenir;
    }
}

blockquote {
    position: relative;
    margin: 0;
    padding-top: 25px;

    &::before {
        position: absolute;
        content: $icon-blockquote;
        top: 0;
        left: -1px;
        height: 21px;
        @extend %icon-font;
        color: #e2e2e2;
        display: block;
        font-size: 27px;
    }

    &.small {
        font: normal normal 12px/18px $f-neue;
    }

    @include bp(min-width, $bp-large) {
        font: normal normal 16px/26px $f-neue;
        margin: 0 0 0 60px;
        padding-top: 0;

        &::before {
            left: -63px;
            top: 9px;
        }
    }
}

strong {
    font-weight: 800;
}

button,
.button,
.buttonstyle,
input[type="button"] {
    text-align: center;
    transition: all 0.3s ease;
    @include primary-button();

    &.raised {
        border-width: 0;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        &:hover {
            background-color: darken($citrus, 10%);
            color: $white;
        }
    }

    &.simple {
        background-color: $white;
        color: darken($citrus, 10%);
    }

    &.alt,
    &.apply,
    &.agreeterms {
        background-color: $b-gray;
        color: $white;
        font-family: $f-primary;
        font-size: 16px;
        font-weight: 400;

        &:hover {
            background-color: $white;
            color: $b-gray;
        }

        &[disabled],
        &.disabled {
            background-color: $field-border;
            color: $b-gray;
        }
    }

    a {
        color: $white;

        &:hover {
            color: $citrus;
        }
    }

    i {
        &.left {
            margin-right: 0.6em;
        }
        &.right {
            margin-left: 0.6em;
        }
    }

    &.lp_minimize,
    &.lp_close,
    &.lp_cancel_button,
    &.lp_submit_button,
    &.lp_popout,
    &.lp_actions_button,
    &.lp_send-icon,
    &.lp_confirm_button,
    &.cobrowse-status-button,
    &.lp_send_button,
    &.lp_maximize,
    &.lp_action_item {
        &::after {
            display: none;
        }
    }
    &.add-to-cart.vip-member-warning {
        border: 0;
        background-color: transparent;
    }
}

.outline-button,
.featured-content a.button {
    @include outline-button();
}

button#add-to-cart {
    &::after {
        display: none;
    }
}

.button-back {
    @include primary-button();
}

.video-button {
    @include video-button();
}

.button-plain {
    @include plain-button();
}

.button-text {
    background-color: transparent;
    border-width: 0;
    // ensures text baseline aligns with other buttons
    padding: 1px 0 0;
    color: $brand-primary-light;
    &:hover {
        background-color: transparent;
        text-decoration: underline;
    }
    &::after {
        display: none;
    }
}

.button-full-width {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
}


// =============================================
// FadeIn Animtaion
// =============================================

@include keyframes (fadeIn) {
    0% {
        opacity: 0;
    }

    1% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// =============================================
// FadeOut Animtaion
// =============================================

@include keyframes (fadeOut) {
    0% {
        opacity: 1;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.wrapper-overlay {
    background-color: rgba(46, 45, 42, 0.5); // IE fallback
    background: rgba(46, 45, 42, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    z-index: 99991;
    cursor: pointer;

    &.fadeOut {
        @include animation(fadeOut $default-transition-duration $default-transition-function);
    }
}

.overlay-active,
.menu-active,
.search-active {
    .wrapper-overlay {
        @include animation(fadeIn $default-transition-duration $default-transition-function);
        display: block;
    }
}

.search-active {
    .wrapper-overlay {
        top: 110px;

        @include bp(min-width, $bp-medium) {
            top: 0;
        }
    }
}

.printLogo {
    display: block;
}

#wrapper {
    &.overlay-active,
    &.menu-active,
    &.search-active {
        position: fixed;

        @supports (-webkit-touch-callout: none) {
            position: absolute;
            height: 100vh;
            min-height: 100vh;
            overflow: hidden;
        }
    }

    &.menu-active,
    &.search-active {
        overflow: hidden;
    }
}

html.touchevents.clickout-active {
    cursor: pointer;
}

.collection-active {
    body {
        overflow: hidden !important;
    }

    #wrapper .wrapper-overlay {
        background-color: rgba(46, 45, 42, 1); // IE fallback
        background: rgba(46, 45, 42, 1);
        position: fixed;
        display: block;
        bottom: 0;
        right: 0;
    }

    .product-collection.show {
        opacity: 1;
    }
}
.no-close.vip-warning {
    h2 {
        font: normal normal 29px/32px $f-primary;
    }
    .ui-dialog-buttonpane {
        border: 0;
    }
    .add-to-cart.vip-member-warning {
        font-weight: bold;
    }
}

.ui-dialog {
    &.loyalty-ui-dialog {
        width: 330px !important;

        @include bp(min-width, $bp-medium) {
            width: 550px !important;
        }

        .ui-widget-header {
            display: none;
        }

        .ui-dialog-content {
            padding: 0;
        }

        .loyalty-dialog {
            &__btn-close {
                color: $white;

                &:hover {
                    color: inherit;
                }
            }
        }
    }
}

.loyalty-dialog {
    color: $black;
    padding: 40px;

    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 27px;
        height: 27px;
        transform: rotate(45deg);
        cursor: pointer;

        @include bp(min-width, $bp-medium) {
            top: 25px;
            right: 27px;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            background: $black;
        }

        &::before {
            height: 1px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            width: 1px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__title {
        color: inherit;
        font: normal normal 20px/24px $f-classical-normal;
        letter-spacing: 0.05em;
        margin: 0 0 50px;
        text-align: center;
    }

    &__copy {
        font: normal normal 16px/22px $f-avenir;
        margin: 0;

        &__first {
            margin-bottom: 40px;
        }

        &__second {
            margin-bottom: 56px;
        }

        a {
            font: inherit;
            color: inherit;
        }
    }

    &__btn-close {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 4px;
        width: 100%;
    }
}

.email-form-modal {
    padding: 0 5px;
    border-radius: 0;
    #dialog-container {
        padding: 5px 25px;
        h3 {
            margin-right: 20px;
            text-decoration: none;
            @include bp(max-width, $bp-large) {
                font: normal normal 28px/34px $f-primary;
            }
        }
        p {
            margin-top: 10px;
            line-height: 18px;
            margin-right: 20px;
        }
        .full-width {
            button {
                width: 100%;
                margin-bottom: 25px;
            }
        }
        .checkbox-row {
            margin-top: -5px;
            .checkbox-label span {
                font-size: 12px;
            }
        }
        .thankyoumessage {
            margin: 0 9%;
        }
        textarea {
            height: auto;
            resize: none;
        }
        .input-focused {
            textarea {
                padding-top: 20px;
            }
            &.textarea-row {
                label {
                    background-color: $white;
                    width: 90%;
                    top: 15px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }
        }
    }
}

.loading-wrapper,
.mini-cart-loading-wrapper,
.refinement-flyout-loading-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: rgba(180, 180, 180, 0.7);

    &--show {
        display: flex;
    }
}

.link--black {
    color: black;

    &:focus,
    &:hover,
    &:active {
        color: black;
    }
}

.btn-default {
    @include primary-button();
}

.btn-white {
    @include outline-button();
}

.btn-tertiary {
    @include tertiary-button();
}

img {
    transition: filter .7s ease-in-out;
    &.lazy {
        filter: blur(50px);
    }
}

.text-black {
    color: $black !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-end-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-start-0 {
    border-left: 0 !important;
}

.text-white {
    color: $white;
}

// Fix issue with owl carousel when using touch events
.owl-carousel {
    touch-action: manipulation;
}

.wishlist-icon-wrapper {
    display: block;
    height: 100%;
    width: 100%;

    &.item-added {
        .wishlist-icon {
            &__added {
                @extend %-show-wishlist-icon;
            }

            &__default {
                display: none;
            }
        }
    }
}

.wishlist {
    &:hover {
        .wishlist-icon-wrapper {
            .wishlist-icon {
                &__added,
                &__default {
                    display: none;
                }

                &__hover {
                    @extend %-show-wishlist-icon;
                }
            }
        }
    }
}

.wishlist-icon {
    background: url("../images/wishlist-default.png") top left / contain no-repeat;
    border: 0;
    display: block;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &__added {
        background-image: url("../images/wishlist-added.png");
    }

    &__default {
        background-image: url("../images/wishlist-default.png");
        @extend %-show-wishlist-icon;
    }

    &__hover {
        background-image: url("../images/wishlist-hover.png");
    }
}

%-show-wishlist-icon {
    height: 100%;
    margin: 0;
    overflow: initial;
    padding: 0;
    position: initial;
    width: 100%;
}

.step-circle-icon {
    width: 30px;
    height: 30px;
    flex-basis: 30px;
    flex-grow: 0;
    flex-shrink: 0;
    display: inline-flex;
    color: $brand-v2-dark-gray;
    align-items: center;
    border: 1px solid $very-light-gray;
    border-radius: 50%;
    font: 900 normal 10px/18px "Avenir";
    justify-content: center;

    &.active,
    .active & {
        background: $brand-v2-dark-gray;
        border-color: $brand-v2-dark-gray;
        color: $white;
    }
}
