.breadcrumb {
    padding: 32px 0 20px;
    text-transform: uppercase;

    a {
        text-decoration: none;

        &:not(:last-child):hover {
            text-decoration: underline;
        }
    }

    @media screen and (max-width: $bp-medium) {
        padding-top: 25px;
    }
}

.breadcrumb-element {
    $breadcrumbSpace: 4px;
    color: $limed-ash;
    font: inherit;
    letter-spacing: inherit;
    padding-left: $breadcrumbSpace;

    &:first-child {
        padding-left: 0;
    }

    &:not(:last-child)::after {
        content: "|";
        color: $limed-ash;
        margin-left: $breadcrumbSpace;
    }

    &.last-crumb {
        color: $clr_dark_mid;
        font-weight: bold;

        &::after {
            display: none;
        }
    }
}
