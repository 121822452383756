.fields {
    clear: both;
    margin-left: 0;
    .field label {
        float: left;
        width: 100%;
    }
}

.input-custom {
    position: absolute;
    left: -999em;
    // z-index: 999; //Debugging Only
}

.select-custom {
    float: left;
    clear: both;
    width: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    display: block;
    cursor: pointer;
    height: 32px;
    @include opacity(0);
}

.custom-shiv {
    float: left;
    box-sizing: border-box;
    clear: both;
    width: 100%;
    height: 32px;
    overflow: hidden;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
    padding: 0 30px 0 15px;
    font: normal pem(14) / 30px $sans-serif;
    @include gradient-vertical($white, darken($white, 2%));
    color: $black;
    position: relative;
    cursor: pointer;
    letter-spacing: 1px;
    border-radius: 5px;
    span::after {
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        content: "";
        font-family: $f-stack-icon;
        line-height: 36px;
        display: block;
        text-align: center;
    }
}

.select-container {
    padding-top: 0;
    position: relative;
    display: inline-block;
    &.validation-error {
        .custom-shiv {
            @include gradient-vertical(lighten($brand-danger, 5%), $brand-danger);
            text-shadow: 0 2px 0 lighten($brand-danger, 5%);
        }
        &:hover .custom-shiv {
            background: $brand-danger;
        }
    }
    &:hover {
        .custom-shiv {
            color: $c-blue;
        }
    }
    &.validation-error:hover {
        .custom-shiv {
            color: $black-80;
        }
    }
    &.disabled {
        @include opacity(.5);
    }
    &.small {
        top: 4px;
        .select-custom {
            height: 18px;
        }
        .custom-shiv {
            display: inline-block;
            height: 18px;
            line-height: 18px;
            font-size: pem(12);
            text-transform: none;
            padding-right: 26px;
            padding-left: 5px;
            span::after {
                width: 26px;
                height: 18px;
                font-size: 12px;
                line-height: 22px;
            }
        }
    }
}

label.radio-label,
label.checkbox-label,
span.label.radio-label,
span.label.checkbox-label {
    cursor: pointer;
    display: inline-block;
    left: 0;
    line-height: 16px;
    margin-bottom: 0;
    padding: 0 0 0 27px;
    position: relative;
    text-align: left;
    text-indent: 0;
    top: 0;
    vertical-align: baseline;

    &::before {
        background-color: $clr_white;
        border: 1px solid $clr_dark_mid;
        color: $clr_dark_mid;
        content: "";
        display: block;
        height: 20px;
        left: 1px;
        position: absolute;
        top: -3px;
        width: 20px;
        z-index: 8;
    }

    &:hover {
        &::before {
            background-color: $clr_light_mid;
        }
    }

    &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        color: $clr_input_notactive;

        &::before {
            border-color: #f1f1f1;
        }
    }

    &.checked {
        &::before {
            border-color: $clr_brand_black;
        }

        &::after {
            color: $clr_brand_black;
            content: $icon-check;
            font: normal normal 9px/9px $f-icon;
            left: 5px;
            position: absolute;
            top: 3px;
            z-index: 9;
            @extend %icon-font;
        }
    }
}

label.radio-label,
span.label.radio-label {
    &::before {
        border-radius: 50%;
    }

    &.checked {
        &::after {
            background: $clr_brand_black;
            border-radius: 50%;
            content: "";
            height: 14px;
            left: 4px;
            top: 0;
            width: 14px;
        }
    }
}

// IE Fixes
.IE {
    label.checkbox-label.checked::after,
    span.label.checkbox-label.checked::after {
        top: 5px !important;
        font-size: 7px !important;
    }

}

span.label.radio-label,
span.label.checkbox-label {
    label {
        padding: 0;
    }
}

.form-list .select-container {
    display: block;
    width: 100%;
    clear: both;
}

.form-list select {
    margin-top: 0;
    width: 100%;
}

.box-related .checkbox-label {
    float: left;
    margin-top: 36px;
    margin-right: 10px;
}

.shipping #co-shipping-method-form .sp-methods dd label.radio-label {
    border: 0;
    font-style: normal;
}

.opc {
    .select-container {
        width: 365px;
        &.v-fix {
            width: auto;
            clear: none;
        }
        select {
            margin-top: 0;
            width: 100%;
        }
    }
}

.customer-address-form {
    .form-list .select-container {
        width: 365px;
    }
}

.reset {
    clear: none;
}

.field-inline {
    padding-top: 5px;
    display: inline-block;
    margin-right: 30px;
}
.hundred {
    width: 100%;
}
