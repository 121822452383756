//  Blue Acorn Grid System

@function grid($prefix) {
    @if $prefix == m {
        @return (1.25, 15.625, 17.5, 17.5, 15.625, 1.25);
    }
    @if $prefix == t {
        @return (3.90625, 11.979166667, 9.635416667, 10.807291666000001, 10.807291666000001, 9.635416667, 11.979166667, 3.90625);
    }
    @if $prefix == d {
        @return (7.6171875, 14.453125, 8.10546875, 11.23046875, 11.328125, 8.10546875, 14.453125, 7.6171875);
    }
}

@function gutter($prefix) {
    $gutter: 0;
    @if $prefix == m {
        $gutter: 6.25;
    }
    @if $prefix == t {
        $gutter: 3.90625;
    }
    @if $prefix == d {
        $gutter: 2.44140625;
    }
    @return $gutter;
}

@function gutter-offset($width, $cols, $grid, $first-margin) {
    $gutter-offset: $cols;
    $additional-width: #{(gutter($grid)/$gutter-offset)};

    @if $first-margin == false {
        @return #{($width + $additional-width) + "%"};
    } @else {
        @return #{$width + "%"};
    }
}

@mixin grid-media($prefix, $type: false) {

    @if $prefix == m {
        @if ($type == min) or ($type == min-max) {
            @if ($type == min) {
                @media only screen and (min-width: 320px) {
                    @content;
                }
            } @else {
                @media only screen and (min-width: 320px) and (max-width: ($bp-medium - 1)) {
                    @content;
                }
            }
        } @else {
            @content;
        }
    } @else if $prefix == t {
        @if ($type == min) or ($type == min-max) {
            @if ($type == min) {
                @media only screen and (min-width: $bp-medium) {
                    @content;
                }
            } @else {
                @media only screen and (min-width: $bp-medium) and (max-width: $bp-large - 1) {
                    @content;
                }
            }
        } @else {
            @content;
        }
    } @else if $prefix == d {
        @if ($type == min) or ($type == min-max) {
            @media only screen and (min-width: $bp-large) {
                @content;
            }
        } @else {
            @content;
        }
    }

}

@function grid-margin($grid, $spanstart) {
    @if ($spanstart > 1) {
        $start: 1;
        $end: $spanstart - 1;

        @if $end == $start {
            $end: false;
        }

        $margin: grid-width($grid, $start, $end);
        $gutter: gutter($grid);

        @return $margin + $gutter;

    } @else {

        @return 0;

    }
}

@function grid-width($grid, $start, $end: false) {

    $cols: grid($grid);
    $gutter: gutter($grid);
    $measure: 0;
    $gutters: 0;
    $i: $start;

    @if $end != false {
        @if ($end - $start) > 0 {
            $gutters: ($gutter * ($end - $start));
        }

        @while $i <= $end {
            $measure: $measure + nth($cols, $i);
            $i: $i + 1;
        }
    } @else {
        $measure: nth($cols, $start);
    }

    @return $measure + $gutters;

}

@function g-col-width($prefix, $column) {
    $column-width: #{grid-width($prefix, $column, $column) + "%"};
    @return $column-width;
}

@mixin g($grid, $start, $end: false, $pad: false) {
    @if $start == all {
        float: left;
        clear: both;
        width: 100%;
        @if ($grid == m) and ($pad == true) {
            $first-column: grid-width(m, 1);
            padding: 0 #{($first-column + gutter($grid)) + "%"};
        }
        @if ($grid != m) and ($pad == true) {
            padding: 0 #{gutter($grid) + "%"};
        }
    } @else {
        @if $start > 1 {
            margin-left: #{gutter($grid) + "%"};
        }
        width: #{grid-width($grid, $start, $end) + "%"};
    }
}

@mixin g-item($max, $min, $cols, $parent-width, $margin) {
    $column-division: (1 / $cols) * 100;
    $gutter: ($margin / $parent-width) * 100;
    $column-margin: $column-division - $gutter;
    $additional-width: $gutter / $cols;

    @include bpmm($min, $max) {
        margin-left: #{$gutter + "%"};

        @if ((1 / $cols) * 100) > $gutter {
            width: #{($additional-width + $column-margin) + "%"};
        } @else {
            width: #{$column-division + "%"};
        }

        &:nth-child(#{$cols + "n"} + #{"1"}) {
            margin-left: 0;
            clear: both;
        }
    }
}

@mixin g-all() {
    @include g(m, all);
}

@mixin g-all-pad() {
    @include g(m, all, false, true);
    @include grid-media(t, min) {
        @include g(t, all, false, true);
    }
    @include grid-media(d, min) {
        @include g(d, all, false, true);
    }
}

@mixin reset-g-padding() {
    padding-left: 0;
    padding-right: 0;
}

@mixin neat-item($span-value, $of-value: false, $nth-value: false) {
    @if $of-value != false {
        @include span-columns($span-value of $of-value);
    } @else {
        @include span-columns($span-value);
    }
    @if $nth-value != false {
        @include omega(#{$nth-value}n);
    }
}

@mixin neat-mobile-item($span-value, $of-value: false, $nth-value: false) {
    @include bpmm(min, $bp-small) {
        @include neat-item($span-value, $of-value, $nth-value);
    }
}

@mixin neat-tablet-item($span-value, $of-value: false, $nth-value: false) {
    @include bpmm($bp-small, $bp-large) {
        @include neat-item($span-value, $of-value, $nth-value);
    }
}

@mixin neat-desktop-item($span-value, $of-value: false, $nth-value: false) {
    @include bpmm($bp-large, max) {
        @include neat-item($span-value, $of-value, $nth-value);
    }
}

@mixin neat-tablet-desktop-item($span-value, $of-value: false, $nth-value: false) {
    @include bpmm($bp-small, max) {
        @include neat-item($span-value, $of-value, $nth-value);
    }
}

@mixin neat-mobile-tablet-item($span-value, $of-value: false, $nth-value: false) {
    @include bpmm(min, $bp-large) {
        @include neat-item($span-value, $of-value, $nth-value);
    }
}

.grid-container {
    @include outer-container;
    @include pad(0 $container-padding 15px $container-padding);

    &.full-width {
        @include outer-container(100%);
    }

    &.bleed {
        @include pad(0 0 15px 0);
    }

    &.bleed.offset,
    &.offset {
        @include pad(0 $container-padding 15px $container-padding);
    }

    @include bp(max-width, $bp-medium) {
        @include pad(0 $m-container-padding 15px $m-container-padding);

        &.bleed.offset,
        &.offset {
            @include pad(0 $m-container-padding 15px $m-container-padding);
        }
    }

    @include bpmm($bp-medium, $bp-large) {
        @include pad(0 $t-container-padding 15px $t-container-padding);

        &.bleed.offset,
        &.offset {
            @include pad(0 $t-container-padding 15px $t-container-padding);
        }
    }

    @include bpmm($bp-large, $bp-xlarge) {
        @include pad(0 $sd-container-padding 15px $sd-container-padding);

        &.bleed.offset,
        &.offset {
            @include pad(0 $sd-container-padding 15px $sd-container-padding);
        }
    }

    @for $i from 1 through 12 {
        .grid-span-#{$i} {
            @include neat-item($i);

            &.offset-left {
                margin-left: -#{$container-padding};
            }

            &.padded-left {
                @include pad(0 0 0 $content-padding);
            }

            &.padded-right {
                @include pad(0 $content-padding 0 0);
            }

            @include bp(max-width, $bp-small) {
                width: 100%;
                margin-left: 0;
            }

            @include bp(max-width, $bp-medium) {
                $grid-columns: $sd-grid-columns;

                &.offset-left {
                    margin-left: -#{$m-container-padding};
                }

                .pt_storefront .featured-content & {
                    width: 100%;
                    margin-left: 0;
                }
            }

            @include bpmm($bp-medium, $bp-large) {
                $grid-columns: $sd-grid-columns;

                &.offset-left {
                    margin-left: -#{$t-container-padding};
                }
            }

            @include bpmm($bp-large, $bp-xlarge) {
                &.offset-left {
                    margin-left: -#{$sd-container-padding};
                }
            }

            @include bp(min-width, $bp-xlarge) {
                &.remove-gutter {
                    margin-right: 0 !important;
                }
            }

            @for $ii from 1 through 12 {
                .span-#{$ii}of#{$i} {
                    @include span-columns($ii of $i);

                    &.last {
                        margin-right: 0;
                    }
                }
            }
        }

        &.twelve-columns .grid-span-#{$i} {
            @include neat-item($i, 12);
        }
    }
}
