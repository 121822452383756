h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: $clr_brand_black;
    margin: 0;
}

h5,
.h5,
h6,
.h6 {
    text-transform: uppercase;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    text-transform: capitalize;
    letter-spacing: 0.025em;

    @include bp(min-width, $bp-large) {
        letter-spacing: 0.035em;
    }
}

h1,
.h1 {
    font: normal normal 36px/40px $f-classical-normal;

    @include bp(min-width, $bp-large) {
        font-size: 42px;
        line-height: 48px;
    }
}

h2,
.h2,
h3,
.h3 {
    &.bottom-border {
        @include bottom-border;
    }
}

h2,
.h2 {
    font: normal normal 28px/32px $f-classical-normal;

    @include bp(min-width, $bp-large) {
        font-size: 32px;
        line-height: 38px;
    }

    &.bottom-border {
        @include bp(max-width, $bp-large) {
            padding-bottom: 17px;
        }
    }
}

h3,
.h3 {
    font: normal normal 18px/18px $f-classical-normal;

    @include bp(min-width, $bp-large) {
        font-size: 24px;
        line-height: 28px;
    }

    &.alternate {
        font-family: $f-classical;
        text-transform: none;
        letter-spacing: 0;

        @include bp(min-width, $bp-large) {
            letter-spacing: 1px;
        }
    }

    &.bottom-border {
        padding-bottom: 16px;
    }
}

h4,
.h4 {
    font: normal normal 16px/16px $f-classical-normal;

    @include bp(min-width, $bp-large) {
        font-size: 18px;
        line-height: 26px;
    }
}

h5,
.h5 {
    font: 900 normal 13px/18px $f-avenir;
    letter-spacing: 0.005em;

    @include bp(min-width, $bp-large) {
        font-size: 14px;
        line-height: 20px;
    }

    &.alternate {
        font-family: $f-classical;
        text-transform: none;
    }
}

#secondary h1 {
    font-size: 1.75em;
    font-style: italic;
    font-weight: 400;
    margin: 0 0 14px;
}

.primary-content {
    // commenting to test if this breaks anything. Jamie - if you find this in the PR, call me on it
    // min-height: 45vh;

    dl {
        clear: both;
        overflow: hidden;
        dt {
            clear: both;
            float: left;
            margin: 2% 0 0;
            min-width: 15%;
        }
        dd {
            float: left;
            margin: 2% 0 0;
            padding: 0 0 0 5%;
        }
    }

    .page-designer-reference {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: $clr_brand_black;
            margin: 0;
            text-transform: uppercase;
        }

        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4 {
            letter-spacing: 0.025em;

            @include bp(min-width, $bp-large) {
                letter-spacing: 0.035em;
            }
        }

        h1,
        .h1 {
            font: normal normal 36px/40px $f-classical-normal;

            @include bp(min-width, $bp-large) {
                font-size: 42px;
                line-height: 48px;
            }
        }

        h2,
        .h2,
        h3,
        .h3 {
            &.bottom-border {
                @include bottom-border;
            }
        }

        h2,
        .h2 {
            font: normal normal 30px/36px $f-classical-normal;

            @include bp(min-width, $bp-large) {
                font-size: 32px;
                line-height: 38px;
            }

            &.bottom-border {
                @include bp(max-width, $bp-large) {
                    padding-bottom: 17px;
                }
            }
        }

        h3,
        .h3 {
            font: normal normal 18px/18px $f-classical-normal;

            @include bp(min-width, $bp-large) {
                font-size: 24px;
                line-height: 28px;
            }

            &.bottom-border {
                padding-bottom: 16px;
            }
        }

        h4,
        .h4 {
            font: normal normal 16px/16px $f-classical-normal;

            @include bp(min-width, $bp-large) {
                font-size: 18px;
                line-height: 26px;
            }
        }

        h5,
        .h5 {
            font: 900 normal 13px/18px $f-avenir;
            letter-spacing: 0.005em;

            @include bp(min-width, $bp-large) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

.col-1 {
    float: left;
    margin: 4px 0;
    width: 45%;
}

.col-2 {
    float: right;
    margin: 4px 0;
    width: 45%;
}

@include bp(max-width, $bp-medium) {
    .col-1,
    .col-2 {
        float: none;
        width: 100%;
    }
}

.content-header {
    font-size: 1.75em;
    font-style: italic;
    font-weight: 400;
    margin: 0 0 14px !important;
    span {
        font-size: 0.75em;
        font-style: normal;
        font-weight: 400;
    }
}

.section-header {
    background: $brand-section-grey;
    margin: 0 0 20px;
    padding: 13px 14px;
    position: relative;

    .dialog-required {
        text-transform: none;
        display: block;
        line-height: 17px;
    }

    .login-box & {
        font: normal normal 22px/34px $f-primary;
    }
}

.section-header-note {
    font-size: 0.9em;
    font-weight: 400;
    text-transform: none;
}

.back {
    display: block;
    margin: 0 0 10px;
}

// ============================================ *
// Image hero - to be used when a image is be behind
// the global header, only used when image for background is
// being pulled in as an inline <img> tag instead of a background image
// ============================================ */
.image-hero {
    display: block;
    position: relative;

    &::before {
        content: "";
        display: block;
        position: absolute;
        height: 140px;
        top: 0;
        right: 0;
        left: 0;
        background-image: linear-gradient(-180deg, #2e2d2a 1%, #2e2d2a 33%, rgba(36, 36, 36, 0) 100%);
        opacity: .35;
    }

    img {
        width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
    }
}

a.facebook,
a.instagram,
a.pinterest,
a.twitter {
    @extend %anchor-no-decoration;
    display: inline-block;

    &::before {
        @extend %icon-font;
        font-size: 18px;
        color: $brand-primary-light;
        padding: 11px;
        line-height: 36px;
    }
}

a.facebook::before {
    content: $icon-facebook;
}

a.instagram::before {
    content: $icon-instagram;
}

a.pinterest::before {
    content: $icon-pinterest;
}

a.twitter::before {
    content: $icon-twitter;
}

.product-tile {
    .pdp-main .product-add-to-cart.all  & {
        & label {
            padding-top: 2px;
            text-decoration: underline;
        }
    }
}

// ============================================ *
// Global Flexslider Styles
// ============================================ */

.flex-direction-nav a {
    opacity: 1;
    height: 34px;
    margin-top: -15px;
    display: block;
    color: $white !important; // turn off active/hover states

    &::before {
        @extend %icon-font;
        display: block;
        color: $white;
        font-size: 30px;
        text-shadow: none;
        line-height: 30px;
    }

    &.flex-prev {
        left: 17px;

        &::before {
            content: $icon-carousel-prev;
        }
    }

    &.flex-next {
        right: 17px;

        &::before {
            content: $icon-carousel-next;
        }
    }
}

.flex-control-paging li a {
    box-shadow: none;
    border-radius: 50%;
    background: #666;
    background: rgba(0, 0, 0, 0.25);
    height: 22px;
    width: 22px;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        display: block;
        top: 50%;
        left: 50%;
        margin: -7px 0 0 -7px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 1px solid $white;
    }

    &:hover {
        background: #666;
        background: rgba(0, 0, 0, 0.25);
    }

    &.flex-active {
        background: #666;
        background: rgba(0, 0, 0, 0.25);

        &::after {
            position: absolute;
            content: "";
            display: block;
            top: 50%;
            left: 50%;
            margin: -3px 0 0 -3px;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background: $white;
        }
    }
}

// ============================================ *
// Global Fancybox Styles
// ============================================ */

.fancybox-wrap {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);

    .fancybox-skin {
        width: 860px !important;
        height: 528px !important;
        border-radius: 0;
        box-shadow: none;

        .fancybox-inner,
        .fancybox-outer {
            width: 100% !important;
            height: 100% !important;
        }

        a.fancybox-close {
            top: 0;
            right: 0;
            background: none;
            position: absolute;
            text-decoration: none;
            width: auto;
            height: auto;

            &::before {
                @extend %icon-font;
                position: relative;
                top: 6px;
                right: 6px;
                color: $black;
                line-height: 36px;
                content: $icon-close-x;
                font-size: 14px;
                padding: 11px;
            }
        }


    }

    @include bp(max-width, $bp-medium) {

        .fancybox-skin {
            padding: 36px !important;
            width: auto !important;
            max-height: 400px;

            a.fancybox-close {
                &::before {
                    top: 3px;
                    right: 0;
                }
            }
        }

        .fancybox-inner {
            width: 100% !important;
        }
    }

    @include bp(max-width, 450px) {
        .fancybox-skin {
            height: 100% !important;
        }
    }
}
