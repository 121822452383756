@import "nav";
@import "menu_utility";
@import "country_selector";

.header__inner {
    max-width: 1600px !important;
}

.header__inner_mobile {
    display: none;
    border-bottom: 1px solid #e1e1e1;
    justify-content: space-between;
    align-items: center;
    height: 39px;
    padding: 0 52px;

    @include bp(max-width, $bp-medium) {
        display: flex !important;
    }

    .left-header-col {
        .brands-title {
            font-size: 12px;
            color: #545454;
            font-weight: 600;
            letter-spacing: 1.2px;
        }
    }

    .center-header-col {
        .primary-logo-surya {
            margin-right: 36px;
            margin-left: 36px;

            .screenLogo {
                margin-top: 7px;
                width: 63.33px;
                height: auto;
            }
        }
    }

    .right-header-col {
        .primary-logo-gvb {
            .screenLogo {
                width: 109px;
                height: auto;
            }
        }
    }
}

.top-banner {
    position: relative;
    height: 60px;
    z-index: 23;
    color: $black;
    display: flex;
    flex-direction: column;

    @include bp(min-width, $bp-custom-large) {
        height: 122px;
    }

    * {
        box-sizing: border-box;
    }

    a,
    > button {
        color: inherit;
    }

    .left-header-col {
        .brands-names {
            display: none;

            @include bp(min-width, $bp-medium) {
                display: flex !important;
            }

            .brands-title {
                font-size: 12px;
                color: #545454;
                font-weight: 600;
                letter-spacing: 1.2px;
                margin-bottom: 5px;
            }

            .primary-logo-surya {
                margin-right: 40px;
                margin-left: 40px;

                .screenLogo {
                    width: 63.33px;
                    height: auto;
                }
            }

            .primary-logo-gvb {
                .screenLogo {
                    width: 115px;
                    height: auto;
                    margin-bottom: 3px;
                }
            }
        }
    }

    .OTA-logo {
        display: none;
        font-size: 14px;
        font-weight: 400;
        color: #000;
        text-decoration: none;
        text-transform: uppercase;
        padding: 0;
        background: none;
        border: none;
        margin-right: 40px;

        @include bp(min-width, $bp-medium) {
            display: block !important;
        }
    }

    .search-icon {
        @include bp(min-width, $bp-custom-small) {
            margin-left: 21px;
        }

        @include bp(max-width, $bp-large) {
            display: block !important;
        }
    }

    .icon-link {
        display: block;
        text-decoration: none;
        font-size: 0;
        line-height: 0;

        .label {
            display: none;
        }
    }

    .registries-link,
    .login-link {
        position: relative;
        display: inline-block;
        @include bp(min-width, $bp-medium) {
            width: 32px;
            display: block;
        }

        @include bp(min-width, $bp-xlarge) {
            width: 138px;
        }
    }
    .login-link {
        &:hover {
            .my-account-dropdown {
                @include bp(min-width, $bp-large) {
                    display: block;
                }
            }
        }

        a {
            overflow: visible;

            &::before {
                left: 17px;
            }

            img {
                height: 20px;
                margin: 0 auto;
                width: 21px;
            }

            span {
                &.label {
                    left: -0;
                }
            }
        }

        .my-account-dropdown {
            display: none;
            background: $white;
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            text-align: left;
            z-index: 9999;
            width: 306px;
            padding-top: 6px;

            @include bp(min-width, $bp-custom-large) {
                background: transparent;
            }
        }
    }

    .mini-cart-total {
        position: relative;
        @include bp(max-width, $bp-large) {
            text-align: center;
            display: block;
            width: 100%;
        }
        .mini-cart-icon-wrapper {
            padding-top: 0;
        }
        .minicart-icon {
            img {
                width: 30px;
            }
        }
        .mini-cart-empty {
            .minicart-quantity {
                display: none;
                color: #2e2d2a;
                background-color: #fff;
            }
        }
    }

    .menu-toggle {
        display: block;
        height: 18px;
        min-width: initial;
        padding: 0;
        width: 17px;

        @include bp(min-width, $bp-custom-large) {
            display: none;
        }

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-image: url("../images/humburger.svg");
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    .left-header-col,
    .center-header-col,
    .right-header-col {
        display: flex;
        align-items: center;
        width: calc(100% / 3);
    }

    .center-header-col {
        justify-content: center;

        .primary-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;

            a {
                display: flex;
            }

            img {
                width: 157px;

                @include bp(min-width, $bp-custom-large) {
                    width: 200px;
                }
            }
        }
    }
    .right-header-col {
        justify-content: flex-end;

        .mini-cart {
            margin-left: 10px;

            @include bp(min-width, $bp-custom-small) {
                margin-left: 25px;
            }

            @include bp(min-width, $bp-medium) {
                margin-left: 31px;
            }
        }

        .store-locator-link,
        .login-link {
            display: none;
            text-align: right;
            width: auto;
            margin-left: 35px;

            a {
                img {
                    margin: 0 auto;
                }
            }
        }

        .login-link {
            @include bp(min-width, $bp-custom-large) {
                display: block;
            }
        }
    }

    #search-suggestions {
        position: absolute;
        z-index: 10000;

        @include bp(min-width, $bp-large) {
            width: 200%;
            right: 0;
        }
    }
}

.mini-cart-link {
    overflow: visible;
}

.minicart-icon {
    display: flex;
    align-items: flex-end;
}

.minicart-quantity {
    font: normal normal 14px/14px $f-avenir;
    transform: translateY(25%);
}

.header-banner {
    background: $brand-v2-off-black;
    color: $clr_white;
    height: 50px;
    padding: 0 12px;

    @include bp(min-width, $bp-medium) {
        height: 40px;
        letter-spacing: 0.01em;
    }

    @include bp(min-width, $bp-custom-large) {
        padding: 0 15px;
    }

    @include bp(min-width, $bp-xxlarge) {
        padding: 0 100px;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        height: 100%;
        width: 100%;
        max-width: 1140px;

        @include bp(min-width, $bp-custom-large) {
            justify-content: space-between;
        }

        @include bp(min-width, $bp-xxlarge) {
            max-width: initial;
        }
    }

    &__left,
    &__right {
        color: inherit;
    }

    &__right {
        @include bp(max-width, $bp-custom-large) {
            display: none;
        }
    }

    a {
        font: inherit;
        color: inherit;
        letter-spacing: inherit;
        text-decoration: none;
    }
}

.header-promotion {
    &.hidden {
        display: none;
    }
}

.promo-header {
    &__wrapper {
        color: var(--promo-header-color);
        font: normal normal 12px/12px $f-avenir;
        background-color: var(--promo-header-background-color);

        @include bp(min-width, $bp-large) {
            font: normal normal 13px/13px $f-avenir;
        }
    }

    &__inner {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 4px 12px 0;
        text-align: center;

        a {
            color: inherit;
            font: inherit;
            text-decoration: none;
            text-transform: uppercase;
        }
    }

    &__name,
    &__dynamic-part {
        padding-bottom: 4px;
    }

    &__name {
        margin-right: 4px;
    }

    &__link {
        border-left: 1px solid var(--promo-header-color);
        margin-left: 4px;
        padding-left: 4px;
    }
}

.promo-banner {
    align-items: center;

    &__mobile {
        display: flex;

        @include bp(min-width, $bp-medium) {
            display: none;
        }
    }

    &__desktop {
        display: none;

        @include bp(min-width, $bp-medium) {
            display: flex;
        }
    }
}

.ui-dialog {
    &.promo-banner-ui-dialog {
        width: 330px !important;
        border: 1px solid $brand-v2-off-black;

        @include bp(min-width, $bp-medium) {
            width: 550px !important;
        }

        .ui-widget-header {
            display: none;
        }

        .ui-dialog-content {
            padding: 0;
        }
    }
}

.promo-banner-dialog {
    color: $brand-v2-off-black;
    padding: 48px 20px 20px;

    @include bp(min-width, $bp-medium) {
        padding: 40px 64px 40px 40px;
    }

    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 25px;
        height: 25px;
        transform: rotate(45deg);
        cursor: pointer;

        @include bp(min-width, $bp-medium) {
            top: 25px;
            right: 27px;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            background: $very-middle-gray-v7;
        }

        &::before {
            height: 2px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            width: 2px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__title {
        font: normal normal 15px/18px $f-classical-normal;
        letter-spacing: 0.01em;
        padding-bottom: 10px;
        border-bottom: 1px solid $very-middle-gray-v8;
    }
}

.promo-banner-dialog-block {
    &__wrapper {
        font: 500 normal 14px/19px $f-avenir;
        color: $brand-v2-off-black;
        letter-spacing: 0.01em;
        margin-top: 30px;
    }

    &__title {
        font-weight: 800;
        margin-bottom: 10px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 20px;
        }
    }

    &__copy {
        margin-bottom: 10px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 20px;
        }
    }

    &__link {
        font: inherit;
        color: inherit !important;
        text-transform: none;
        text-decoration: underline;
    }
}

html.IE.nine .top-banner .header-search {
    display: none;
}

html.IE.nine .top-banner .open .header-search {
    display: block;
}

body {
    &.hide-live-chat {
        #launcher,
        .LPMcontainer,
        .LPMoverlay {
            display: none !important;
        }
    }

    #b2c-navigation {
        clear: both;
    }

    @include bp(max-width, $bp-custom-large) {
        nav[role="navigation"] .content {
            .menu-category {
                &.level-1 {
                    li#Inspiration {
                        border-top: 1px solid $black;
                        padding: 0;
                        margin: 28px 0 0;
                        a::after {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
    @include bp(max-width, $bp-medium) {
        nav[role="navigation"] .content {
            .menu-category {
                &.level-1 {
                    li#Inspiration {
                        border-top: 1px solid $black;
                        padding: 0;
                        margin: 28px 0 0;
                        a::after {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    @include bp(max-width, 768) {
        nav[role="navigation"] .content {
            .menu-category {
                &.level-1 {
                    li#Inspiration {
                        border-top: 1px solid $black;
                        padding: 0;
                        margin: 28px 0 0;
                        a::after {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}

.mobile-search-form-wrapper {
    background: $white;
    display: none;
    left: 0;
    margin: 0 auto;
    position: absolute;
    top: 110px;
    transition-duration: .85s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    width: 100%;
    z-index: 99992;

    @include bp(min-width, $bp-medium) {
        top: 100px;
    }

    &.open {
        display: block;
    }
}

.search-input-wrapper {
    align-items: center;
    border-bottom: 1px solid $header-search-border-color;
    display: flex;
    margin: 0 auto 20px;
    max-width: 1130px;
    padding: 29px 20px 7px;
    position: relative;

    @include bp(min-width, $bp-medium) {
        padding: 17px 40px 10px;
    }

    @include bp(min-width, $bp-large) {
        padding: 22px 15px 5px;
    }

    @include bp(min-width, 1130px) {
        max-width: 1100px;
        padding: 22px 0 5px;
    }

    .search-input {
        border: 0 none;
        height: 27px;
        padding: 0 10px;
        position: relative;

        @include bp(min-width, $bp-medium) {
            height: 32px;
        }

        @include bp(min-width, $bp-large) {
            padding: 0 20px;
        }

        @include placeholder() {
            color: $header-search-input-placeholder-color;
            font: normal normal 14px/16px $f-avenir;
            letter-spacing: 0.0025em;
        }
    }
}

.search-submit {
    background: transparent url("../images/search.svg") center / contain no-repeat;
    border: 0 none;
    height: 27px;
    min-width: 27px;
    padding: 0;
    transition: inherit;

    @include bp(min-width, $bp-medium) {
        height: 32px;
        min-width: 32px;
    }

    &:focus,
    &:hover {
        background: transparent url("../images/search.svg") center / contain no-repeat;
        border: 0 none;
    }
}

.clear-search,
.close-search {
    min-width: 18px;
    position: relative;
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
    cursor: pointer;

    @include bp(min-width, $bp-medium) {
        width: 24px;
        height: 24px;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        background: $black;
    }

    &::before {
        height: 1px;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &::after {
        width: 1px;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.clear-search {
    display: none !important;
}

.menu-category a.padding-left-10 {
    padding-left: 10px;
}

.header {
    &__wrapper {
        flex: 1;
        border-bottom: 1px solid $very-middle-gray-v7;
        background-color: $white;
        padding: 0 15px;

        @include bp(min-width, $bp-xxlarge) {
            padding: 15px 100px;
        }

        .left-divider {
            &::before {
                background-color: #ccc;
                position: absolute;
                left: -1px;
                content: " ";
                width: 1px;
                height: 60%;
                top: 20%;
            }
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        height: 100%;
        width: 100%;
        max-width: 1140px;

        @include bp(min-width, $bp-xxlarge) {
            max-width: initial;
        }
    }

    &__links-wrapper {
        display: flex;
    }

    &__link {
        position: relative;

        &:hover,
        &:focus {
            .header {
                &__menu-wrapper {
                    display: block;
                }
            }
        }

        +.header {
            &__link {
                margin-left: 30px;
            }
        }
    }

    &__menu-wrapper {
        display: none;
        position: absolute;
        right: -50%;
        padding-top: 12px;
        z-index: 30;
    }

    &__menu-inner {
        color: $brand-v2-off-black;
        padding: 15px 30px;

        @include bp(min-width, $bp-custom-large) {
            background: $white;
            border: 1px solid $very-light-gray;
            width: 304px;
            padding: 0;
        }
    }

    &__menu-swatches {
        @include bp(min-width, $bp-custom-large) {
            border-color: $very-middle-gray-v3;
            width: 344px;
            padding: 20px;
        }

        @include bp(min-width, 1920px) {
            width: 416px;
        }
    }

    &__menu-line {
        font: normal normal 13px/18px $f-avenir;
        letter-spacing: 0.01em;
        padding: 17px 30px 15px;

        +.header {
            &__menu-line {
                border-top: 1px solid $very-light-gray;
            }
        }
    }

    &__menu-title {
        font: normal normal 14px/17px $f-classical-normal;
        letter-spacing: 0.01em;
        margin-bottom: 15px;

        @include bp(min-width, $bp-custom-large) {
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 10px;
        }
    }

    &__menu-body {
        display: flex;
        justify-content: space-between;
    }

    &__menu-tile {
        position: relative;
        width: calc(50% - 10px);
        font: 500 normal 10px/14px $f-avenir;
        color: $white;
        letter-spacing: 0.01em;

        @include bp(min-width, 1920px) {
            font: 500 normal 12px/16px $f-avenir;
        }

        &__swatch {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: transparent;
            border: 1px solid $white;
            padding: 3px 4px;

            @include bp(min-width, 1920px) {
                padding: 4px 5px;
            }
        }

        .text-nowrap {
            white-space: nowrap;
        }

        img {
            width: 100%;
        }
    }
}

.global-banner {
    padding: 10px 0 6px;
    text-align: center;

    .asset {
        border-right: 1px $white solid;
        display: inline-block;
        padding: 0 20px;
        text-transform: uppercase;
        width: 28%;

        @include bp(max-width, $bp-large) {
            border: none;
            display: block;
            width: 100%;
        }

        &:last-child {
            border-right: none;
        }

        p {
            font-size: 13px;
            line-height: 1.3;
            margin: 0;
            padding: 0;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        strong {
            font-size: 13px;
            line-height: 1;
        }
    }
}
.mobile-search-icon {
    .mobile-search-icon-img {
        width: 32px;

        @include bp(min-width, $bp-custom-large) {
            width: auto;
        }
    }
}
