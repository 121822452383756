/*
* Symbolset
* www.symbolset.com
* Copyright © 2012 Oak Studios LLC
*/

@font-face {
    font-family: "SSStandard";
    src: url("../fonts/ss-standard.eot");
    src: url("../fonts/ss-standard.eot?#iefix") format("embedded-opentype"),
    url("../fonts/ss-standard.woff") format("woff"),
    url("../fonts/ss-standard.ttf") format("truetype"),
    url("../fonts/ss-standard.svg#SSStandard") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
