.page-loader {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99998;

    &__background {
        background: $black-two;
        height: 100%;
        opacity: 0.4;
        width: 100%;
    }

    &__img {
        background: url("../images/MGBW-page-loading-animation.gif") center / 40px no-repeat $clr_white;
        border: 3px solid $clr_white;
        border-radius: 50%;
        height: 50px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
    }
}

#wrapper {
    @extend %clearfix;
    background-color: $white;
    border: 0;
    left: 0;
    margin: auto;
    position: relative;
    transition: left 0.2s ease-in;
    width: 100%;
    overflow-x: hidden;
    min-height: 100vh;

    &.pt_categorylanding {
        width: 100%;
        .primary-content {
            padding-bottom: 0;
        }
        .refinements {
            border-right: 0;
            display: none;
            width: 0;
            @include bp(min-width, $bp-medium) {
                display: block;
                width: 20%;
            }
        }
    }

    &.pt_product-search-result,
    &.pt_categorylanding_lifestyle {
        #main {
            max-width: $bp-custom-xxlarge;
        }
    }

    &.pt_product-details,
    &.pt_cart {
        overflow: initial;

        #main {
            max-width: initial;
        }
    }

    #main {
        max-width: $bp-xlarge;
        margin: 0 auto;

        &.default-max-width {
            max-width: none;
        }
    }
}

.primary-content:not(.gift-page-wrapper) {
    @include clearfix;
    width: 100%;

    @include bp(min-width, $bp-medium) {
        float: right;
        padding-left: 16px;
        position: relative;
        width: calc(100% - 242px);

        .primary-focus & {
            border: none;
            float: left;
            margin: 0;
            width: 76%;
        }
    }

    @include bp(min-width, $bp-large) {
        padding-left: 64px;
    }

    .full-width & {
        border: none;
        width: 100%;
        padding-left: 0;
        float: none;
    }
}

.secondary-content {
    clear: both;
}

.pagewrap {
    @include max-width(1280px);
    @extend %clearfix;
    padding: 60px 64px;

    &.open-trade-account {
        max-width: 1300px !important;
        padding: 60px 15px;
    }

    @include bp(max-width, $bp-large) {
        padding: 30px 16px 20px;
    }

    @include bp(max-width, $bp-medium) {
        padding: 8px 16px;
    }

    .sidebar-left,
    #secondary.nav {
        float: left;
        width: 242px;

        @include bp(max-width, $bp-medium) {
            border-top: 1px solid $brand-primary-light;
            padding-top: 20px;
            margin-top: 20px;
            width: 100%;
            float: none;
        }
    }
}

.contact-captcha {
    float: right;
    clear: left;
}

#add-vip-cart {
    display: none;
}
h2.autorenewh2 {
    margin: 1%;
    @include bp(min-width, $bp-medium) {
        margin: 1% 21%;
    }
}

.auto-renew-terms-container {
    display: none;
    .renew-checkbox-container .label-content {
        display: inline-block;
        position: relative;
        top: 12px;
        @include bp(max-width, $bp-medium) {
            width: 83%;
        }
    }
    label[for="autorenew"] {
        @include bp(max-width, $bp-medium) {
            @media only screen and (orientation: portrait) {
                top: -22px;
            }
        }
    }
}
.autorenewterms {
    text-align: center;
    #auto-terms {
        border: 1px solid #000;
        padding: 20px;
        margin: 0 20px 16px;
    }
    h2.autorenewh2 {
        font-size: 25px;
        margin: 1%;
        @include bp(min-width, $bp-medium) {
            font-size: 28px;
            margin: 1% 21%;
        }
    }
    hr {
        border: 0;
        border-top: 1px solid #000;
        display: inline-block;
        width: 50%;
    }
    .scrollarea {
        height: 20vh;
        text-align: left;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding-right: 20px;
        @include bp(min-width, $bp-medium) {
            height: 200px;
        }
    }
    .scrollarea-content {
        text-align: left;
    }
    .cancelterms {
        @include plain-button;
    }
}

.remove-loyalty-pli-dialog {
    text-align: center;

    @include bp(min-width, $bp-medium) {
        max-width: 490px;
    }

    &.ui-dialog {
        .ui-widget-header {
            height: 50px;
        }

        .ui-dialog-titlebar-close {
            top: 14px;
            right: 14px;
        }

        .ui-dialog-content {
            padding: 10px 40px 30px;
        }
    }

    .remove-loyalty {
        &__title {
            font: normal normal 26px/26px $f-classical-normal;
            color: $california-gold;
            text-transform: none;
            letter-spacing: normal;
            margin: 0 0 25px;
        }

        &__zero-title {
            display: none;
        }

        &__body {
            font: normal normal 14px/19px $f-primary;
            color: $brand-v2-dark-gray;
            margin-bottom: 80px;
        }

        &__buttons {
            button {
                font: normal normal 14px/19px $f-primary;
                text-transform: none;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;

                + button {
                    margin-top: 16px;
                }
            }
        }

        &__cancel {
            font-weight: 500;
        }

        &__signin {
            font: normal normal 14px/18px $f-primary;
            color: $brand-v2-off-black;
        }
    }
}

.btn-quickview {
    display: none;
}
