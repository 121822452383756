.comfort-club {
    .comfort-header {
        display: flex;
        align-items: center;

        @include bp(max-width, $bp-small) {
            flex-direction: column;
        }

        .col-50 {
            width: 50%;

            &:first-of-type {
                width: percentage(425/1240);
                margin-right: percentage(90/1240);

                @include bp(max-width, $bp-small) {
                    width: 100%;
                    margin-right: 0;
                }
            }

            @include bp(max-width, $bp-small) {
                width: 100%;
            }

            img {
                width: 100%;
            }

            h3 {
                text-align: center;
            }
        }
    }

    .errorItem {
        color: $c-error;
    }

    .comfort-content {
        padding: 81px percentage(77/1240) 0;

        .comfort-content-top {
            border-bottom: 1px solid $black;

            h3 {
                text-transform: none;
            }

            ul {
                list-style-type: none;
                padding-left: percentage(66/1240);

                li {
                    padding-left: 20px;
                    position: relative;
                    line-height: 40px;

                    &::before {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: inline-block;
                        content: "+";
                    }
                }
            }

            .disclaimer {
                text-align: right;
            }
        }

        .comfort-content-bottom {
            padding-top: 53px;
            text-align: right;

            @include bp(max-width, $bp-small) {
                text-align: center;
            }

            h3 {
                font-weight: bold;
                text-align: center;
                padding-bottom: 10px;
            }

            p {
                text-align: center;
            }
        }
    }

    .sg-form {

        .form-row {
            width: 100%;

        }

        p.required {
            text-align: right;
            color: $c-error;
        }
    }

    .loyalty-success {
        text-align: center;
        margin: 40px 0;

        .comfort-content-bottom {
            text-align: right;
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid #ababaa;
        }
    }
}

.pli-member-savings {
    display: none;
}
