.js {
    .simple-submit {
        display: none;
    }
}

legend {
    border-bottom: 1px solid $brand-primary-light;
    margin-bottom: 32px;
    font-size: 1.1em;
    padding: 16px 0;
    position: relative;
    text-transform: uppercase;
    width: 100%;

    span {
        font-size: 12px;
        font-weight: 400;
        position: relative;
        text-transform: none;
        padding: 0 .5em;
    }
}

.dialog-required {
    display: inline-block;
    position: relative;

    .required-indicator {
        font-size: 0;

        em {
            &::before {
                content: "* ";
                display: inline;
                color: $clr_notification_error;
                font-size: 14px;
                font-style: normal;
            }
        }
    }
}

label {
    display: block;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 26px;

    .label-inline & {
        display: inline;
        float: none;
        font-size: 1em;
        font-weight: 400;
        margin-top: 0;
        text-align: left;
        width: auto;
    }
    .label-above & {
        margin-bottom: .3rem;
        width: auto;
    }
}

.form-label-text {
    float: left;
    font-size: 1.1em;
    font-weight: 700;
    padding: 0.75em 0 0;
    text-align: right;
    width: 25%;
}

.form-row {
    @extend %clearfix;
    margin: 0 0 .6875rem;
    position: relative;

    label {
        background: none;
        color: $clr-dark_mid;
        display: inline-block;
        font: normal normal 14px/16px $f-avenir;
        left: 15px;
        letter-spacing: 0.0025em;
        position: absolute;
        top: 19px;
        transition: all 0.3s;
        z-index: 1;

        &.radio-label,
        &.checkbox-label,
        &.plain-label {
            position: relative;
            left: 0;
            top: 0;
            float: left;
            clear: left;
        }

        &.custom-select {
            + .field-wrapper {
                &::after,
                &::before {
                    display: none !important;
                }
            }
        }
    }


    &.input-focused,
    &.dropdown {
        &> label:first-of-type {
            transform: translate3d(0, -14px, 0);
            font-size: 12px;
            z-index: 1;
        }
    }

    .form-row {
        margin-top: 0;
    }

    &.card-number {
        position: relative;
    }

    &.aligned-with-textfield {
        @include bp(min-width, $bp-small) {
            margin-top: 1px;
        }
    }
}

.IE.nine .form-row.input-focused label {
    top: -14px;
    left: 0;
    font-size: 12px;
}

.field-wrapper {
    box-sizing: border-box;
    margin-bottom: .5rem;
    width: 100%;
    position: relative;

    .label-inline & {
        float: left;
        padding-left: 0;
        width: auto;

        &::before,
        &::after {
            display: none;
        }
    }
    .label-above & {
        float: none;
        padding-left: 0;
        width: auto;
    }

    &.plain-wrapper {
        &::before,
        &::after {
            display: none;
        }
    }
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
textarea {
    background-color: $clr_white;
    border: 1px solid $clr_dark_mid;
    border-radius: 0;
    box-sizing: border-box;
    color: $clr_brand_black;
    font: normal normal 14px/16px $f-avenir;
    height: 50px;
    letter-spacing: 0;
    outline: none;
    padding: 9px 10px 1px 14px;
    text-indent: 0;
    width: 100%;
    -webkit-appearance: none;

    &:active,
    &:focus {
        border-color: $clr_brand_black;
    }

    &:focus {
        outline: none;
    }

    &.error {
        margin-bottom: 1px;
    }
}

textarea {
    padding-top: 21px;
}

input {
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset !important;

        &:hover,
        &:focus,
        &:active  {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        @include appearance(none);
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type="number"] {
        -moz-appearance: textfield;
    }

    &[type="checkbox"],
    &[type="radio"] {
        margin: 1em 1% 1em 2%;

        .label-inline & {
            margin: 0 .3rem 0 0;
        }
    }

    &[type="text"],
    &[type="tel"] {
        &.input-text {
            font-size: 0;

            .input-focused & {
                font-size: 14px;
            }
        }
    }

    &::-ms-clear {
        display: none;
    }

    &#dwfrm_cart_couponCode {
        &::-webkit-input-placeholder {
            display: block !important;
        }
    }
}

select {
    background-color: #fff; // set background color to make height work
    width: 100%;
    height: 2rem;
    .search-result-options & {
        height: auto;
    }
}

.input-text {
    &.error {
        border-color: $clr_notification_error;
    }
}

.form-caption {
    clear: left;
    width: auto;
    .label-above & {
        margin-left: 0;
    }
    .form-indent & {
        margin-left: 0;
    }
}

.error,
.captcha-error {
    color: $clr_notification_error;
}

.account-error {
    background-color: $tutu;
    color: $clr_notification_error;
    padding: 0.7em 0.5em;
}

span.error,
div.error,
.captcha-error {
    background: none;
    color: $clr_notification_error;
    font: normal normal 12px/16px $f-avenir;
    letter-spacing: 0.025em;
    text-align: left;
    display: block;
    padding-top: 4px;
}

span.success {
    background: none;
    color: $field-border-success;
    letter-spacing: 0;
    font: normal normal 11px/14px $f-neue;
    text-align: left;
    display: block;
    padding-top: 6px;
}

.error-form {
    border: 0;
    background: transparent;
    color: $c-error;
    padding: 0 0 20px;

    &.card-decline-error {
        font: normal normal 12px/28px $f-primary;
        color: #ae1e1e;
        padding-left: 32px;
        padding-bottom: 10px;
    }
}

.info-form {
    padding: 0 0 20px;
}

.form-inline {
    form {
        padding: 0 0 2em;
        width: 98%;
    }
    label {
        padding: 0 0 0.75em;
        text-transform: uppercase;
        width: auto;
    }
    input[type="text"],
    input[type="tel"],
    select {
        margin-left: 0;
        width: 95%;
    }
    button {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.require-dialog {
    font-weight: 400;
    position: absolute;
    right: 1em;
    text-transform: none;
    top: 1em;
}

label .required-indicator {
    float: right;
    height: 10px;
    overflow: hidden;
    margin-left: 4px;
    text-indent: -9999px;

    &::before {
        font-size: 14px;
        content: "*";
        line-height: 14px;
        display: block;
        text-indent: 0;
    }
}

label.custom-select .required-indicator {
    margin-left: 3px;

    &::before {
        line-height: 21px;
    }
}

.form-field-tooltip {
    float: right;
    text-align: right;
    margin-left: 3%;
    padding-top: 8px;
}

form > .submit {
    content: "";
    display: block;
    width: 100%;
    clear: both;
    text-align: right;
}

[data-form-msg] {
    display: block;
    clear: both;
    width: 100%;
}

[data-doomed-msg] {
    .alert & p {
        margin: 0;
        padding: 7px 3px;
    }
}

.honeypot {
    display: none;
}

.form-horizontal {
    @include clearfix;

    fieldset .form-row {
        width: calc(50% - 10px);
        float: left;

        &:nth-of-type(2n + 1) {
            margin-right: 20px;
            clear: left;
        }

        @include bp(max-width, 1156px) {
            width: 100%;
            float: none;
        }

        &.full-width {
            @include clearfix;
            width: 100%;
        }

        &.no-margin-right {
            margin-right: 0;
        }
    }
}

#RegistrationFormModal {
    &.reg-form-modal-redesign {
        .form-row {
            width: 100%;
        }
    }
}

#ChangePassowrdForm .form-row,
#dwfrm_wishlist_search .form-row,
#dwfrm_giftregistry_search .form-row {
    float: none;
}

.radio-inline {
    label {
        left: 0;
        top: 0;
        position: relative;
    }
}

#ui-datepicker-div {
    background: $white;
    z-index: 9;
    border: 1px solid $brand-grey;
}

.form-row.captcha {
    margin: 5px 0;

    label {
        display: none;
    }
}

.form-row.subscribe-catalog {
    display: none;
}

.form-for-payment {
    clear: both;
}

.form-row-wrapper {
    margin-bottom: 35px;
}

.address-row,
.email-row,
.phone-row {
    .form-field-tooltip {
        @extend %icon-tooltip;
        position: absolute;
        top: 0;
        right: 12px;
        padding: 0;
        margin: 15px 0;

        a {
            text-decoration: none;
        }
    }
}

.checkout-billing {
    .card-icons {
        position: absolute;
        top: 12px;
        right: 13px;
        z-index: 2;

        img {
            display: none;
            height: 25px;
            width: 36px;
        }
    }
}

.custom-iterator {
    align-items: center;
    border: 1px solid $clr_dark_mid;
    display: flex;
    justify-content: space-between;
    height: 40px;
    position: relative;
    width: 82px;

    &:hover {
        border-color: $clr_brand_black;

        input {
            color: $clr_brand_black;
        }
    }

    &.disabled {
        border-color: $clr_input_notactive;

        &:hover {
            border-color: $clr_input_notactive;
        }

        input,
        .minus,
        .plus {
            color: $clr_input_notactive;
            cursor: auto;
        }
    }

    input,
    .minus,
    .plus {
        width: calc(100% / 3);
    }

    input {
        color: $clr_dark_mid;
        border: none;
        font: 500 normal 14px/18px $f-avenir;
        letter-spacing: 0.025em;
        height: 100%;
        padding: 0;
        text-align: center;

        &.error {
            margin-bottom: 0;
        }
    }

    .minus,
    .plus {
        color: $clr_brand_black;
        cursor: pointer;
        height: 100%;
        position: relative;

        &::before {
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }

    }

    .minus {
        &::before {
            content: "\2013";
        }
    }

    .plus {
        &::before {
            content: "\002B";
        }
    }

    span {
        &.error {
            bottom: 0;
            position: absolute;
            transform: translateY(100%);
        }
    }
}

.open-trade-account {

    .business-type-container {
        display: none;
    }

    .business-type-design-review,
    .business-type-retail-review,
    .business-type-ecommerce-review,
    .business-type-contract-review {
        display: none;
    }

    .progress-bar-container {
        width: 100%;
        margin-top: 30px;

        .progress-bar {
            counter-reset: step 0;
            display: flex;
            justify-content: space-evenly;
            padding: 0;
            margin: 30px 0;

            > li {
                width: 33.33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                font-size: 16px;
                text-align: center;
                line-height: 24px;
                font-weight: 400;
                color: #0e0e0e;

                &.active {
                    font-weight: 600;
                }

                &.active::before {
                    background-color: #323132;
                    color: #fff;
                }

                &::before {
                    content: counter(step);
                    counter-increment: step 1;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background: #fff;
                    border: 2px solid #e0e0e0;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    z-index: 1;
                }

                &:first-child::after {
                    content: "";
                    border: none;
                }

                &::after {
                    content: "";
                    border: 2px solid #e0e0e0;
                    width: 100%;
                    position: absolute;
                    top: 20px;
                    left: -50%;
                }
            }
        }
    }

    .form-review-page {
        .review-company-overview,
        .review-business-overview,
        .review-billing-information {
            margin-top: 30px;

            .card-body {
                padding: 0;
            }
        }

        .edit-section {
            cursor: pointer;
        }

        .sub-heading-title {
            padding: 10px;
            margin: 10px;
            border-bottom: 1px solid #e0e0e0;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: left;
        }

        .form-details-row {
            display: flex;

            .form-detail {
                width: 33.33%;
                padding: 21px 32px 32px;

                @include bp(max-width, $bp-large) {
                    width: 100%;
                }

                .form-detail-label {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 22px;
                }

                .form-detail-value {
                    font-size: 15px;
                    line-height: 24px;
                    font-weight: 400;
                    color: #323132;
                    text-align: left;
                }
            }
        }
    }

    .card {
        border: 1px solid #e0e0e0;

        .card-header {
            font-size: 18px;
            color: #333;
            background-color: #f8f8f8;
            padding: 15px 20px;
            font-weight: 700;
        }

        .card-body {
            padding: 21px 32px 10px;
        }
    }

    #OTAForm {
        &.reg-form-redesign {
            .d-flex {
                display: flex;
            }

            .justify-content-between {
                justify-content: space-between;
            }

            .align-items-center {
                align-items: center;
            }

            .flex-mobile-column {
                @include bp(max-width, $bp-large) {
                    flex-direction: column;
                }
            }

            .company-information {
                .form-cta-buttons {
                    justify-content: flex-end;
                }
            }

            .state-certificate-input-container {
                width: 100%;
                position: relative;
            }

            .state-issued-resale-certificate {
                cursor: pointer;
                display: flex;

                .state-certificate-button {
                    color: #fff;
                    font-size: 15px;
                    font-style: normal;
                    padding: 0 21px;
                    background: #323132;
                    border: 2px solid #323132;
                    height: 50px;
                    margin: 24px 0 0 15px;
                    white-space: nowrap;
                    line-height: 45px;

                    @include bp(max-width, 437px) {
                        margin: 36px 0 0 15px;
                    }
                }

                .state-certificate-input {
                    height: 50px;
                    color: #000;
                    background-color: #fff;
                    border: 1px solid #595959;
                    box-sizing: border-box;
                    width: 100%;
                    position: relative;
                    cursor: pointer;
                }

                input {
                    position: relative;
                    height: 50px;
                    cursor: pointer;
                    top: 12px;
                    width: 85%;
                }

                input[type="file"]::file-selector-button {
                    display: none;
                }

                input[type="file"]::placeholder {
                    height: 50px;
                }

                .icon-wrapper {
                    position: absolute;
                    pointer-events: none;
                    right: 2px;
                    top: 2px;
                    padding: 8px;
                    width: 36px;
                    height: 36px;
                    border-radius: 100%;
                }
            }

            .business-overview,
            .billing-address,
            .form-review-page {
                display: none;
            }

            .primary-details-container {
                margin-top: 30px;
            }

            .form-row {
                clear: none;
                margin-right: 0;
                width: 100%;

                @include bp(min-width, $bp-large) {
                    width: calc(33% - 10px);
                }

                label {
                    font: 700 normal 14px/16px $f-avenir;
                    position: relative;
                    top: 0;
                    left: 0;
                    transition: none;
                    color: #333;
                    white-space: wrap;
                }

                &.dropdown {
                    &> label:first-of-type {
                        transform: none;
                        font-size: 14px;
                        z-index: 1;

                    }
                }

                &.same-height-level-2 {
                    label {
                        height: 32px;
                    }
                }

                &.same-height-level-3 {
                    label {
                        height: 52px;
                    }
                }

                .input-box.ba-select-container {
                    line-height: 11px;

                    &.disabled {
                        pointer-events: none;
                    }
                }

                .ba-options ul {
                    li {
                        margin: 0;
                        padding: 8px 0;

                        .ba-opt-content {
                            padding: 5px;
                        }
                    }
                }
            }

            input {
                padding: 0 10px;

                &[type="text"] {
                    padding: 0 10px;

                    &.input-text {
                        font-size: 15px;
                    }

                    &:disabled {
                        background: #f5f5f5;
                        color: #ccc;
                        cursor: not-allowed;
                    }
                }

                &[type="number"] {
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: button;
                    }
                }
            }

            .field-left {
                @include bp(min-width, $bp-medium) {
                    clear: left;
                    margin-right: 20px;
                }
            }

            .field-middle {
                @include bp(min-width, $bp-medium) {
                    margin-right: 20px;
                }
            }

            .sales-tax-status {
                .input-box.disabled {
                    .ba-shiv {
                        background: #f5f5f5;
                        color: #ccc;
                    }
                    .ba-options {
                        cursor: not-allowed;
                    }
                }

                .form-caption {
                    font-size: 13px;
                    line-height: 18px;
                    color: #a07400;
                }
            }

            .tax-details {
                display: flex;
            }

            .same-as-primary-contact {
                margin-left: 16px;
            }

            .accounts-payable-container {
                margin-top: 30px;

                .header-title-name {
                    display: flex;
                    align-items: center;

                    .form-row {
                        margin: 0 0 0 16px;

                        label {
                            top: -4px;
                            &.checkbox-label::before {
                                top: -2px;
                            }
                        }
                    }
                }
            }

            .accounttype-wrapper,
            .addtoemaillist-margin-override,
            .field-wide {
                width: 100%;
            }

            .no-country {
                display: none;
            }

            .addtoemaillist-margin-override {
                margin-top: -16px;
            }

            .privacy-policy-wrapper {
                margin-top: 30px;
                clear: left;
            }

            .declaration-consent-wrapper {
                margin-top: 30px;

                span {
                    line-height: 30px;
                }

                .declaration-consent {
                    width: fit-content;
                }

                .submit-button {
                    float: right;
                }
            }

            .privacy-policy-consent {
                width: fit-content;
            }

            .data-captcha-box {
                @include bp(min-width, $bp-medium) {
                    float: right;
                    margin-top: -30px;
                    width: calc(50% - 10px);
                }

                .data-captcha {
                    float: right;
                }

                .captcha {
                    &.required {
                        display: none;
                    }
                }
            }
        }

        .form-cta-buttons {
            display: flex;
            justify-content: space-between;

            button {
                padding: 11px 34px;

                @include bp(max-width, $bp-medium) {
                    padding: 11px 25px;
                }

                &:disabled {
                    background-color: #ccc;
                    border-color: #ccc;
                    color: #fff;
                }
            }

            .next-button {
                button {
                    float: right;
                }
            }
        }

        .form-row {
            float: none;

            &.form-row-button.cta {
                margin-top: 40px;
                width: fit-content;
            }

            &.input-focused {
                > label:first-of-type {
                    transform: none;
                }
            }
        }

        .hide-mobile {
            @include bp(max-width, $bp-large) {
                display: none;
            }
        }

        .data-captcha-box {
            @include bp(min-width, $bp-large) {
                float: right;
            }
            .data-captcha {
                display: inline-block;
                @include bp(min-width, $bp-large) {
                    float: right;
                }
            }
        }
    }
}

