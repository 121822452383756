.refinements {
    display: flex;
    flex-wrap: wrap;
}

.refinement-header {
    position: relative;
    font: normal normal 14px/16px $f-avenir;
    color: $clr_brand_black;
    letter-spacing: 0.0025em;
    text-transform: capitalize;
    cursor: pointer;
    border: 0 none;
    min-width: 0;

    @include bp(min-width, $bp-medium) {
        padding: 0 16px 0 0;
        margin: 0 36px 0 0;
    }

    &:active,
    &:focus,
    &:hover {
        text-decoration: underline;
    }

    &::before {
        border: 1px solid $clr_brand_black;
        content: "";
        display: block;
        height: calc(100% + 20px);
        left: -12px;
        margin: 0;
        position: absolute;
        top: -10px;
        width: calc(100% + 26px);
    }

    &::after {
        content: "";
        display: none;
        position: absolute;
        top: 20%;
        right: 0;
        transform: rotate(135deg);
        width: 7.15px;
        height: 7.15px;
        border-top: 1px solid $clr_brand_black;
        border-right: 1px solid $clr_brand_black;

        @include bp(min-width, $bp-medium) {
            display: block;
        }
    }

    &.not-collapsible {
        padding-left: 0;
        cursor: default;

        &::before {
            display: none;
        }
    }
}

.refinement {
    ul {
        list-style: none;

        > li {
            text-indent: 0;
            padding-left: 28px;
            position: relative;
            line-height: 38px;
            display: block;

            &.show-less {
                display: none;
            }
        }

        li > ul {
            padding-left: 0;

            li {
                padding-left: 20px;
            }
        }
    }

    .checkbox-refinements {
        display: flex;
        flex-direction: column;
        > li {
            padding-left: 0;
        }

        li.unselectable {
            order: 1;
            label,
            input {
                opacity: .5;
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }

    .fa {
        @include position(absolute, 0 null null 0);
        font-size: 20px;

        &.fa-angle-right {
            left: 3px;
            top: 3px;

            &.fa-angle-up {
                left: 1px;
            }
        }
    }

    .swatches {
        > li {
            padding-left: 0;
            display: inline-block;
            margin-right: 15px;

            &.clear-refinement {
                display: block;
                margin-bottom: 6px;
            }

            &.selected {
                font-family: $f-neue-bold;

                a {
                    color: $brand-primary-dark;
                }
            }
        }

        .unselectable {
            cursor: not-allowed;
            opacity: .5;
        }

        a {
            text-decoration: none;
        }
    }

    .refinementColor {
        @include bp(min-width, $bp-medium) {
            > li.end-row {
                margin-right: 0;
            }
        }

        > li[class*="swatch"] {
            width: 20px;
            height: 20px;

            a {
                @include hide-text;
                display: block;
                width: 100%;
                height: 100%;
                text-indent: 100%;
            }

            &.selected {
                border: 1px solid $brand-orange;

                a {
                    border: 2px solid $white;
                }
            }
        }

        .swatch-white {
            border: 1px solid $brand-light-grey;
        }
    }

    // Hide Refinements on Category Landing Pages
    .pt_categorylanding & {
        display: none;
        &.category-refinement {
            display: block;
        }
    }

    &.active {
        overflow: visible;
    }
}

.refinement-link {
    @include link($brand-primary-light);
    font: normal normal normal 14px/16px $f-neue;
    text-decoration: none;

    &.active {
        font-family: $f-neue-bold;
        color: $brand-primary-dark;
    }
}

.refinements-toggle {
    order: 2;
    margin: 0;
    cursor: pointer;
    color: $brand-primary-dark;
    padding-top: 2px;
    text-decoration: underline;
    text-transform: uppercase;
}

.search-filters {
    ul {
        list-style: none;
        padding: 2px 0 0;
        margin: 0 0 33px;

        li {
            text-indent: 0;
            line-height: 28px;
        }
    }

    .breadcrumb-relax {
        display: inline-block;
        font-size: 0;
        padding-right: 10px;
        margin-right: 2px;
        position: relative;
        line-height: 15px;

        &::before {
            font-size: 10px;
            font-family: $f-icon;
            content: $icon-close-x;
        }
    }

    .breadcrumb-refinement-value {
        display: block;
        text-transform: lowercase;

        &::first-line {
            text-transform: capitalize;
        }
    }

    a {
        overflow: visible;
        text-decoration: none;
    }

    &:empty {
        display: none;
    }
}

.scrollable {
    height: 10em;
    overflow: auto;
}

.nav {
    ul {
        border: 0 none;
        list-style: none outside none;
        margin: .58em 0;
        padding: 0;
        li {
            list-style: none outside none !important;
            margin: 0 0 .58em !important;
        }
    }
    .toggle {
        cursor: pointer;
        border: 0 none;
        display: block;
        font-size: 1em;
        font-weight: 700;
        margin: .83em 0 .58em;
        text-transform: uppercase;
        span {
            background: none repeat scroll 0 0 transparent;
        }
    }
    .expanded {
        background-position: left -96px;
    }
}

.clear-refinement {
    display: block;
    margin-bottom: 2px;

    a {
        @include link($brand-primary-dark);
        text-decoration: none;
    }
}

.desktop-only {
    display: none;
    @include bp(min-width, $bp-medium) {
        display: block;
    }
}

.sort-by,
.items-per-page {
    float: left;
    position: relative;
    z-index: 1;

    label {
        display: none;
    }

    .ba-select-container {
        display: inline-block;
        @include bp(min-width, $bp-medium) {
            min-width: 220px;
        }
        ul {
            max-height: none !important;
            overflow-y: hidden !important;
        }
    }

    .ba-shiv,
    .ba-options {
        min-width: 184px;
    }

    .ba-shiv {
        padding-top: 0;

        .ba-shiv-content {
            font-family: $f-neue;
            color: $dark-sage;
        }
    }

    @include bp(max-width, $bp-medium) {
        .ba-select-container {
            width: 100%;
        }

        .ba-shiv,
        .ba-options {
            min-width: auto;
        }

        .ba-options {
            padding-right: 32px;
            &::after {
                width: 42px;
            }
            .ba-arrow {
                right: 15px;
            }
        }
    }
}

.sort-by {
    float: none;
    overflow: visible;
    width: auto;
    z-index: 22;
    display: none;
    min-width: 0;
    margin: 0;

    @include bp(min-width, $bp-large) {
        display: block;
    }

    @include bp(max-width, $bp-medium) {
        width: 49%;
        display: inline-block;
        padding-left: 3px;
    }

    .refinement-header {
        display: flex;
        text-transform: none;
    }

    label {
        display: inline-block;
    }

    ul > li {
        padding-left: 0;
        line-height: normal;
    }

    > ul {
        padding: 0;
        margin: 0;

        @include bp(min-width, $bp-medium) {
            &::before {
                display: none;
            }
        }
    }
}

.items-per-page {
    .ba-select-container.sm {
        .ba-shiv,
        .ba-options {
            padding-right: 43px;
        }
    }

    // modifier class if the 'Show All' link is present in the SS
    &.show-all {
        .ba-shiv,
        .ba-options {
            min-width: 135px;
        }
    }

    @include bp(max-width, $bp-large) {
        display: none;
    }
}

.show-more-refinements {
    display: none;
    align-items: center;
    position: relative;
    cursor: pointer;

    @include bp(min-width, $bp-large) {
        display: flex;
    }

    .icon {
        width: 22px;
        margin-right: 10px;
    }
}

#wrapper {
    &.sticky-refinements {
        .refinements {
            position: fixed;
            top: 0;
            z-index: 1000;
        }
    }
}
