// Change the grid settings
$disable-warnings: true;
$gutter: 3.9009em;
$grid-columns: 10;
$content-padding: 4.9%;
$max-width: 1280px;
$container-padding: 64px;

// Mobile
$m-container-padding: 16px;
$m-grid-columns: 5;

// Tablet
$t-container-padding: 39px;

// Small Desktop
$sd-container-padding: 51px;
$sd-grid-columns: 5;
