.search-suggestion-wrapper {
    color: $black;
    background-color: $white;
    height: 100vh;
    margin: 0 auto;
    max-height: calc(100vh - 194px);
    max-width: 1130px;
    overflow-y: auto;
    padding: 0 20px 30px;

    @supports (-webkit-touch-callout: none) {
        padding-bottom: 90px;
    }

    @include bp(min-width, $bp-medium) {
        height: auto;
        max-height: initial;
        padding: 0 40px 44px;
    }

    @include bp(min-width, $bp-large) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @include bp(min-width, 1130px) {
        max-width: 1100px;
        padding-left: 0;
        padding-right: 0;
    }

    &.two-columns {
        @include bp(min-width, $bp-large) {
            display: flex;
        }

        .phrase-suggestions {
            @include bp(min-width, $bp-large) {
                flex: 1;
            }
        }

        .product-suggestions {
            @include bp(min-width, $bp-large) {
                flex: 0 0 66%;
                max-width: 66%;
                margin-right: 70px;
            }
        }
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }

    .hitgroup {
        font: normal normal 14px/19px $f-avenir;
        margin-top: 30px;

        &:first-child {
            @include bp(min-width, $bp-large) {
                margin-top: 0;
            }
        }

        .header {
            color: inherit;
            font: inherit;
            font-weight: 500;
            letter-spacing: initial;
            margin: 0 0 20px;
            text-transform: none;

            @include bp(min-width, $bp-medium) {
                margin-bottom: 23px;
            }
        }

        .hit {
            display: block;
            margin-bottom: 8px;
        }
    }
}

.product-suggestion-wrapper {
    @include bp(min-width, $bp-medium) {
        padding: 0 10px;
    }
}

.product-suggestion-head {
    align-items: center;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    margin-bottom: 10px;

    @include bp(min-width, $bp-medium) {
        margin: 0 -10px 10px;
    }

    &__view-all {
        &__link {
            text-decoration: underline;
        }
    }
}

.product-suggestion-carousel {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px -20px;

    @include bp(min-width, $bp-medium) {
        margin: 0 -10px;
    }
}

.product-suggestions {
    .search-phrase {
        border-bottom: 1px solid $header-search-border-color;
        margin-bottom: 20px;
        padding-bottom: 30px;

        @include bp(min-width, $bp-large) {
            display: none;
        }
    }
}

.phrase-suggestions {
    .search-phrase {
        display: none;

        @include bp(min-width, $bp-large) {
            display: block;
        }
    }
}

.product-suggestion {
    margin-bottom: 20px;
    padding: 0 5px;
    width: 50%;

    @include bp(min-width, $bp-medium) {
        margin-bottom: 0;
        padding: 0 10px;
        width: 25%;
    }

    .product-image {
        margin-bottom: 10px;

        img {
            max-width: 100%;
            width: auto;
        }
    }

    .product-link {
        font: 500 normal 12px/16px $f-avenir;
        color: inherit;
    }
}
