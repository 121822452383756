.ui-dialog {
    &.wishlist-ui-dialog,
    &.lp-wishlist-ui-dialog {
        border-radius: initial;
        width: 550px !important;

        .ui-widget-header {
            display: none;
        }

        .ui-dialog-content {
            padding: 0;
        }

        a:not(.button) {
            color: inherit;

            &:active,
            &:hover,
            &:focus {
                color: inherit;
            }
        }
    }
}

.wl-dialog,
.wl-lp-dialog {
    &__wrapper {
        color: $black;
        padding: 50px 40px;
    }

    &__close {
        cursor: pointer;
        height: 27px;
        position: absolute;
        transform: rotate(45deg);
        right: 12px;
        top: 12px;
        width: 27px;

        &::before,
        &::after {
            background: $black;
            content: "";
            position: absolute;
        }

        &::before {
            height: 1px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            bottom: 0;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            width: 1px;
        }
    }
}

.wl-dialog-add {
    &__wrapper {
        &.new-list {
            .wl-dialog-add {
                &__form {
                    display: flex;
                }

                &__list-wrapper {
                    display: none;
                }

                &__create-list {
                    margin-top: 10px;
                }
            }
        }
    }

    &__title {
        font: normal normal 20px/27px $f-avenir;
        letter-spacing: 0.01em;
        margin: 30px 0 20px;
        text-align: center;

        .create-list,
        .login-success,
        .account-active {
            display: none;
        }
    }

    &__sub-title {
        font: normal normal 16px/22px $f-avenir;
        letter-spacing: normal;
        margin: 0 0 20px;

        span {
            font-style: italic;
        }
    }

    &__form {
        display: none;

        &.focused {
            .wl-dialog-add__button {
                &:not([disabled]) {
                    background: $brand-v2-off-black;
                    color: $white;

                    &::before {
                        display: block;
                    }
                }
            }
        }

        .form-row {
            flex: 1;
        }

        .wl-dialog-add {
            &__name-label {
                display: none;
            }

            &__name {
                border-color: $brand-v2-dark-gray;
                padding-top: 0;
            }
        }
    }

    &__button {
        height: 50px;
        position: relative;
        text-transform: initial;

        &:disabled {
            background: $silver;
            color: $white;
        }

        &::before {
            border: 1px solid $white;
            bottom: 0;
            content: "";
            display: none;
            left: -1px;
            position: absolute;
            right: 0;
            top: 0;
        }

        &.button {
            border-color: $brand-v2-dark-gray;
            border-left: 0;
        }
    }

    &__list-wrapper {
        max-height: 260px;
        padding-right: 12px;
        overflow-y: auto;
    }

    &__create-list {
        display: flex;
        font: normal normal 18px/25px $f-avenir;
        justify-content: space-between;
        letter-spacing: 0.01em;
        margin-top: 30px;
        padding-right: 12px;

        &.hide {
            display: none;
        }
    }

    &__create-list-link {
        align-items: center;
        display: flex;
        text-decoration: none;

        svg {
            height: 19px;
            width: 19px;
        }

        span {
            padding-left: 5px;
            text-decoration: underline;
        }
    }

    &__login {
        display: flex;
        font: normal normal 16px/22px $f-avenir;
        justify-content: flex-end;
        letter-spacing: normal;
        margin-top: 40px;
    }

    &__login-link {
        font: inherit;
        letter-spacing: inherit;
        text-decoration: underline;
    }
}

.wl-dialog-add__wrapper {
    &.new-list,
    &.after-login,
    &.after-registration {
        .wl-dialog-add__title {
            .select-list {
                display: none;
            }
        }
    }

    &.new-list {
        &.after-login,
        &.after-registration {
            .wl-dialog-add__title {
                .create-list {
                    display: none;
                }
            }
        }

        .wl-dialog-add__title {
            .create-list {
                display: inline;
            }
        }
    }

    &.after-login {
        .wl-dialog-add__title {
            .login-success {
                display: inline;
            }
        }
    }

    &.after-registration {
        .wl-dialog-add__title {
            .account-active {
                display: inline;
            }
        }
    }
}
.wl-dialog-list {
    &__wrapper {
        color: inherit;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        margin-bottom: 20px;
        border: 1px solid $brand-v2-dark-gray;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 12px 15px 11px;
        text-decoration: none;

        &.has-product:hover,
        &:hover {
            background: $boulder;

            .wl-dialog-list__link-icon {
                .wishlist-icon {
                    &__added,
                    &__default {
                        display: none;
                    }

                    &__hover {
                        @extend %-show-wishlist-icon;
                    }
                }
            }
        }

        &.product-added {
            background: $boulder;
        }

        &.has-product {
            .wl-dialog-list {
                &__link-icon {
                    .wishlist-icon {
                        &__added {
                            @extend %-show-wishlist-icon;
                        }

                        &__default {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &__link-name {
        align-items: center;
        display: flex;
        font: normal normal 18px/25px $f-avenir;
        letter-spacing: 0.01em;
        max-width: 70%;
    }

    &__link-icon {
        height: 25px;
        margin-right: 10px;
        min-width: 18px;
        width: 18px;
    }

    &__link-count {
        font: normal normal 16px/22px $f-avenir;
        letter-spacing: 0.01em;
    }
}

.wl-lp-dialog-create,
.wl-lp-dialog-edit,
.wl-lp-dialog-delete,
.wl-lp-dialog-share {
    &__title {
        font: normal normal 20px/24px $f-avenir;
        letter-spacing: 0.01em;
        margin: 30px 0;
        text-align: center;
    }

    &__button {
        align-items: center;
        display: flex;
        font: normal normal 16px/22px $f-avenir;
        justify-content: center;
        letter-spacing: 0.05em;
        padding: 11px 41px;
        width: 100%;

        &.btn-default {
            &:not(:disabled) {
                background: $black;

                &:focus {
                    color: $white;

                    &:hover {
                        background: $white;
                        color: $black;
                    }
                }

                &:hover {
                    background: $white;
                }
            }
        }

        &.btn-white {
            color: $black;

            &:hover {
                color: $white;
                background: $black;
            }
        }

        &:disabled {
            color: $white;
        }
    }
}

.wl-lp-dialog-edit {
    &__button {
        margin-bottom: 15px;
    }

    &__delete-link-wrapper {
        text-align: center;
    }

    &__delete-link {
        text-decoration: underline;
    }

    .wl-lp-dialog-delete {
        &__title,
        &__description,
        &__form {
            display: none;
        }
    }
}

.wl-lp-dialog-delete {
    &__description {
        margin: 0 0 30px;
        text-align: center;
    }

    &__form {
        display: flex;
    }

    &__cancel-button,
    &__button {
        flex: 1;
        width: auto;
    }

    &__cancel-button {
        letter-spacing: initial;
        margin-right: 10px;
        text-transform: none;
    }

    .wl-lp-dialog-edit {
        &__title,
        &__form,
        &__delete-link-wrapper {
            display: none;
        }
    }
}

.wl-lp-dialog-share {
    &__title {
        margin-bottom: 20px;
    }

    &__sub-title {
        font: normal normal 18px/25px $f-avenir;
        letter-spacing: normal;
        margin: 0 0 20px;
    }

    &__description {
        font: normal normal 16px/22px $f-avenir;
        letter-spacing: normal;
        margin: 0 0 20px;
    }

    &__input-wrapper {
        position: relative;
    }

    &__input {
        color: $brand-v2-dark-gray;
        font: normal normal 16px/22px $f-avenir;
        height: 45px;
        margin-bottom: 30px;
        padding: 12px 7px 11px 10px;
        width: 100%;
    }

    &__copied {
        bottom: 10px;
        left: 0;
        position: absolute;

        &.success {
            display: none;
        }
    }

    &__button {
        &.btn-default {
            color: $white;

            &:hover {
                color: $black;
            }
        }
    }
}

.wl-dialog-auth {
    $parent: &;

    &__head {
        display: flex;

        &__tab {
            flex: 1;
            padding-bottom: 12px;
            border-bottom: 1px solid $brand-light-grey-2;
            text-align: center;
            text-decoration: none;

            &.active {
                border-bottom: 3px solid black;

                #{$parent}__head__title {
                    opacity: 1;
                    color: black;
                }
            }
        }

        &__title {
            font-size: 20px;
            line-height: 24px;
            font-family: $f-avenir;
            opacity: 0.5;
            color: $scorpion;
            letter-spacing: -0.01em;
        }
    }

    &__body {
        &__tab {
            display: none;

            &.active {
                display: block;
            }
        }

        &__message {
            margin: 20px 0;
            font-family: $f-avenir;
            font-size: 16px;
            line-height: 28px;
            opacity: 0.7;
            letter-spacing: 0.05em;
            color: black;
        }

        [data-form-msg] {
            padding: 0;
            margin: -5px 0 0;
        }

        &__form-row-button {
            margin: 20px 0 0;
        }

        &__extra-row {
            margin-top: 20px;
        }

        &__button {
            width: 100%;
        }

        &__helper-link,
        &__helper-message {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.05em;
            font-family: $f-avenir;
        }

        &__helper-link {
            padding-bottom: 4px;
            border-bottom: 1px solid black;
            text-decoration: none;
            vertical-align: text-bottom;
        }

        &__helper-message {
            margin-right: 5px;
        }

        &__password-reset-from {
            margin-top: 25px;
        }

        &__registration-from {
            .form-caption {
                display: none;
            }
        }
    }
}
