.ui-widget-overlay {
    background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% $night-rider;
    opacity: 0.4;
}

.ui-dialog {
    background-color: $white;
    padding: 0;
    // ovrrides the inline styles set by JS
    z-index: 99999 !important;

    .ui-widget-header {
        border: 0 none;
        border-radius: 0;
        height: 23px;
        text-transform: uppercase;
        background: none;
    }

    .ui-dialog-title {
        color: $brand-primary-dark;
        font-weight: bold;
    }
    .ui-icon-closethick {
        display: none;
    }

    .ui-dialog-titlebar-close {
        background-color: transparent;
        border: 0 none;
        border-radius: 0;
        color: $brand-v2-off-black;
        height: 32px;
        margin: 0;
        min-width: initial;
        opacity: .5;
        padding: 0;
        right: 0;
        top: 0;
        width: 32px;

        &:hover {
            opacity: 1 !important;
        }
        span {
            margin: 0;
            padding: 0;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            @extend %icon-font;
            font-size: 14px;
            content: $icon-close-x;
            padding: 0;
            margin: 0;
            line-height: 36px;
        }

        &:hover::after {
            transform: rotateX(0);
        }
    }
    .dialog-content {
        // prevents weird collapsing content bug
        width: 100% !important;
    }
    .dialog-required {
        font-weight: 400;
        position: relative;
        text-transform: none;
    }
}

.no-close .ui-dialog-titlebar-close {
    display: none;
}

.ui-accordion .ui-accordion-content {
    padding: 0;
}

.ui-tabs {
    .ui-tabs-nav {
        height: 2.45em;
        padding: 0;
        li {
            border-color: $white;
            border-style: solid;
            border-width: 0 0 3px;
            &.ui-state-active {
                border-bottom-color: $citrus;
                a {
                    color: $black;
                    font-weight: 700;
                }
            }
            a {
                color: $nobel;
            }
        }
    }
    .ui-tabs-panel {
        background-color: $white;
        border: 1px solid $white;
        line-height: 1.5em;
        margin: 0;
        padding: 20px 10px;
        h3 {
            @media screen and (min-width: $bp-medium) {
                display: none;
            }
        }
    }
}

#vertical-carousel {
    height: 19rem;
    position: relative;
    overflow: hidden;
    ul {
        height: 10000em;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        li {
            float: left;
            height: 19rem;
            width: 100%;
            .capture-product-id {
                display: none;
            }
        }
    }
    .jcarousel-nav {
        color: $nobel;
        cursor: pointer;
        font-size: 0.9em;
        font-weight: 700;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        &:hover {
            color: $citrus;
        }
        &.inactive {
            display: none;
        }
    }
    .jcarousel-prev {
        bottom: 30px;
    }
    .jcarousel-next {
        bottom: 0;
    }
}

.dialog-cta {
    text-align: right;
}
.requirephone {
    .form-row.form-row-button {
        float: right;
    }
}
