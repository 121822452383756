.gift-certificate-purchase {
    background-color: $white-smoke;
    margin-bottom: 1em;
    padding: .83em;
    display: flex;
    flex-flow: column-reverse;
    position: relative;

    @include bp(max-width, $bp-medium) {
        position: relative;
        padding-top: 20px;
    }

    @include bp(min-width, $bp-medium) {
        flex-flow: row;
    }

    .content-asset {
        @media (min-width: 768px) {
            box-sizing: border-box;
            padding-right: 1rem;
            width: 50%;
        }
        img {
            margin: 1.66em 0;
        }
    }
    form {
        background-color: $boulder;
        box-sizing: border-box;
        width: 100%;
        @media (min-width: 768px) {
            width: 50%;
            margin-top: 60px;
            padding: 0 1.5em;
        }

        .form-row {
            width: 100%;
        }

        .cert-amount {
            width: 40%;
        }
        button {
            width: 12em; //for IE
            width: max-content;
            height: 50px;
        }
        .form-row-button {
            width: 50%;
            margin-left: 5%;
        }
        fieldset {
            display: flex;
            flex-wrap: wrap;
        }
        textarea {
            padding-top: 20px;
        }
    }
    .cert-amount {
        .form-caption {
            margin: 1.66em 0 0;
            position: absolute;
            width: 40%;
        }
        &.error {
            padding-bottom: 2.92em;
        }
    }
    span.error {
        margin-left: 2%;
    }
}

.check-balance {
    border-bottom: 1px solid $gainsboro;
    margin: 0 0 1.66em;
    padding: 0 0 1.66em;

    .form-row {
        @include bp(max-width, $bp-medium) {
            float: none;
            display: block;
            width: 100%;

            input,
            button {
                width: 100%;
            }
        }

        &.gift-cert-balance {
            margin-bottom: 0;
            padding-top: 0;

            .error-message {
                margin-top: 5px;
            }
        }
    }
}

.gift-cert-balance {
    float: left;
    width: 40%;
    label {
        display: none;
    }
    input {
        margin-top: 0;
        width: 93%;
    }
    .form-caption {
        margin-left: 0;
    }
}

.gift-cert-balance-submit {
    float: left;
    clear: none;
}

.gift-cert-balance .label {
    display: none;
}

.gift-certificate-title {
    font: normal 400 32px $f-classical-normal;
    color: $scorpion;
    padding-bottom: 25px;
}
