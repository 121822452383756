.kit {
    &__wrapper {
        color: $black;
        margin: 30px auto 20px;
        max-width: 1090px;
        padding-left: 19px;
        padding-right: 21px;

        @include bp(min-width, $bp-medium) {
            margin: 50px auto 30px;
        }
    }

    &__head {
        margin-bottom: 35px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 55px;
        }
    }

    &__title {
        font: normal normal 26px/31px $f-classical-normal;
        letter-spacing: 0.05em;
        text-align: center;

        @include bp(min-width, $bp-medium) {
            font: normal normal 32px/38px $f-classical-normal;
        }
    }

    &__body {
        display: flex;

        @include bp(min-width, $bp-medium) {
            margin: 0 -30px;
        }
    }

    &__left,
    &__right {
        flex: 1;

        @include bp(min-width, $bp-medium) {
            padding: 0 30px;
        }
    }

    &__left {
        display: none;

        @include bp(min-width, $bp-medium) {
            display: block;
        }

        img {
            width: 100%;
        }
    }

    &__text {
        letter-spacing: 0.01em;
        margin-bottom: 20px;

        @include bp(min-width, $bp-medium) {
            margin: 59px 0;
        }
    }

    &__header {
        font: normal normal 18px/25px $f-avenir;
        margin-bottom: 20px;
        text-align: center;

        @include bp(min-width, $bp-medium) {
            font: normal normal 20px/27px $f-avenir;
            text-align: initial;
        }
    }

    &__copy {
        font: normal normal 14px/19px $f-avenir;

        @include bp(min-width, $bp-medium) {
            font: normal normal 16px/22px $f-avenir;
        }
    }

    &__form {
        .button {
            width: 100%;
            margin-top: 20px;
        }
    }
}
