.pt_account {
    .account-create-page-wrapper,
    .account-login-page-wrapper {
        float: initial;
        padding: 0;
        position: initial;
        width: 100%;
    }

    .thankyou-page {
        padding: 0 20px;
    }

    .thankyou-text {
        text-align: center;
        font-size: 36px;
    }

    .need-help-tel {
        text-decoration: none;
    }
}

.errormsg {
    color: #b00020; /* Red text for error indication */
    background-color: #ffebee; /* Light red background */
    border: 1px solid #f44336; /* Red border */
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.account-create-page-wrapper,
.account-login-page-wrapper {
    color: $black;

    a {
        font: inherit;
        letter-spacing: inherit;
    }
}

.account-login-page-wrapper {
    #password-reset {
        color: inherit;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .col-lg-6 {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        width: 100%;

        @include bp(min-width, $bp-large) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        &.login-box-center {
            @include bp(min-width, $bp-large) {
                margin: 0 auto;
            }
        }

        &.login-box-left {
            @include bp(min-width, $bp-large) {
                border-right: 1px solid $black;
                padding-right: 30px;
            }
        }

        &.login-box-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include bp(min-width, $bp-large) {
                padding-left: 30px;
            }
        }
    }

    .login-box-heading {
        color: inherit;
        font-size: 22px;
        letter-spacing: 0.05em;

        em {
            font-style: normal;
            letter-spacing: 1px;
        }
    }

    .login-box-content {
        button {
            float: initial;
            max-width: initial;
            width: 100%;
        }
    }

    .login-create-account {
        .login-box-heading {
            border-top: 1px solid $black;
            margin: 30px 0 20px;
            padding-top: 30px;

            @include bp(min-width, $bp-large) {
                border: 0 none;
                margin: 0 0 40px;
                padding-top: 0;
            }
        }
    }

    .cc-password-reset {
        .left-section-wrapper,
        .right-section-wrapper {
            width: calc(50% - 40px);
            padding: 30px;
            margin: 20px;
            @include bp(max-width, $bp-medium) {
                width: calc(100% - 20px);
                margin: 10px;
            }
            h3 {
                font: bold normal 18px/28px "NeueHaasUnicaPro-Regular", Arial;
                letter-spacing: 1px;
                margin-bottom: 20px;
                border-bottom: 1px solid #ccc;
                padding-bottom: 10px;
                color: $black;
            }
        }
        .right-section-wrapper {
            float: right;
            border: 1px solid #ccc;
        }
        .left-section-wrapper {
            float: left;
            background: #f2f2f2;
        }
        .cc-set-password-heading {
            text-align: center;
            margin-bottom: 40px;
            h2 {
                font-size: 28px;
                margin-top: 10px;
            }
            p {
                max-width: 700px;
                margin: 10px auto;
            }
        }
    }
}

.account-b2d-message {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0125rem;
    color: $black;
    margin-bottom: 10px;

    @include bp(min-width, $bp-large) {
        text-align: center;
        margin-bottom: 30px;
    }
}

.account-login-title,
.open-trade-account-inquiry-title {
    color: inherit;
    font: normal normal 32px/38px $f-avenir;
    letter-spacing: 1.3px;
    margin: 20px auto 30px;
    text-align: left;
    text-transform: none;

    @include bp(min-width, $bp-large) {
        margin: 0 auto 40px;
    }
}

.open-trade-account-inquiry-content {
    p {
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 28px;
        letter-spacing: 0.0125rem;
        color: $black;

        a {
            color: rgb(0, 0, 238);
        }
    }
}

.password-reset-actions {
    display: flex;
    max-width: 460px;
}

.account-password-reset-email {
    flex: 1;
    margin-right: 10px;

    + button {
        height: 50px;
    }
}

.registration-selection {
    input,
    label {
        display: inline;
        float: none;
    }
}

.payment-list {
    overflow: hidden;
    padding: 0;
    li {
        list-style: none outside none;
        margin: 0 0 28px;
    }

    .delete {
        margin-top: 20px;
    }
}

.pt_order {
    .orderdetails {
        .order-billing,
        .order-payment-instruments,
        .order-shipment-address {
            border: none;
            border-top: $checkout-border;
            padding: 20px 0;
            width: calc(100% - 308px);

            @include bp(max-width, $bp-large) {
                width: 100%;
            }
        }

        .order-billing {
            @include bp(max-width, $bp-large) {
                border-top: none;
            }
        }

        .order-shipments {
            border-bottom: $checkout-border;
            width: 100%;
        }

        .product-line-items-header {
            border-top: $checkout-border;
            border-bottom: $checkout-border;
            font-size: 10px;
            line-height: 10px;
            margin: 0 0 10px;
            padding: 10px 0;
        }
    }

    .orderhistory-actions {
        margin: 20px 0 14px;
    }
}

.order-history-header {
    background: transparent;

    .label {
        font-weight: 700;
    }
    button {
        float: right;
    }
    .order-date,
    .order-status,
    .order-number {
        float: left;
        margin: 2px 0;
        width: 60%;
    }
}

.order-history-items {
    margin: 20px 0;
}

.order-history-table {
    th,
    td {
        padding: 5px 10px;
    }
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        list-style: none outside none;
    }
    .product-name-order-history {
        text-transform: uppercase;
    }
    .order-shipped-to,
    .order-items,
    .order-total-price {
        width: 33%;
    }
    .toggle {
        cursor: pointer;
        display: block;
    }
}

.js .order-history-table .hidden {
    display: none;
}
.account-loyalty {
    padding-top: 34px;
}
.content-asset .account-options,
.account-loyalty .account-options {
    margin: 0;
    padding: 11px 0 0;
    list-style: none;

    li {
        margin-bottom: 37px;
    }
    .fa {
        float: left;
        margin-right: 1em;

        &::before {
            font-size: 37px;

            @include bp(max-width, $bp-medium) {
                font-size: 24px;
            }
        }
    }
    a {
        @include link(inherit);
        text-decoration: none;
    }
    p {
        margin: 0;

        @include bp(max-width, $bp-medium) {
            clear: left;
        }
    }
}

.address-list {
    padding: 0;
    li {
        list-style: none outside none;
        margin: 0;
        padding: 1em 0;
        &.default {
            border-bottom: 1px solid $white-smoke;
            float: none;
            width: auto;
        }
    }
    .mini-address-title {
        font-style: italic;
    }
    a {
        @include link($brand-primary-dark);
        text-decoration: none;
    }
}

#wrapper.pt_customer-service #main {
    padding: 0 20px;
}

#main {
    #secondary.nav {
        float: left;
        width: 242px;

        @include bp(max-width, $bp-medium) {
            border-top: 1px solid $brand-primary-light;
            width: 100%;
            float: none;
        }
    }
}

.loyalty-form {
    .autorenew-button {
        display: none;
    }
    .membership-cancelation {
        label[for="dwfrm_profile_loyalty_autorenew"] {
            display: none;
        }
    }
    .checkbox-label span {
        display: none;
    }
    .checkbox-copy {
        cursor: pointer;
        font-weight: bold;
        position: relative;
        top: -16.5px;
        padding-left: 27px;
    }
    button {
        @include plain-button;
    }
}
.cancelautorenewterms {
    .flex {
        display: flex;
    }

    .flex-horizontal-center {
        justify-content: center;
    }
    .content-wrapper-cancelauto {
        justify-content: center;
        width: 100%;
    }
    h2 {
        border-bottom: 1px solid #000;
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        margin-bottom: 20px;
        text-align: center;
        width: 40%;
        margin: 0 0 15px;
    }
    p {
        padding: 0 16px;
        justify-content: center;
        text-align: center;
    }
    .button-area {
        margin: 30px;
    }
}

#edit-address-form button {
    margin-right: 12px;

    &.apply-button {
        margin-right: 0;
    }
}

#dialog-container {
    .dialog-cta {
        @include bp(max-width, $bp-medium) {
            margin-top: 50px;
        }
    }
}

.accounttype-wrapper {
    width: 48.67%;
    display: inline-block;
    @include bp(max-width, $bp-medium) {
        width: 100%;
        display: block;
    }
    .tooltip-wrapper {
        display: inline-block;
        margin-left: 5px;
        vertical-align: sub;
        .tooltip-icon {
            width: 18px;
        }
    }
    .form-row {
        width: 100%;
        .field-wrapper {
            margin-bottom: 5px;
            margin-right: 20px;
            label {
                position: relative;
                left: 0;
                top: 0;
            }
            &.clear {
                clear: both;
            }
        }
    }
    .radio-container {
        display: block;
        position: relative;
        padding-left: 30px;
        margin: 10px 0 5px;
        cursor: pointer;
        font-size: 14px;
        line-height: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #ccc;
        }
        .checkmark::after {
            content: "";
            position: absolute;
            display: none;
            top: 4px;
            left: 4px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #54584e;
        }
        input:checked ~ .checkmark {
            background-color: #fff;
        }
        input:checked ~ .checkmark::after {
            display: block;
        }
    }
}

.account-primary-content,
.account-sidebar-left {
    color: $black;

    * {
        color: inherit;
    }
}

.account-primary-content {
    .loyalty-banner {
        margin: 16px 0;
    }
}

.account-title {
    font: normal normal 32px/38px $f-classical-normal;
    letter-spacing: 1.3px;
    text-transform: none;
}

.account-welcome-title {
    font-size: 22px;
    padding: 37px 0 22px;
}

.trade-account {
    .loyalty-li {
        display: none;
    }
}
