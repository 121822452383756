.content-slot img {
    height: auto;
    max-width: 100%;
}

.product-listing-1x4 {
    background: none repeat scroll 0 0 $white;
    overflow: hidden;
    padding: .83em 0;
    .search-result-items .grid-tile {
        margin: 0 1% 0 2%;
        width: 18%;
    }
}

.content-asset {
    ul,
    ol {
        line-height: 1.6em;
        margin: 1em 0;
        padding: 0 1.5em;
    }
    address {
        margin: 1em 0;
    }
    ol li {
        list-style: decimal outside none;
    }
}

.browser-compatibility-alert {
    background-color: $sangria;
    color: $white;
    font-size: 1.3em;
    line-height: 2em;
    padding: 0.8em;
}

.folder-slot {
    border-style: solid;
    border-color: $islamic-green;
    border-width: 1px;
    background-color: white;
    padding: 0.5em;

    h2 {
        color: $islamic-green;
        margin: 0.5em 0;
    }
}

.menu-slot {
    display: none;
    margin-top: 0.5em;
    padding-top: 1.5em;
    .social-links {
        padding-left: 0;
        li {
            display: inline-block;
            padding-left: 0;
        }
    }
    .pdp-overlay {
        border-top: 1px solid #faeded;
    }
    @media screen and (min-width: 768px) {
        width: 13.5rem;
        display: block;
    }
}

#home-bottom-left {
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;
    width: 100%;
    h2 {
        color: $black;
        font-family: $sans-serif-alt;
        font-size: 2rem;
        font-weight: 100;
        margin: 0;
        padding: 1rem 0;
        text-transform: uppercase;
        @media screen and (min-width: 768px) {
            font-size: 2.3rem;
        }
    }
    ul {
        margin: -.5em 0 .5em;
        li {
            border-right: 1px solid $dim-gray;
            color: $dim-gray;
            display: inline;
            font-family: $sans-serif-alt;
            font-size: .8rem;
            font-weight: 400;
            list-style: none outside none;
            margin: 0 5px 0 0;
            padding: 0 5px 0 0;
            text-transform: uppercase;
            &:last-child {
                border: 0 none;
            }
            span {
                margin: 0 10px 0 0;
            }
        }
    }
    img {
        display: block;
    }
}

#home-bottom-center {
    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;
    .home-bottom-center-text {
        position: absolute;
        text-align: center;
        top: 18%;
        width: 100%;
        h1 {
            color: $cerulean;
            font-family: $serif;
            font-size: 4rem;
            font-style: italic;
            font-weight: normal;
            margin: 0;
            padding: 0;
            text-transform: lowercase;
        }
        h2 {
            color: $dim-gray;
            font-family: $sans-serif-alt;
            font-size: 1.3rem;
            font-weight: 100;
            letter-spacing: .2em;
            margin-top: -.05rem;
            text-transform: uppercase;
        }
        h3 {
            color: $dim-gray;
            font-family: $serif;
            font-size: .8rem;
            font-weight: normal;
            margin-top: -1rem;
            text-transform: uppercase;
        }
    }
    img {
        display: block;
    }
}

.home-bottom-right {
    font-family: $sans-serif-alt;
    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;
    .home-bottom-right-header {
        h2 {
            color: $dim-gray;
            font-size: 0.7rem;
            font-weight: 400;
            padding: .5em 0 0;
            margin: 0;
            @media screen and (min-width: 768px) {
                font-size: 2.3rem;
                font-weight: 200;
            }
        }
        h3 {
            color: $rain-forest;
            font-size: 2.2rem;
            font-style: normal;
            font-weight: 200;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            @media screen and (min-width: 768px) {
                font-size: 2.3rem;
            }
        }
        img {
            display: block;
        }
    }
    .product-tile {
        border: 0 none;
        margin: 1em auto 0;
        min-height: 255px;
        width: 90%;
        img {
            box-shadow: 0 0 5px 3px $very-light-gray;
            max-width: 90%;
        }
        .product-name {
            height: auto;
            text-transform: uppercase;
            a {
                text-transform: uppercase;
            }
        }
        .product-sales-price {
            color: $black;
        }
    }
}

.category-slot,
.product-slot {
    @include clearfix;

    .catlanding-banner {
        overflow: hidden;
        position: relative;
        width: 100%;
        img {
            height: auto;
            width: 100%;
        }
    }
    .category-tile {
        text-align: center;
        width: calc(33.333% - 40px);
        float: left;
        margin-right: 60px;
        margin-top: 6px;
        padding: 10px 10px 0;

        @include bp(min-width, $bp-medium) {
            margin-top: 25px;

            &:nth-of-type(3n + 3) {
                margin-right: 0;
            }

            &:nth-child(-n + 3) {
                margin-top: 0;
            }
        }

        a {
            @include link($brand-primary-dark);
            text-decoration: none;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        .category-name {
            font: normal normal 12px/20px $f-neue-bold;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-top: 6px;

            @include bp(max-width, $bp-medium) {
                line-height: 18px;
                padding-top: 2px;
                margin-top: 1px;
            }
        }

        @include bpmm($bp-medium, $bp-large) {
            margin-right: 32px;
            width: calc(33% - 21.333px);
        }

        @include bp(max-width, $bp-medium) {
            width: calc(50% - 8px);
            margin-right: 16px;
            padding: 5px 5px 0;

            &:nth-of-type(2n + 2) {
                margin-right: 0;
            }

            &:nth-child(-n + 2) {
                margin-top: 0;
            }
        }
    }
    .search-result-items {
        .grid-tile {
            &:nth-of-type(odd) {
                @include bp(min-width, $bp-medium) {
                    margin-right: 30px;
                }

                @include bp(min-width, $bp-large) {
                    margin-right: 60px;
                }
            }

            &:nth-of-type(-n+2) {
                @include bp(min-width, $bp-medium) {
                    .product-tile::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.cart2-content-row {
    display: block;
    @include bp(min-width, $bp-medium) {
        display: flex;
    }
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

.cart2-content-column {
    background: $boulder;
    width: 100%;
    margin: 10px;
    padding: 10px;
    height: 100%;
    @media screen and (max-width: 768px) {
        margin: 10px 0;
    }

    .h2 {
        text-align: center;
        }

    @include bp(min-width, $bp-medium) {
        float: left;
        width: 30%;
        flex: 1;
    }
}
