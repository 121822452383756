#primary.wishlist-primary-content {
    width: 100%;
    padding: 0;
    float: none;
}

.wishlist-dashboard {
    padding-top: 25px;

    &__container {
        color: $black;
        max-width: 1700px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    &__attic {
        display: none;
        padding: 0 30px;
        margin-bottom: 10px;

        @include bp(min-width, $bp-medium) {
            display: block;
        }

        &__back {
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.006em;
            text-decoration: none;
            color: inherit;

            &:active,
            &:focus,
            &:hover {
                color: inherit;
            }

            &::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 12px;
                margin-right: 6px;
                background: url("../images/chevron-left.svg") no-repeat;
                background-size: contain;
                vertical-align: -2px;
            }
        }
    }

    &__heading {
        color: inherit;
        margin: 0 0 20px;
        font-family: $f-classical-normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        text-transform: none;

        @include bp(min-width, $bp-medium) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    &__create-list-button {
        font-family: $f-avenir;
        font-size: 16px;
        font-weight: normal;
        text-transform: none;

        &:hover {
            background-color: black;
            color: $white;
        }
    }

    &__get-started-section {
        margin: 160px 0 300px;
        text-align: center;

        &__heading {
            margin: 0 0 60px;
            font-size: 24px;
            line-height: 33px;
            color: inherit;
            text-transform: none;
        }
    }

    &__create-list-section {
        margin-bottom: 40px;
        @include bp(min-width, $bp-medium) {
            margin-bottom: 20px;
            text-align: right;
        }

        &__button {
            width: 100%;

            @include bp(min-width, $bp-medium) {
                width: auto;
            }
        }
    }

    &__collections {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__collection {
        display: flex;
        flex-direction: column;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 54px;
        position: relative;

        @include bp(min-width, $bp-medium) {
            border: 1px solid $scorpion;
            border-radius: 5px;
            padding: 31px;
            margin-bottom: 64px;
            max-width: calc(50% - 21px);
        }

        &:active,
        &:focus,
        &:hover {
            @include bp(min-width, $bp-medium) {
                border-width: 2px;
                padding: 30px;
            }
        }

        &__overlay-link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        &__empty {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            text-align: center;
        }

        &__items {
            @extend %clearfix;
            margin-top: -3%;

            &--2 {
                *:nth-child(2) {
                    width: 49%;
                    margin-left: 2%;
                }
            }
        }

        &__item {
            float: left;
            width: 23%;
            margin-left: 2.5%;
            margin-top: 3%;
            aspect-ratio: 1 / 1; // sass-lint:disable-line no-misspelled-properties

            &:first-child {
                width: 49%;
                margin-left: 0;
            }

            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                vertical-align: top;
            }
        }

        &__details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            @include bp(min-width, $bp-medium) {
                margin-top: 20px;
            }

            &__info-wrap {
                padding-right: 15px;
            }

            &__name {
                font-family: $f-avenir;
                font-size: 16px;
                line-height: 22px;

                @include bp(min-width, $bp-medium) {
                    font-size: 18px;
                    line-height: 25px;
                }
            }

            &__share-link {
                font-family: $f-classical-normal;
                font-size: 14px;
                line-height: 17px;
                font-style: italic;
                text-decoration: none;
                color: inherit;
                position: relative;
                z-index: 2;

                @include bp(min-width, $bp-medium) {
                    font-size: 16px;
                    line-height: 19px;
                }

                &:hover {
                    text-decoration: underline;
                }

                &:active,
                &:focus {
                    color: inherit;
                }
            }
        }
    }
}

.wishlist-services {
    $parent: &;

    background-color: $alabaster;
    margin-bottom: 60px;

    @include bp(min-width, $bp-large) {
        margin-top: 25px;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        max-width: 1240px;
        padding: 40px 20px;
        margin: 0 auto;
    }

    &__column {
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center;

        @include bp(min-width, $bp-large) {
            max-width: 33.33333%;
            flex: 0 0 33.33333%;
            padding: 0 20px;
        }

        + #{$parent}__column {
            margin-top: 45px;

            @include bp(min-width, $bp-large) {
                margin-top: 0;
            }
        }
    }

    &__head {
        margin-bottom: 20px;

        @include bp(min-width, $bp-large) {
            margin-bottom: 40px;
        }

        .button-text {
            margin-top: 10px;
            display: inline-block;
            vertical-align: top;
        }
    }

    &__heading {
        font-family: $f-avenir;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.05em;
        color: black;
    }

    &__button-text {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-decoration: none;

        &:active,
        &:focus {
            color: $dark-sage;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__outline-button {
        align-items: center;
        color: $black;
        display: flex;
        font: normal normal 16px/18px $f-avenir;
        justify-content: center;
        height: auto;
        letter-spacing: 0.05em;
        margin: 0 auto;
        max-width: 240px;
        padding: 10px 30px;
        text-align: center;
        text-transform: none;

        &:hover {
            background: $black;
            border-color: $black;
        }
    }

    &__floor {
        margin-top: 15px;
    }
}

.wishlist-single {
    padding: 20px 0 100px;

    @include bp(min-width, $bp-medium) {
        padding-top: 26px;
    }

    &__container {
        max-width: 1760px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    &__attic {
        color: $black;
        margin-bottom: 11px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 6px;
        }

        &__back {
            color: inherit;
            font: normal normal 13px/18px $f-avenir;
            letter-spacing: 0.006em;
            margin: 0;
            text-decoration: none;

            &:active,
            &:focus,
            &:hover {
                color: inherit;
            }

            &::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 12px;
                margin-right: 6px;
                background: url("../images/chevron-left.svg") no-repeat;
                background-size: contain;
                vertical-align: -2px;
            }
        }
    }

    &__name {
        color: $black;
        font: normal normal 24px/29px $f-classical-normal;
        letter-spacing: initial;
        margin-bottom: 18px;
        text-align: center;
        text-transform: none;

        @include bp(min-width, $bp-medium) {
            font: normal normal 32px/38px $f-classical-normal;
            margin-bottom: 10px;
        }
    }

    &__summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 5px;
        margin: 0 -20px 20px;
        border-bottom: 1px solid $brand-light-grey;

        @include bp(min-width, $bp-medium) {
            padding: 0 0 14px;
            margin: 0 0 20px;
        }

        &__aside {
            color: $black;
            margin-left: auto;
            text-align: right;
        }

        &__amount,
        &__share,
        &__edit {
            color: inherit;
            font-size: 12px;
            line-height: 16px;
            font-family: $f-avenir;

            @include bp(min-width, $bp-medium) {
                font-size: 14px;
                line-height: 19px;
            }
        }

        &__share,
        &__edit {
            margin-left: 14px;
            text-decoration: none;

            @include bp(min-width, $bp-medium) {
                margin-left: 40px;
            }

            &:active,
            &:focus,
            &:hover {
                color: inherit;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__empty {
        text-align: center;
    }

    .btn-quickview {
        display: none !important;
    }
}

.pt_wish-list {
    .list-table-header {
        overflow: hidden;
        p {
            float: right;
            margin: 0;
            overflow: hidden;
            width: 40%;
        }
    }
    .account-header {
        margin-bottom: 43px;

        @include bp(max-width, $bp-large) {
            margin-bottom: 28px;
        }
    }
    .primary-content .list-table-header h2 {
        float: left;
    }
    .headings {
        border-bottom: 1px solid;
    }
}

.list-share {
    @extend %clearfix;
    border-top: 1px solid $brand-primary-light;
    padding-top: 28px;

    .share-option {
        box-sizing: border-box;
        display: block;
        margin-bottom: .5rem;
        float: right;
    }
    .share-options {
        text-align: center;
        margin-bottom: .5rem;
        font-size: 1.7em;
        float: right;
        .share-title {
            display: inline;
        }
    }
    .list-title {
        float: left;
    }
    .print-page {
        float: right;
    }
    .share-link {
        cursor: pointer;
    }
    .share-link-content {
        display: none;
        &.active {
            display: block;
            float: left;
        }
    }
}

.wishlist-search,
.registry-search {
    .form-row-button {
        margin-left: 0;
    }
}

.wishlist-list {
    .value {
        clear: both;
    }
    .option-date-added {
        padding-top: 5px;
    }
    tr {
        border-bottom: 1px solid $very-light-gray;

        &:last-child {
            border-bottom: 0;
        }
    }
    fieldset {
        .form-row {
            margin: 0;

            &.option-add-to-cart {
                text-align: right;
                float: right;
                clear: none;
                margin-right: 0;

                .field-wrapper {
                    width: 92px;
                    float: left;
                }
            }
        }
    }
}
