.menu-utility-user {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0 none;

    @include bp(min-width, $bp-custom-large) {
        border: 1px solid $very-middle-gray-v7;
        border-bottom: 0 none;
    }

    a {
        @include bp(min-width, $bp-custom-large) {
            display: inline;
        }

        &.back {
            margin: 0;
        }
    }

    .user-info {
        background: $white-smoke;

        @include bp(min-width, $bp-custom-large) {
            padding: 0;
        }

        .OTA-logo {
            font-size: 14px;
            font-weight: 500;
            color: #222;
            width: 100%;
            text-decoration: none;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            padding: 15px 30px;
            background: none;
            text-align: left;
            border: none;
            border-top: 1px solid rgb(225, 225, 225);
        }

        > a {
            display: block;
            font: 500 normal 14px/19px $f-avenir;
            color: $brand-v2-off-black;
            letter-spacing: 0.01em;
            text-decoration: none;

            @include bp(min-width, $bp-custom-large) {
                display: none;
            }

            &.has-sub-menu {
                font: normal normal 16px/22px $f-avenir;
                text-transform: none;

                .title {
                    font-weight: 800;
                }
            }
        }
    }

    .level-2 {
        text-transform: none;
    }

    li {
        border-bottom: 1px solid $very-middle-gray-v7;

        @include bp(min-width, $bp-custom-large) {
            padding: 12px 30px 10px;
        }

        &.border-bottom {
            border-bottom: 1px solid $very-middle-gray-v7 !important;
        }

        &:last-of-type {
            border: 0 none;
        }
    }

    .user-panel {
        @include bp(min-width, $bp-custom-large) {
            display: block !important;
            position: static;
            overflow: auto;
            padding-bottom: 0;
        }
    }

    .level-2-top {
        text-transform: uppercase;

        @include bp(min-width, $bp-custom-large) {
            display: none;
        }

        &.loyalty-member {
            font: normal normal 15px/18px $f-classical-normal;
            background: $alabaster;
            text-transform: none;

            @include bp(min-width, $bp-custom-large) {
                display: flex;
                padding: 15px 20px;
            }
        }

        a {
            @include bp(min-width, $bp-custom-large) {
                cursor: auto;
            }
        }
    }

    .level-2-multi-lines {
        font: normal normal 12px/16px $f-avenir;
        color: $brand-v2-off-black;
        letter-spacing: 0.01em;
        background: $alabaster;
        padding: 15px 30px;

        @include bp(min-width, $bp-custom-large) {
            padding: 15px 20px;
        }
    }

    .member-exp {
        color: $hot-hands;
    }
}
