// ============================================ *
// Sets the width of the wrapper, so
// its not full width
// ============================================ */
%constrain-wrapper {
    max-width: $bp-xlarge;
    display: block;
    margin: 0 auto;
}

// ============================================ *
// icon-font placeholder
// ============================================ */
@mixin icon-font {
    font-family: $f-icon;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 21px;
    font-size: 19px;
}
%icon-font {
    @include icon-font;
}

// ============================================ *
// Image hero top gradient
// ============================================ */

%image-hero-top-gradient {
    content: "";
    display: block;
    position: absolute;
    height: 64px;
    top: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(-180deg, #2e2d2a 1%, #2e2d2a 33%, rgba(36, 36, 36, 0) 100%);
    opacity: .35;

    @include bp(min-width, $bp-medium) {
        height: 96px;
    }

    @include bp(min-width, $bp-large) {
        height: 120px;
    }

    @include bp(min-width, $bp-xlarge) {
        height: 140px;
    }
}

%anchor-no-decoration {
    text-decoration: none !important;
}

%collapse-header {
    text-transform: uppercase;
    display: block;
    font: normal normal 14px/18px $f-neue-bold;
    color: #54584e;
    letter-spacing: 1px;
    min-width: 157px;
    padding: 14px 5px;
    border: 1px solid $very-light-gray;
    border-width: 1px 0;
    position: relative;
    cursor: pointer;

    &::before {
        @extend %icon-font;
        transition: transform $default-transition-duration $default-transition-function;
        content: $icon-select-down-arrow;
        position: absolute;
        top: 50%;
        margin-top: -11.8px;
        right: 10px;
        height: 21px;
        display: block;
        font-size: 8px;
    }
}
