// Order History - Order Details Styling

.pt_order,
.confirmation {
    .primary-content {
        min-height: 0;
    }
    .page-content {
        @include max-width(1280px);

        @include bpmm($bp-medium, 959px) {
            .primary-content {
                width: calc(100% - 258px);
            }
        }
    }
    .order-confirmation-details {
        width: 100%;
        padding: 1rem 0;
    }
    .actions {
        @include clearfix;
        text-align: right;
    }
    .orderhistory-actions {
        @include clearfix;
        text-align: center;

        @include bp(min-width, $bp-custom-small) {
            .button-back {
                float: left;
            }
            .button {
                float: right;
            }
        }
    }
    .search-result-options {
        .results-hits {
            float: left;
            margin-right: 14px;
            padding-top: 6px;
        }
        ul {
            padding-left: 0;
            list-style: none;
        }

        li {
            display: inline-block;

            a,
            &.current-page {
                padding: 6px 4px;
            }
        }
    }
    .login-create-account {
        display: none;
    }
    .order-shipment-address {
        div.phone {
            display: none;
        }
    }
}

.orderhistory-subtitle {
    a {
        color: inherit;
        text-decoration: none;
    }
}

.order-history-header {
    background-color: $white-smoke;
    overflow: hidden;
    padding: 10px;
    .label {
        font-weight: 700;
    }
    button {
        float: right;
    }
    .order-date,
    .order-status,
    .order-number {
        float: left;
        margin: 2px 0;
        width: 60%;
    }
}
.orders-list {
    clear: both;
    list-style: none;
    padding: 0;
    margin: 0;
}
.order-history-items {
    margin: 20px 0;
    .order-history-table {
        th,
        td {
            padding: 5px 10px;
        }
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            list-style-position: inside;
            list-style-type: square;
        }
        .order-shipped-to,
        .order-items,
        .order-total-price {
            width: 32%;
        }
        .toggle {
            cursor: pointer;
            display: block;
        }
        tr {
            border-bottom: 1px solid $brand-primary-light;

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
.js .order-history-table .hidden {
    display: none;
}

// Details Styling

.orderdetails {
    @include clearfix;
    font-family: $f-neue;

    h2 {
        clear: both;
        font-size: 24px;
    }

    div.label {
        margin-bottom: .3rem;
        text-transform: uppercase;
    }

    span.label {
        text-transform: none;
        font-weight: bold;
    }

    address {
        font: inherit;
    }

    .inner-flex-wrapper {
        width: 100%;
    }
    .flex-wrapper {
        display: flex;
        flex-direction: column;
        border-top: $checkout-border;
        font-size: 12px;
        line-height: 20px;
        word-break: break-word;

        @include bp(min-width, $bp-large) {
            display: inline-block;
            display: flex;
            flex-direction: row;
            width: calc(100% - 20px);
            padding: 25px 0;
        }
    }
    .order-billing,
    .order-payment-instruments,
    .order-shipment-address {
        display: inline-block;
        vertical-align: top;
        padding: 1rem 0;
        border: 0 solid $c-fieldset-border;
        border-width: 0 0 1px;
        @include bp(min-width, $bp-large) {
            width: 33%;
            padding: 0 19px;
            border-width: 0 1px 0 0;
        }
    }
    .order-shipment-address {
        .email {
            padding-top: 10px;
        }
        @include bp(min-width, $bp-large) {
            padding-left: 0;
        }
    }
    .order-payment-instruments {
        border-right: none;
        @include bp(min-width, $bp-large) {
            padding-right: 0;
        }
    }
    .order-shipments {
        @include bp(min-width, $bp-large) {
            display: inline-block;
            width: calc(100% - 20px);
        }
        .item-edit-details,
        item-price span,
        span.plus,
        span.minus {
            display: none !important;
        }
        .item-details {
            .label {
                font-weight: normal;
                text-transform: capitalize;
            }
        }
        .quantity {
            border: none !important;
        }
        #cart-table,
        #cart-table-gift {
            .item-image {
                width: 30%;

                @include bp(min-width, $bp-large) {
                    width: 15%;
                }
            }
            .item-details {
                width: 70%;
                @include bp(min-width, $bp-large) {
                    width: 60%;
                }
                .prop65-wrapper {
                    display: block;
                    .prop65-callout {
                        position: static;
                     }
                }
            }
            input[type="number"] {
                text-align: right;
                float: right;
                @include bp(min-width, $bp-large) {
                    text-align: left;
                    float: none;
                    height: 24px;
                }
            }
            .item-total .pricing-reg-club .product-club-pricing ,
            .item-total .pricing-reg-club .product-price-non-member {
                display: none;
                &.bold {
                    display: flex;
                    font-weight: normal;
                }
                .label {
                    display: none;
                }
            }

            .item-total,
            .item-quantity {
                font: normal normal 14px/19px $f-primary;
                color: $brand-v2-off-black;
            }

            .item-quantity {
                margin-bottom: -45px;
                .quantity {
                    width: 30px;
                }
                input {
                    text-align: left;
                }
                @include bp(min-width, $bp-large) {
                    width: auto;
                }
            }
            .item-total {
                width: 50%;
                clear: both;
                @include bp(min-width, $bp-large) {
                    width: 70%;
                }
            }
        }
    }

    .line-items {
        clear: both;
        padding-top: 1rem;
        .line-item {
            padding-bottom: 1rem;
            .line-item-details,
            .line-item-quantity,
            .line-item-price {
                display: inline-block;
                vertical-align: top;
                // inline-block margin trick
                margin-right: -4px;
            }
            .line-item-quantity,
            .line-item-price {
                width: 15%;
            }
            .line-item-details {
                width: 70%;
                .name {
                    a {
                        text-transform: uppercase;
                    }
                }
                a {
                    font-family: $f-neue-bold;
                    text-decoration: none;
                    &:hover {
                        color: $black-two;
                    }
                }
            }
            .line-item-quantity {
                text-align: center;
            }
            .line-item-price {
                text-align: right;
            }
        }
    }
    .order-information {
        color: $black;
        font-family: $f-avenir;
        padding-bottom: 1rem;
        padding-top: .5em;
        .order-number {
            pointer-events: none;

            a {
                text-decoration: none;
                color: inherit;
            }
        }

        span.label {
            text-transform: uppercase;
        }

        .confirmation-create-account-asset {
            text-align: center;

            @include bp(min-width, $bp-large) {
                border-bottom: $checkout-border;
                margin-bottom: 20px;
            }

            h3 {
                margin-top: 26px;
                padding-top: 28px;
                border-top: $checkout-border;
                text-transform: capitalize;
            }

            p {
                max-width: 700px;
                margin: auto;
                margin-bottom: 20px;
            }

            button {
                margin-bottom: 35px;
            }
        }
    }
    .order-totals-table td {
        &:first-child {
            text-align: left;
        }
    }
    .prop65-wrapper.pli .prop65-full-message {
        padding-top: 0;
    }
    .item-list {
        &.gift {
            .label {
                text-transform: uppercase;
            }
            .order-shipment-address {
                border: none;
                width: 100%;
            }
            .order-gift-cert-to,
            .order-gift-cert-from {
                .label,
                .label + .value {
                    display: inline-block;
                    font-family: $f-neue;
                    font-weight: normal;
                    text-transform: none;
                }
            }
            .item-quantity {
                min-width: 46px;
            }
        }
    }
}

.wrap-for-sticky {
    display: block;
    @include bp(min-width, $bp-large) {
        display: flex;
    }
    .sticky-track {
        display: block;
        @include bp(min-width, $bp-large) {
            float: right;
        }
    }
}

.order-payment-summary {
    background: $brand-section-grey;
    padding: 1rem;
    width: 100%;
    border: $checkout-border;
    @include bp(min-width, $bp-large) {
        float: right;
        width: 285px;
        position: sticky;
        top: 0;
    }
    .keep-shopping a {
        width: 100%;
        height: 45px;
        line-height: 43px;
        padding: 0;
        color: $brand-primary-dark;
        border-color: $brand-primary-dark;
        background: $white;
        &:hover {
            color: $white;
            background: $brand-primary-dark;
        }
    }
}

.confirmation-message {
    border-bottom: none;
    text-align: left;
    margin: 36px 0 0;
    padding: 0;

    &__title {
        text-transform: none;
    }

    .content-asset a {
        text-decoration: none;
        color: inherit;
    }
}

.confirmation {
    @include max-width($bp-xlarge);
    margin: 0 auto;
    padding: 0 64.5px;

    @include bp(max-width, $bp-large) {
        padding: 0 16px;
    }

    &.create-account {
        .login-create-account,
        .order-confirmation-details {
            @include clearfix;
            h2 {
                font-size: 24px;
            }

        }
        .login-create-account {
            @include bp(min-width, $bp-medium) {
                float: right;
                width: 306px;
            }
        }
        .order-confirmation-details {
            @include bp(max-width, $bp-medium) {
                float: none;
                width: 100%;
            }
        }
        .login-box-content {
            p {
                margin-top: 0;
                margin-bottom: 28px;
            }
        }
    }
}

.cumfortclub-confirmation {
    .confirmation-message {
        border-bottom: 1px solid $very-light-gray;
        margin-bottom: 20px;
        p {
            margin: 5px 0 20px;
            &.confirmation-description {
                margin-bottom: 0;
            }
        }
    }
    .left-section {
        float: left;
        width: 70%;
        margin-right: 5%;
        position: relative;
        img {
            width: 100%;
        }
        @include bp(max-width, $bp-custom-large) {
            width: 100%;
            float: none;
        }
    }
    .right-section {
        float: right;
        width: 25%;
        @include bp(max-width, $bp-custom-large) {
            width: 100%;
            float: none;
        }
        .login-section {
            background: $white-smoke;
            padding: 20px;
            float: right;
            min-height: 408px;
            @include bp(max-width, $bp-custom-large) {
                min-height: 200px;
                margin: 20px auto;
            }
            width: 100%;
            h2 {
                font-size: 18px;
                letter-spacing: 1px;
                font-weight: 600;
                border-bottom: 1px solid $very-light-gray;
            }
            p {
                font-size: 14px;
            }
            .button {
                width: 100%;
            }
        }
    }
    .image-overlay-content {
        position: absolute;
        top: 45px;
        left: 50px;
        color: $white;
        @include bp(max-width, $bp-small) {
            top: 5px;
            left: 10px;
        }
        h2 {
            font: normal normal 40px/49px "ClassicalGaramondBT-Roman", serif;
            color: $white;
            text-transform: none;
            letter-spacing: 0;
            @include bp(max-width, $bp-custom-small) {
                font-size: 28px;
                line-height: 30px;
            }
        }
        p {
            font: italic normal 28px/28px "ClassicalGaramondBT-Roman", serif;
            margin: 4px 0;
            @include bp(max-width, $bp-custom-small) {
                font-size: 20px;
                line-height: 24px;
            }
        }
        .ex-button--inverse {
            border: 2px solid $white;
            color: $white;
            font: 16px/20px $f-primary;
            padding: 17px 30px;
            text-transform: none;
            width: 200px;
            height: auto;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 600;
            letter-spacing: 1px;
            margin-top: 26px;
            display: block;
            text-align: center;
            &:hover {
                background-color: $black;
            }
            @include bp(max-width, $bp-small) {
                font: 14px/20px $f-primary;
                padding: 5px;
                width: 120px;
                margin-top: 24px;
            }
        }
    }
}

.confirmation-find-mine-img {
    display: none;
    width: 1px;
    height: 1px;
}
