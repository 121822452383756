//
// Styles for Super Select Custom Form Elements for
// Highly Stylized Select Elements with Custom
// Functionality
//
// @package     BlueAcorn/SuperSelects
// @version     1.0.0.
// @author      Blue Acorn <code@blueacorn.com>, Greg Harvell <greg@blueacorn.com>
// @copyright   Copyright © 2015 Blue Acorn, Inc.
//

.ba-select-input {
    border-radius: 0;
    border: 1px solid $clr_brand_black;
    height: 50px;
    left: -999em;
    padding: 7px 14px 0;
    position: absolute;
    -webkit-appearance: none;

    &.error {
        color: $clr_brand_black;
        background: $clr_white;
        border-color: $clr_notification_error;
    }
}

.superselects-content {
    padding: 20px;

    .fields,
    .fields .field,
    .wide {
        margin-bottom: 40px;
    }
}

.ba-select-box {
    position: relative;
    &.open {
        .ba-options {
            display: block;
        }
        &.ba-over,
        &.ba-thumbnail {
            .ba-shiv {
                box-shadow: 0 0 0 rgba(255, 255, 255, 0);
            }
        }
    }
}

.ba-select {
    display: inline-block;
    width: 100%;

    &.disabled {
        @include opacity(.75);
    }
}

.ba-shiv {
    border: 1px solid $clr_dark_mid;
    background: $clr_white;
    color: $clr_brand_black;
    cursor: pointer;
    float: left;
    font: normal normal 14px/50px $f-avenir;
    height: 50px;
    letter-spacing: 0.0025em;
    padding: 2px 50px 0 14px;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @include ie8 {
        border: 1px solid $clr_dark_mid;
    }
}

.ba-options {
    display: none;
    position: absolute;
    top: 1px;
    left: 0;
    box-sizing: border-box;
    width: auto;
    right: 0;
    padding: 13px 50px 11px 22px;
    cursor: pointer;
    max-height: 49px;
    transition: all 0.4s;

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        height: 30px;
        width: 48px;
        border-left: 1px solid $clr_dark_mid;
        top: 9px;
        transition: all 0.5s;
    }

    .ba-arrow {
        &.ba-arrow {
            content: $icon-select-down-arrow;
            transition: transform $default-transition-duration $default-transition-function;
            @extend %icon-font;
            display: block;
            right: 19px;
            top: 20.5px;
            position: absolute;

            &::before {
                content: $icon-select-down-arrow;
                @extend %icon-font;
                color: $clr_brand_black;
                font-size: 7px;
                line-height: 10px;
                display: block;
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        min-width: 100px;

        li {
            clear: both;
            color: $clr_dark_mid;
            cursor: pointer;
            float: left;
            font: normal normal 14px/16px $f-avenir;
            letter-spacing: 0.0025em;
            margin: 1px 0;
            padding: 7px 5px;
            width: 100%;

            &:hover,
            &:focus {
                background-color: $clr_light_mid;
            }

            &.selected {
                color: $clr_brand_black;
            }
        }
    }
}

.ba-select-close {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 9998;
}

.ba-over {
    &.open {
        z-index: 9999;

        .ba-options {
            background-color: $clr_white;
            border: 1px solid $clr_brand_black;
            margin-top: -1px;
            max-height: 9999px;
            overflow-x: hidden;
            visibility: visible;

            &::before {
                max-height: 999px;
                z-index: -1;
            }

            &::after {
                border-color: $clr_brand_black;
            }

            ul {
                visibility: visible;

                li {
                    opacity: 1;

                    &:first-child {
                        display: none;
                    }
                }
            }

        }

        .ba-arrow {
            &.ba-arrow {
                transform: rotateX(180deg);
            }
        }
    }

    .ba-options {
        display: block;
        visibility: visible;

        &::before {
            background: $clr_white;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            max-height: 0;
            box-sizing: border-box;
            left: 0;
            top: 0;
            transform-origin: 50% 0%;
            border: none;
            transition: max-height 0.3s;
        }

        ul {
            visibility: hidden;
            background: $clr_white;

            li {
                opacity: 0;
                transition: opacity 0.3s;
                transition-delay: 0.2s;
            }
        }

        @include ie8 {
            border: 1px solid $clr_white;
            left: 0;
            position: absolute;
            top: 0;
            @include gradient-vertical($clr_white, darken($clr_white, 2%));
        }
    }
}

.ba-select-container.error {
    padding-top: 0;

    .ba-shiv {
        border-color: $clr_notification_error;
    }

    .ba-over {
        .ba-options {
            border-color: $clr_notification_error;

            &::after {
                border-color: $clr_notification_error;
            }
        }
    }

    .open {
        .ba-shiv {
            border-bottom: 0;
            visibility: hidden;
        }
    }

    span {
        &.error {
            @include bp(min-width, $bp-large) {
                margin-top: -3px;
            }
        }
    }
}

.ba-select-box.ba-left,
.ba-select-box.ba-right,
.ba-select-box.ba-under {
    .ba-options {
        display: block;
        float: left;
        width: auto;
        min-width: 0;

        position: absolute;
        left: auto;
        opacity: 0;
        visibility: hidden;
        transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
        &::after {
            position: absolute;
            display: block;
            visibility: visible;
            content: " ";
            text-indent: -999em;
        }
    }
    &.open {
        .ba-options {
            opacity: 1;
            visibility: visible;
            &::after {
                visibility: visible;
                opacity: 1;
            }
        }
        .ba-shiv {
            color: $white;
            border-color: $black-60;
            @include gradient-vertical($black-60, $black-70);
        }
        .ba-shiv-content {
            color: $white;
        }
    }
}

.ba-left,
.ba-right {
    .ba-options {
        top: 50%;
    }
}

.ba-left {
    .ba-options {
        transform: translate3d(30%, 0, 0);
    }
    .ba-options::after {
        content: "  ";
        display: block;
        right: -14px;
        background: url("../images/blueacorn/super-selects/super-selects-sprite.png") no-repeat -73px -2px;
        width: 14px;
        height: 20px;
        top: 50%;
        margin-top: -10px;
    }
    &.open {
        .ba-options {
            transform: translate3d(0, 0, 0);
        }
        .ba-arrow {
            background-position: -36px -77px;
            width: 10px;
            height: 15px;
            margin-top: -8px;
        }
    }
}

.ba-right {
    .ba-options {
        transform: translate3d(-30%, 0, 0);
    }
    .ba-options::after {
        content: "   ";
        display: block;
        left: -14px;
        background: url("../images/blueacorn/super-selects/super-selects-sprite.png") no-repeat -70px -35px;
        width: 14px;
        height: 20px;
        top: 50%;
        margin-top: -10px;
    }
    &.open {
        .ba-options {
            transform: translate3d(0, 0, 0);
        }
        .ba-arrow {
            background-position: -36px -37px;
            width: 10px;
            height: 15px;
            margin-top: -8px;
        }
    }
}

.ba-under {
    .ba-options {
        top: 100%;
        width: 100%;
        transform: scale(.85) translate3d(0, -10%, 0);
        &::after {
            content: "    ";
            top: -14px;
            left: 50%;
            margin-left: -10px;
            width: 19px;
            height: 14px;
            background: url("../images/blueacorn/super-selects/super-selects-sprite.png") no-repeat -68px -68px;
        }
    }
    &.open {
        .ba-options {
            transform: scale(1) translate3d(0, 0, 0);
        }
        .ba-arrow {
            background-position: -34px 0;
        }
    }
}

.ba-thumbnail {
    .ba-options {
        background: none;
        display: block;
        visibility: hidden;
        box-shadow: 0 0 0 rgba(255, 255, 255, 0);
        border: 0;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transform: scale3d(1, 0, 1);
            transform-origin: 50% 0%;
            @include gradient-vertical($white, darken($white, 2%));
            border-radius: 5px;
            box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
            border: 1px solid $white;
            transition: transform 0.4s;
        }

        @include ie8 {
            border: 1px solid $black-20;
            left: 0;
            position: absolute;
            top: 0;
            @include gradient-vertical($white, darken($white, 2%));
            ul {
                width: 100%;
                float: left;
                li.first-child {
                    float: left;
                    width: 100%;
                }
            }
        }
    }
    .ba-shiv {
        height: 50px;
        padding-left: 0;
        .ba-shiv-content,
        .ba-img-span,
        .ba-img {
            float: left;
            line-height: 50px;
            margin-right: 5px;
        }
        .ba-arrow::after {
            height: 50px;
            line-height: 50px;
        }
    }
    ul li {
        padding: 5px;
        width: 20%;
        clear: none;
        float: left;
        text-align: center;
        opacity: 0;
        transition: opacity 0.4s;
        transition-delay: 0.2s;
        &.first-child {
            width: 100%;
        }
        @include ie8 {
            ul li {
                opacity: 1;
            }
        }
    }
    .ba-img-span {
        display: inline-block;
        width: 50px;
        height: 50px;
        background-position: 50% 50%;
        background-size: 100% auto;
        border-radius: 5px;
    }
    .ba-opt-content {
        @include g-all();
    }
    &.open {
        ul li {
            opacity: 1;
        }
        .ba-options {
            visibility: visible;
            &::before {
                transform: scale3d(1, 1, 1);
                z-index: -1;
            }
        }
    }
}

.ba-overlay {
    &.open .ba-shiv {
        @include box-shadow(0 0 0 rgba(255, 255, 255, 0));
    }
    .ba-options {
        top: 50%;
        background: none;
        display: block;
        visibility: hidden;
        overflow: hidden;

        box-shadow: 0 0 0 rgba(255, 255, 255, 0);
        border: 0;
        transform: scale3d(1, 0, 1);
        transform-origin: 50% 50%;
        transition: transform 0.4s;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transform: scale3d(1, 0, 1);
            transform-origin: 50% 50%;
            @include gradient-vertical($white, darken($white, 2%));
            border-radius: 5px;
            box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
            border: 1px solid $white;
            transition: transform 0.4s;
        }

        @include ie8 {
            position: absolute;
            border: 1px solid $black-20;
            left: 0;
            top: 0;
            @include gradient-vertical($white, darken($white, 2%));
            ul {
                width: 100%;
                float: left;
                li.first-child {
                    float: left;
                    width: 100%;
                }
            }
        }
    }
    &.open .ba-options {
        visibility: visible;
        transform: scale3d(1, 1, 1);
        &::before {
            transform: scale3d(1, 1, 1);
            z-index: -1;
        }
    }
    & + .ba-select-close {
        background: url("../images/blueacorn/super-selects/overlay-background.png") 0 0 repeat;
    }
}

.ba-fullscreen {
    .ba-options {
        display: block;
        text-align: center;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 0;
        box-shadow: 0 0 0 rgba(255, 255, 255, 0);
        background: url("../images/blueacorn/super-selects/overlay-background-white.png") 0 0 repeat;
        visibility: hidden;
        opacity: 0;
        transform: scale(.9);
        transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
        ul {
            position: absolute;
            top: 50%;
            width: 100%;
            li {
                opacity: 0;
                transform: translate3d(30%, 0, 0);
                transition: transform 0.3s, opacity 0.3s;
                @include ie8 {
                    &.hover span {
                        border-bottom: 2px solid $black;
                        color: $black;
                    }
                }
            }
            li span {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 20px;
                color: $brand-primary;
                border-bottom: 2px solid rgba(255, 255, 255, 0);
                &:hover {
                    border-bottom: 2px solid $black;
                    color: $black;
                }
            }
            li.selected span {
                border-bottom: 2px solid $brand-primary;
            }
        }
    }
    &.open .ba-options {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
        transition: transform 0.5s, opacity 0.5s;
        li {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    & + .ba-select-close {
        position: fixed;
        left: auto;
        right: 20px;
        top: 20px;
        width: 50px;
        height: 50px;
        z-index: 9999;
        cursor: pointer;
        &::after {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            content: "␡";
            color: $black;
            font-family: $f-stack-icon;
            font-size: 40px;
            text-align: right;
        }
    }
}

.ba-fullthumb {
    .ba-shiv {
        line-height: 30px;
        .ba-color-name,
        .ba-shiv-content {
            display: inline-block;
        }
        .ba-color-box {
            width: 20px;
            height: 20px;
            border-radius: 3px;
            position: relative;
            top: 5px;
            display: none;
            float: left;
        }
    }
    &.setup {
        .ba-shiv {
            .ba-color-box {
                display: inline-block;
            }
            .ba-shiv-content {
                margin-left: 5px;
            }
        }
    }
    .ba-options {
        display: block;
        text-align: center;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 0;
        box-shadow: 0 0 0 rgba(255, 255, 255, 0);
        background: url("../images/blueacorn/super-selects/overlay-background.png") 0 0 repeat;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s, visibility 0s 0.2s;
        ul {
            width: 100%;
            padding: 20px;
            li {
                white-space: normal;
            }

            li span {
                font-size: 32px;
                line-height: 40px;
                color: $brand-primary;
                border-bottom: 2px solid rgba(255, 255, 255, 0);
                &:hover {
                    border-bottom: 2px solid $black;
                    color: $black;
                }
            }
        }
        li {
            padding: 0;
            @include g-item(max, $bp-large + 1, 5, 980, 19.5);
            @include g-item($bp-large, $bp-small + 1, 3, 600, 13);
            @include g-item($bp-small, 320px, 2, 320, 6.5);
            margin-bottom: 20px;
            clear: none;
            float: left;
            text-align: center;
            background: $white;
            border-radius: 5px;
            transition: transform 0.3s, opacity 0.3s;
            transform: scale3d(0.8, 0.8, 1);
            height: 95%;
            opacity: 0;
            @include ie8 {
                &.hover span {
                    border-bottom: 2px solid $black;
                    color: $black;
                }
            }
            &.first {
                display: none;
            }
            &.selected .ba-color-box {
                position: relative;
                &::before {
                    content: "✓";
                    font: normal 32px/38px $f-stack-icon;
                    color: $white;
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -16px;
                    margin-top: -16px;
                }
            }
        }
        .ba-img-span,
        .ba-color-box {
            display: inline-block;
            width: 100%;
            height: 100%;
            max-width: 100%;
            min-height: 100px;
            background-position: 50% 50%;
            background-size: 100% auto;
        }
        .ba-color-box {
            @include border-top-radius(5px);
        }
        .ba-opt-content {
            width: 100%;
        }
    }
    &.open .ba-options {
        visibility: visible;
        opacity: 1;
        li {
            transform: scale3d(1, 1, 1);
            opacity: 1;
        }
    }
}

.ba-color-name {
    width: 100%;
    font-size: 16px;
}

.validation-error {
    .ba-shiv,
    .ba-options {
        @include gradient-vertical(lighten($brand-danger, 5%), $brand-danger);
        border-color: $brand-danger;
        &:hover {
            background: $brand-danger;
        }
    }
    .ba-shiv .ba-arrow {
        background-position: -34px 0;
    }
    .ba-left,
    .ba-right,
    .ba-under {
        .ba-shiv {
            text-shadow: none;
        }
    }
    .ba-left .ba-options::after {
        background-position: -103px -2px;
    }
    .ba-right .ba-options::after {
        background-position: -100px -35px;
    }
    .ba-under .ba-options::after {
        background-position: -98px -68px;
    }
    .ba-options li,
    .ba-shiv-content,
    .ba-shiv-content:hover {
        color: $white;
    }
    .ba-options li:hover {
        color: $black-80 !important;
    }
    .ba-options::before {
        @include gradient-vertical(lighten($brand-danger, 5%), $brand-danger);
        text-shadow: 0 2px 0 lighten($brand-danger, 5%);
        border-color: $brand-danger;
        &:hover {
            background: $brand-danger;
        }
    }
    .ba-over .ba-options,
    .ba-thumbnail .ba-options {
        background: none;
        border: 0;
        box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    }
    .ba-fullscreen .ba-options,
    .ba-fullthumb .ba-options {
        background: rgba(255, 255, 255, .9);
        border: 0;
        text-shadow: none;
    }
    .ba-fullthumb .ba-options {
        background: rgba(0, 0, 0, .75);
    }

    @include ie8 {
        .ba-shiv {
            border-color: darken($brand-danger, 20%);
        }
        .ba-options {
            @include gradient-vertical(lighten($brand-danger, 5%), $brand-danger);
            border-color: darken($brand-danger, 20%);
            li.hover,
            li.hover span {
                color: $black-80;
            }
        }
    }
}

html.touch.resp-mobile,
html.touch.resp-tablet {
    .ba-select-container {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .ba-select-input {
        @extend .select-custom;
    }
}

.ba-select-container.sm {
    .ba-shiv {
        height: 32px;
        padding: 3px 40px 0 10px;
        font: 300 1em/24px $sans-serif;
    }
    .ba-shiv-content {
        font-family: $f-primary;
        font-size: 13px;
        line-height: 2;
        letter-spacing: 0.9px;
    }
    .ba-arrow::after {
        font-size: 12px;
        width: 24px;
        height: 24px;
        line-height: 27px;
    }
    .ba-options {
        max-height: 32px;
        padding: 3px 40px 4px 10px;

        ul li {
            margin: 0;
            padding: 0;

            &.selected,
            &:hover {
                padding-bottom: 2px;
                line-height: 26px !important;
            }
        }

        .ba-arrow {
            right: 10px;
            top: 12px;
        }

        &::after {
            height: 20px;
            width: 32px;
            bottom: 6px;
        }
    }
    .ba-over.open .ba-options {
        max-height: 9999px;

        ul {
            padding-top: 2px;
        }

        .ba-arrow {
            top: 12px;
        }
    }
}

// SiteGenesis Overrides
.product-options .attribute.option-dropdown,
.primary-content .search-result-options {
    overflow: visible;
}
