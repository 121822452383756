.brand-primary-dark {
    color: $brand-primary-dark;
}

.brand-primary-light {
    color: $brand-primary-light;
}

.brand-orange {
    color: $brand-orange;
}

.brand-charcoal {
    color: $brand-charcoal;
}

.brand-grey {
    color: $brand-grey;
}

.brand-california-gold {
    color: $clr_gold_membership_dark;
}

.brand-light-grey {
    color: $brand-light-grey;
}

.capitalize {
    text-transform: uppercase;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.clear {
    @include clearfix;
}

.hide-always {
    display: none;
}

.hide-mobile {
    @include bp(max-width, $bp-large) {
        display: none;
    }
}

.hide-desktop {
    @include bp(min-width, $bp-large) {
        display: none;
    }
}

.d-none {
    display: none !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-auto {
    cursor: auto !important;
}

#wrapper .desktop {
    display: none;

    @include bp(min-width, $bp-medium) {
        display: block;
    }
}

#wrapper .mobile {
    display: block;

    @include bp(min-width, $bp-medium) {
        display: none;
    }
}
