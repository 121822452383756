.checkoutlogin {
    @include clearfix;
    padding: 0 48px;
    .move-right {
        float: right;
    }
    .col-1,
    .col-2 {
        width: 49%;
        background-color: #efefef;
        height: 460px;
        @include bp(max-width, $bp-medium) {
            width: 100%;
            height: auto;
        }
    }
    .col-2 {
        @include bp(max-width, $bp-medium) {
            margin-top: 20px;
        }
    }
    @include bp(max-width, $bp-large) {
        padding: 0 16px;
    }

    .login-heading {
        margin-top: 40px;
        margin-bottom: 33px;
        font: normal normal 43px/44px $f-primary;
    }
    p.login-help {
        text-align: left;
        margin-top: -26px;
    }
    .login-box-heading {
        // font: normal 400 18px/18px $f-neue-bold;
        letter-spacing: .5px;
        margin-bottom: 18px;
        @include bp(max-width, $bp-large) {
            font-size: 16px;
        }
    }
    .login-box-content {
        // border-top: 1px solid #ccc;
        .create-account-now {
            .form-row {
                &.form-row-button {
                    button {
                        @include bp(max-width, $bp-medium) {
                            width: 100%;
                        }
                    }
                }

            }
        }
        &.returning-customers {
            button#dwfrm_login_login {
                @include bp(max-width, $bp-medium) {
                    width: 100%;
                }
            }
            .form-row {
                &.login-rememberme {
                    label.checkbox-label {
                        >span {
                            font: normal 400 12px/16px $f-neue;
                            @include bp(max-width, $bp-medium) {
                                font: normal 400 12px/14px $f-neue;
                            }
                            color: #54584e;
                        }
                    }
                }
            }
        }
        .form-guest-checkout {
            margin-top: 23px;
        }
        p {
            font-family: $f-neue;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: normal;
            color: #54584e;
        }
        .form-row {
            &.formbuttonrow {
                button[name*="unregistered"] {
                    width: 100%;

                    @include bp(min-width, $bp-medium) {
                        width: auto;
                    }
                }
            }
        }
        #dwfrm_login {
            .form-row > label {
                &.checkbox-label {
                    transform: none;
                }
            }
            .form-row {
                margin-bottom: 0;
                padding: 0;
                label {
                    z-index: 1;
                    &.checkbox-label {
                        margin-top: -9px;
                        @include bp(max-width, $bp-medium) {
                            margin-top: -4px;
                        }
                    }
                }
                &.password {
                    margin-top: 10px;
                }
            }
            .form-row-wrapper {
                margin-bottom: 31px;
                a#password-reset {
                    margin-top: 4px;
                    @include bp(max-width, $bp-medium) {
                        margin-top: 6px;
                    }
                }
            }
        }
    }
    .login-box {
        background-color: #efefef;
        padding: 24px 40px;
        @include bp(max-width, $bp-medium) {
            padding: 24px 15px;
        }
        p {
            margin: 1em 0;
        }
        .error {
            margin-left: 0;
        }

        .form-row {
            margin-top: 0;

            .ba-select-container {
                text-align: left;
            }
        }

        .dialog-required {
            display: none;
        }
    }
}
.returning-customers {
    p {
        margin-top: 0;
    }
    button {
        float: left;
        width: 100%;
        max-width: 245px;
        @include bp(max-width, $bp-medium) {
            max-width: none;
        }
    }
}

.login-rememberme {
    padding-top: 8px;
    float: left;
    z-index: 0;
    margin: -27px 0 0;
}

a#password-reset {
    font: normal normal 12px/10px $f-neue;
    color: #54584e;
    float: right;
    margin-top: -3px;
    overflow: visible;
    @include bp(max-width, $bp-medium) {
        margin-top: 0;
    }
}

.login-oauth {
    text-align: center;

    p:first-child {
        font-size: 2em;
        text-align: center;
        margin: 0;
        text-transform: uppercase;
    }
}

#OAuthProvider {
    display: block;
    margin-bottom: 1em;
    width: 80%;
}

#account-create-modal {
    display: none;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    left: 0;
    z-index: 50;
}

#account-create-modal-inner {
    margin: 0 auto;
    position: relative;
    width: 720px;
    overflow: scroll;
    @include bp(max-width, $bp-medium) {
        width: 100%;
    }
    background-color: #fff;
    padding: 20px 45px;
    #account-create-modal-close {
        display: block;
    }
}

/* New Modal for Guest Intercept */
.guest-intercept-modal,
.registered-intercept-modal,
.guest-order-confirm-modal {
    top: 108px !important;

    @include bp(max-width, $bp-medium) {
        top: 0;
        max-width: 320px;
    }
    span.ui-dialog-title {
        margin: 17px 20px;
        font-family: $f-primary;
        font-size: 28px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: 1px;
        @include bp(max-width, $bp-medium) {
            overflow-wrap: break-word;
            margin: 24px 0;
            white-space: normal;
            width: 72.5%;
            height: 56px;
            color: #2e2d2a;
            font-size: 22px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.27;
            letter-spacing: 1.02px;
        }
    }
    h4 {
        display: inline-block;
        font-family: $f-neue-bold;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: 0.5px;
        clear: both;
        margin-right: 10px;
    }
    p {
        font-family: $f-neue;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: normal;
    }
    &.ui-dialog {
        .ui-dialog-titlebar-close {
            right: 24px;
            top: 28px;
            @include bp(max-width, $bp-medium) {
                right: 15px;
                top: 24px;
            }
        }
    }
    #dialog-container {
        padding: .5em 38px;
        overflow: hidden;
        @include bp(max-width, $bp-medium) {
            padding: .5em 16px;
        }
    }
    .error-header {
        display: none;
        .error {
            background: none;
        }
    }
    #RegistrationFormModal {
        .modal-membership-address {
            margin-bottom: 12px;
            margin-top: 14px;
        }
        fieldset {
            @include bp(max-width, $bp-medium) {
                max-width: 290px;
            }
        }
        .form-row {
            &.modal-reason-for-joining,
            &.shipping-address {
                .ba-select-container {
                    .ba-over.open {
                        .ba-shiv {
                            border-bottom: none;
                        }
                        .ba-shiv-content {
                            display: none;
                        }
                    }
                    &.error {
                        .ba-over {
                            .ba-shiv {
                                border-color: $c-error;
                                background-color: transparent;
                            }
                            &.open {
                                .ba-shiv {
                                    border-bottom: none;
                                }
                            }
                        }
                        .ba-options {
                            background-color: rgba(207, 80, 80, 0.15);
                        }
                    }
                }
                &> label {
                    display: none;
                }
                .field-wrapper {
                    .error {
                        background-color: transparent;
                    }
                }
            }
            .ba-options {
                background-color: rgba(207, 80, 80, 0.15);
                &::after {
                    border-bottom: none;
                    bottom: 9px;
                }
            }
            .form-caption {
                display: none;
            }
            &.form-row-button {
                width: 100%;
                @include bp(max-width, $bp-medium) {
                    max-width: 290px;
                }
            }
            & + label {
                transition: all 0.3s;
                transform: translate3d(0, -14px, 0);
            }
            &.dropdown {
                margin: 0 0 4px;
            }
            input[type="text"],
            input[type="email"],
            input[type="number"],
            input[type="password"],
            input[type="date"],
            input[type="tel"],
            textarea {
                background-color: transparent;
                &.error {
                    border: 1px solid $c-error;
                    background-color: rgba(207, 80, 80, 0.15) !important;
                }
            }

            .field-wrapper {
                .input-radio-new {
                    width: 20px;
                    height: 20px;
                }
                input.error {
                    color: $c-error;
                    background-color: rgba(207, 80, 80, 0.15);
                }
                input.error ~ label {
                    &.error {
                        font-size: 12px;
                        position: relative;
                        top: 4px;
                        left: 0;
                        background-color: transparent;
                        color: $c-error;
                    }
                }
                span.modal-radio-label {
                    vertical-align: super;
                }
            }
            &.modal-state {
                .field-wrapper {
                    select + label {
                        position: relative;
                        top: 0;
                        left: 0;
                        &.error {
                            top: 4px;
                            font-size: 12px;
                            color: $c-error;
                        }
                    }
                }
            }
            .agree-to-text {
                display: none;
                .field-wrapper {
                    width: 94%;
                    margin-left: 20px;
                    @include bp(max-width, $bp-medium) {
                        width: 88%;
                    }
                }
                .hundred {
                    margin-top: 0;
                }
            }
            #dwfrm_profile_customer_reasonForCreatingAccount-error {
                top: 55px;
                left: 0;
                color: $c-error;
                font-size: 12px;
            }
            .ba-select-container .ba-over {
                .ba-options {
                    background-color: transparent;
                }
                &.open .ba-options {
                    background-color: $white;
                }
            }
            .ba-select-container.error .ba-over {
                &.open .ba-shiv-content {
                    display: none;
                }
                .ba-options {
                    border-color: $c-error;
                    background-color: rgba(207, 80, 80, 0.15);
                    border-top: 0;
                    ul {
                        background-color: #f8e5e5;
                    }
                }
            }
        }

        .hundred {
            width: 100%;
            float: left;
            margin-top: -18px;
            margin-bottom: 4px;
            & > .form-row {
                width: 100%;
                margin-bottom: 8px;

                .input-checkbox + .error {
                    margin-left: 28px;
                }

                &.holder-for-nth {
                    margin-bottom: 0;
                }

                &.form-indent {
                    margin-top: 3px;
                    margin-bottom: 4px;
                }
            }
        }
        .agreement-checkbox {
            display: flex;
            flex-direction: column;
            .field-wrapper {
                order: 2;
            }
        }
        .communication-preferences {
            width: 48.67%;
            display: inline-block;
            @include bp(max-width, $bp-medium) {
                width: 100%;
                display: block;
            }
            .form-row {
                width: 100%;
                .field-wrapper {
                    margin-bottom: 0;
                    #comm-pref-phone {
                        margin-left: 22px;
                    }
                    label {
                        position: relative;
                        left: 0;
                        top: 0;
                    }
                    &.clear {
                        clear: both;
                    }
                }
                .agree-to-text {
                    label {
                        margin-left: 22px;
                    }
                    .phone + .error {
                        margin-left: 0;
                    }
                }
            }
        }
        .data-captcha-box {
            clear: both;
            display: inline-block;
            float: right;
            margin: 0 0 16px;
            width: 48.67%;

            @include bp(max-width, $bp-medium) {
                display: block;
                width: 100%;
            }

            .data-captcha {
                float: right;

                @include bp(max-width, $bp-medium) {
                    transform: scale(0.96);
                    transform-origin: left;
                }
            }

            .captcha {
                &.required {
                    display: none;
                }
            }
        }
        .button {
            float: left;
            display: inline-block;
            width: auto;
            height: 45px;
            min-width: 245px;
            @include bp(max-width, $bp-medium) {
                width: 100%;
            }
            span {
                height: 30px;
                font-family: $f-neue;
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 22.5px;
                letter-spacing: 0.5px;
                text-align: center;
            }
            &.outline-button {
                min-width: 200px;
                margin-left: 15px;
                @include bp(max-width, $bp-medium) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }
}
.registered-intercept-modal {
    p,
    h4 {
        &.error {
            color: $c-error;
            background-color: transparent;
        }
    }
    #RegistrationFormModal {
        .hundred {
            margin-top: 0;
        }
    }
}
/* increasing z-index of google address suggestion box, because in modal already has z-index:9999, so we have to give more than that */
.pac-container {
    z-index: 9999;
}
