.top-banner .story-container h5 {
    text-transform: uppercase;
    margin: 21px 0 12px;
    font: normal normal bold 14px/1 $f-neue;
    line-height: normal;
    letter-spacing: 0.5px;
    color: black;
}
.top-banner .story-container p {
    font: normal normal 300 12px/1.67 $f-neue;
    letter-spacing: 0.3px;
    color: $brand-primary-dark;
}

.OpenATradeAccountMobile {
    display: block;
    @include bp(min-width, $bp-medium) {
        display: none;
    }
}

nav[role="navigation"] {
    color: $white;
    position: absolute;
    top: 0;
    z-index: 99992;
    width: 100%;
    left: 0;
    display: none;
    transition: all $navigation-transition-duration $navigation-transition-function;
    transform: translateX(-105%);
    background-color: transparent;

    .content {
        z-index: 2;
        padding-top: 0;
        position: relative;
        background: $white;
        box-sizing: border-box;
        transition: transform $navigation-transition-duration $navigation-transition-function;
        overflow-y: auto;
        padding-bottom: 50px;
        height: 100%;

        @supports (-webkit-touch-callout: none) {
            padding-bottom: 90px;
        }

        @include bp(min-width, $bp-medium) {
            border: 1px solid $very-middle-gray-v5;
        }

        &.overflow {
            overflow-y: visible;
        }

        .header-search {
            width: auto;
            margin: 0 30px;

            form {
                margin-right: 39px;

                button {
                    top: 2px;
                    right: -17px;
                }
            }

            i.fa.fa-search {

                &::before {
                    @extend %icon-font;
                    font-size: 20px;
                    position: absolute;
                    content: $icon-search;
                    top: 0;
                    left: 0;
                    line-height: 22px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }

    .level-2,
    .level-3 {
        display: none;

        @include bp(min-width, $bp-medium) {
            border: 1px solid $very-middle-gray-v5;
        }
    }

    a {
        &.has-sub-menu,
        &.has-sub-categories {
            position: relative;

            &::after {
                @extend %icon-font;
                font-size: 12px;
                line-height: normal;
                content: $icon-pagination-next;
                color: inherit;
                display: block;
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &.has-sub-menu {
            &.open {
                + .level-2 {
                    display: block !important; // override inline css
                }
            }
        }

        &.has-sub-categories {
            &.open {
                + .level-3 {
                    display: block;
                }
            }
        }
    }

    .menu-active &,
    .IE.nine .menu-active & {
        display: block;
        transform: translateX(0);
        overflow-x: hidden;
    }

    // This is seperate since it does something
    // completely different in mobile
    &.sub-active {
        @include bp(max-width, $bp-medium) {
            overflow-y: hidden;
        }
    }

    @include bp(min-width, $bp-medium) {
        max-width: 375px;

        .content {
            max-width: 375px;
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;

        &::before {
            @extend %icon-font;
            font-size: 14px;
            content: $icon-close-x;
            color: $white;
            background: $brand-primary-dark;
            padding: 11px;
            line-height: 36px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .menu-utility-user,
    .menu-category,
    .additional-menu {
        li {
            border-bottom: 1px solid $very-middle-gray-v7;

            &.border-bottom {
                border-bottom: 1px solid $very-middle-gray-v7 !important;
            }

            &:last-of-type {
                border: 0 none;
            }
        }

        .nav-tile {
            padding: 15px 30px;

            &.has-sub-menu,
            &.has-sub-categories {
                padding-right: 50px;
            }

            &.back {
                padding-left: 54px;
                width: 100%;
            }
        }

        .back,
        .back-without-text {
            position: relative;
            margin: 0;

            @include bp(min-width, $bp-custom-large) {
                display: none;
            }

            &::before {
                @include icon-font;
                font-size: $header-arrows-font-size;
                content: $icon-pagination-prev;
                display: block;
                position: absolute;
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .back-without-text {
            padding: 0 0 0 54px;
            height: 50px;

            + a {
                flex: 1;
                padding: 15px 30px 15px 0;
            }
        }
    }

    .additional-menu {
        .level-2 {
            text-transform: none;
        }
    }
}

.menu-toggle {
    background-color: transparent;
    border: none;
    display: none;

    &:hover {
        background-color: transparent;
    }

    .menu-icon {
        width: 20px;
        height: 16px;

        &::before {
            font-size: 20px;
            content: $icon-menu;
        }

    }

    &::after {
        display: none;
    }
}

.navigation-main-category {
    font: normal normal 14px/16px $f-primary;
}

.menu-utility-user,
.menu-category,
.menu-category ul,
.additional-menu {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-indent: 0;

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
    }

    a {
        display: block;
        font: inherit;
        text-decoration: none;
    }
}

.menu-utility-user,
.additional-menu {
    font-weight: 500;
}

.level-2-top,
.level-3-top {
    font: normal normal 15px/20px $f-avenir;
    display: flex;
    align-items: center;
}

.additional-menu {
    > li {
        background: $very-middle-gray-v4;
    }
}

.level-1 > li {
    @extend %clearfix;

    > a {
        @include bp(min-width, $bp-custom-large) {
            height: 29px;
        }

        &.currentOpen {
            @include bp(min-width, $bp-custom-large) {
                border-bottom: 2px solid $brand-v2-off-black;
            }
        }
    }
}

.level-2 {
    clear: left;
    background: $white;
    position: absolute;
    top: 0;
    height: 100vh;
    z-index: 10;
    width: 100%;
    transition: all $navigation-transition-duration $navigation-transition-function;
    display: none;

    @include bp(min-width, $bp-custom-large) {
        height: auto !important;
    }

    @include bp(max-width, $bp-medium) {
        overflow-y: scroll;
    }
}

.level-3 {
    clear: left;
    background: $white;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 10;
    width: 100%;
    transition: all $navigation-transition-duration $navigation-transition-function;
    display: none;

    @include bp(min-width, $bp-custom-large) {
        height: auto !important;
    }
}

.banner-mobile {
    padding: 15px 0;
    color: $brand-v2-off-black;
    letter-spacing: 0.01em;
    background: $white;

    .banner-featured {
        font: normal normal 15px/18px $f-classical-normal;
        text-transform: uppercase;
        margin-top: 0;
    }

    .story-container {
        margin: 0;

        img {
            width: 100%;
            margin-bottom: 15px;
            max-width: none !important;
        }

        h5 {
            font: normal normal 20px/24px $f-classical-normal;
            margin: 0 0 10px;
            text-transform: none;
        }

        p {
            font: normal normal 15px/20px $f-avenir;
            margin: 0;
        }
    }

    .banner-featured,
    .story-container h5,
    .story-container p {
        padding: 0 30px;
    }
}

.mobile-bottom-space {
    padding-bottom: 69px; // to compensate for overlaid footer utility bar on mobile devices

    @include bp(min-width, $bp-medium) {
        padding-bottom: 0;
    }
}

html.IE.nine {
    nav[role="navigation"] {
        a {
            &.has-sub-menu {
                + .level-2 {
                    display: none;
                }

                &.open {
                    + .level-2 {
                        display: block;
                    }
                }
            }
        }
    }
}

.b2c-navigation-menu {
    display: none;
    border-bottom: 1px solid $very-middle-gray-v7;
    z-index: 0;

    @include bp(min-width, $bp-custom-large) {
        display: block;
    }

    .menu-category {
        padding: 15px 15px 0;
        display: flex;
        justify-content: space-between;
        max-width: 1170px;
        margin: 0 auto;
        border: 0 none;

        @include bp(max-width, $bp-medium) {
            display: none;
        }

        .level-2-container {
            margin: 0 auto;
            max-width: 1170px;
            padding: 0 15px;
            position: relative;
            width: 100%;

            .level-2-container-inner {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }

        .megamenu-flyout {
            .level-b {
                display: none;
                left: 0;
                width: 1500px;
                top: 151px;
                position: absolute;
                background-color: #fff;
                .grid {
                    display: grid;
                    column-width: 200px;
                }
            }
            &:hover + div.level-2 {
                display: block;
            }
        }

        .level-2 {
            background: rgba(255, 255, 255, 1);
            -webkit-backdrop-filter: saturate(100%) blur(9px);
            backdrop-filter: saturate(100%) blur(9px);
            clear: left;
            display: none;
            height: auto;
            left: 0;
            max-height: calc(90vh - 145px);
            overflow: hidden;
            padding: 40px 0 20px;
            position: absolute;
            top: 122px;
            transition: all $navigation-transition-duration $navigation-transition-function;
            width: 100% !important;
            z-index: 999;

            @media only screen and (min-width: $bp-custom-large) and (max-height: $bp-medium) {
                height: 100vh !important;
                max-height: calc(100vh - 122px);
                overflow-y: auto;
            }

            .menu-vertical {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                height: 100%;
                margin: 0;
                padding: 0;

                > li {
                    > a {
                        color: $black;
                        font: normal normal bold 12px/14px $f-primary !important;
                        display: initial;
                        letter-spacing: 0.15em;
                        margin: 0;
                        padding: 0;
                        text-transform: uppercase;

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }

                    &.mega-cat-sub-cat-top-level {
                        margin-bottom: 18px;
                        margin-top: 15px;

                        &:first-child {
                            margin-top: 0;
                        }

                        + .mega-cat-sub-cat-top-level {
                            margin-top: 0;
                        }
                    }

                    &.mega-cat-sub-cat-inner-level {
                        margin-bottom: 15px;

                        a {
                            padding: 0;
                            margin: 0;
                            font: normal normal normal 14px $f-primary !important;
                            letter-spacing: 0.05em;
                            text-transform: none;
                            margin-bottom: 23px;

                            &:hover {
                                text-decoration: underline !important;
                            }
                        }
                    }
                }
            }

            .banner {
                max-width: 228px;

                .story-container {
                    margin: 0;
                    margin-bottom: 24px;
                    display: flex;

                    img {
                        width: 100%;
                    }

                    h5 {
                        margin: 6px 0;
                        text-align: left;
                        text-transform: none;
                        font: normal normal normal 20px/24px $f-classical-normal;
                    }

                    p {
                        font: normal normal normal 12px/18px $f-primary;
                        margin-bottom: 0;
                    }
                }

                a {
                    padding: 0;
                }

                .banner-featured {
                    font: normal normal bold 12px/14px $f-primary;
                    text-transform: uppercase;
                    color: $black;
                    margin: 0;
                    padding: 0 0 22px;
                    letter-spacing: 0.15em;
                }
            }
        }

        &::after {
            display: none;
        }
    }

    a.has-sub-menu.open + .level-2 {
        display: none;
        box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2);
        background-color: $white;
        &.open {
            left: 0;
            max-width: 100%;
            display: flex;
            flex: 1 1 auto;
            z-index: 9999;
        }
    }
}

body,
body.has-image {
    #wrapper {
        .top-banner {
            @include bp(max-width, $bp-large) {
                flex-direction: row;
            }
            .menu-toggle  {
                @include bp(min-width, $bp-custom-large) {
                    display: none;
                }
                @include bp(max-width, $bp-custom-large) {
                    color: #000;
                    .menu-icon {
                        span.label {
                            display: none;
                        }
                    }

                }
            }
            .header__wrapper {
                @include bp(max-width, $bp-custom-large) {
                    width: 100%;
                    flex: 1;
                    order: 2;
                }
            }
        }
    }
}

.top-banner {
    .close {
        display: none;
        width: 19px;
        height: 19px;
        opacity: 1;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            background-image: url("../images/close.svg");
            background-size: cover;
            background-repeat: no-repeat;
            display: block;
        }
    }
}

.menu-active {
    .top-banner {
        .menu-toggle {
            display: none;
        }

        .close {
            display: block;
        }
    }
}

.story-container-width-50 {
    width: calc(50% - 7px);

    &:first-of-type {
        margin-right: 7px;
    }

    &:last-of-type {
        margin-left: 7px;
    }
}

.story-container-width-100 {
    width: 100%;
}
