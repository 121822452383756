.splash-page {
    color: $black;

    &__hero-image {
        margin-bottom: 24px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 40px;
        }

        img {
            object-fit: cover;
            width: 100%;
        }
    }

    img {
        display: block;
    }
}

.splash-contacts {
    padding: 0 20px;
    text-align: center;

    &__title {
        color: inherit;
        font: normal normal 36px/40px $f-classical-normal;
        letter-spacing: 0.025em;
        text-transform: none;

        @include bp(min-width, $bp-medium) {
            font-size: 42px;
            letter-spacing: 0.035em;
            line-height: 48px;
        }
    }

    &__copy {
        font: normal normal 16px/22px $f-avenir;
        letter-spacing: 0.015em;
        margin: 10px auto 24px;

        @include bp(min-width, $bp-medium) {
            font-size: 18px;
            letter-spacing: 0.0125em;
            line-height: 28px;
        }
    }

    &__cards {
        margin: 0 auto 40px;
        max-width: 1060px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 60px;
        }
    }
}

.splash-contact-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -10px;
}

.splash-contact-card {
    flex: 1;
    max-width: 270px;
    min-width: 177px;
    padding: 16px 10px;

    &__icon {
        img {
            margin: 0 auto;
        }
    }

    &__copy {
        font: normal normal 16px/22px $f-avenir;
        letter-spacing: 0.015em;
        margin: 15px auto 12px;

        @include bp(min-width, $bp-medium) {
            font-size: 18px;
            letter-spacing: 0.0125em;
            line-height: 28px;
            margin-top: 20px;
            max-width: 196px;
        }
    }

    &__cta {
        font: 500 normal 16px/16px $f-avenir;
        letter-spacing: 0.025em;

        a {
            color: inherit;
            font: inherit;
            letter-spacing: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.splash-trade {
    &__inner {
        display: flex;
        flex-direction: column-reverse;
        margin: 0 auto;
        max-width: 1600px;
        text-align: center;

        @include bp(min-width, $bp-medium) {
            flex-direction: row;
        }
    }

    &__text {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
    }

    &__text-inner {
        margin: 24px auto;
        padding: 0 20px;

        @include bp(min-width, $bp-medium) {
            margin: 0 auto;
            padding: 0;
            width: 75%;
        }
    }

    &__title {
        color: inherit;
        font: normal normal 30px/36px $f-classical-normal;
        letter-spacing: 0.025em;
        text-transform: none;

        @include bp(min-width, $bp-medium) {
            font-size: 32px;
            letter-spacing: 0.035em;
            line-height: 38px;
        }
    }

    &__copy {
        font: normal normal 16px/22px $f-avenir;
        letter-spacing: 0.015em;
        margin: 10px auto 24px;

        @include bp(min-width, $bp-medium) {
            font-size: 18px;
            letter-spacing: 0.0125em;
            line-height: 28px;
        }
    }

    &__cta {
        font: 500 normal 16px/16px $f-avenir;
        letter-spacing: 0.025em;

        a {
            border-color: $black;
            color: inherit;
            font: inherit;

            &:hover {
                background: $black;
                color: $white;
            }
        }
    }

    &__image {
        flex: 1;

        @include bp(min-width, $bp-medium) {
            flex: 2;
        }

        img {
            width: 100%;
        }
    }
}
