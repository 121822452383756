.tooltip {
    position: relative;
    cursor: pointer;

    &--light {
        .tooltipster-box {
            background-color: $carousel-background;
            border: 1px solid $brand-v2-lt-gray;
        }

        .tooltipster-content {
            font: normal normal 12px/18px $f-primary;
            color: $black;
            padding: 15px 20px;
        }
    }
}

.ui-tooltip.ui-widget-content {
    background: $night-rider;
    border-radius: 3px;
    box-shadow: 2px 3px 3px 0 $nobel;
    color: $white;
}

.ui-tooltip-content {
    padding: 0.3em;
    .recommendation-tooltip-header {
        font-size: 1.2em;
        font-weight: bold;
        padding: 0.5em;
        .product-price {
            font-weight: 400;
            .price-standard {
                color: $nobel;
                text-decoration: line-through;
            }
        }
    }
    .recommendation-tooltip-description,
    .recommendation-tooltip-attributes {
        padding: 0.8em;
    }
    .shipping-method-cost,
    .surcharge-product,
    .promo {
        text-align: right;
        padding-bottom: .3rem;
    }
    .shippingtotal {
        clear: right;
        text-align: right;
        margin-top: .3rem;
        .value {
            border-top: 1px solid $charcoal;
            padding-top: .3rem;
        }
    }
}


$tooltip-bg: rgba(46, 45, 42, 0.85);

.tooltip-content {
    display: none;
}

.tooltipster-sidetip.tooltipster-borderless,
.tooltipster-sidetip.tooltipster-cc {
    .tooltipster-content {
        color: $white;
        padding: 0;

        p {
            color: inherit;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            color: inherit;
        }

        .label {
            font-weight: bold;
        }
    }
}

.tooltipster-sidetip.tooltipster-borderless .tooltipster-box {
    border: none;
    background: $tooltip-bg;
    padding: 20px;
    border-radius: 0;
}
.tooltipster-sidetip.tooltipster-cc .tooltipster-box {
    border: none;
    background: rgba(46, 45, 42, 0.85);
    padding: 20px;
    border-radius: 0;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-box,
.tooltipster-sidetip.tooltipster-cc.tooltipster-bottom .tooltipster-box {
    margin-top: 8px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-box,
.tooltipster-sidetip.tooltipster-cc.tooltipster-left .tooltipster-box {
    margin-right: 8px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-box,
.tooltipster-sidetip.tooltipster-cc.tooltipster-right .tooltipster-box {
    margin-left: 8px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-top .tooltipster-box,
.tooltipster-sidetip.tooltipster-cc.tooltipster-top .tooltipster-box {
    margin-bottom: 8px;
}
.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow,
.tooltipster-sidetip.tooltipster-cc .tooltipster-arrow {
    height: 8px;
    margin-left: -8px;
    width: 16px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-arrow,
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow,
.tooltipster-sidetip.tooltipster-cc.tooltipster-left .tooltipster-arrow,
.tooltipster-sidetip.tooltipster-cc.tooltipster-right .tooltipster-arrow {
    height: 16px;
    margin-left: 0;
    margin-top: -8px;
    width: 8px;
}
.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow-background,
.tooltipster-sidetip.tooltipster-cc .tooltipster-arrow-background {
    display: none;
}
.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-cc .tooltipster-arrow-border {
    border: 8px solid transparent;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: $tooltip-bg;
}
.tooltipster-sidetip.tooltipster-cc.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: rgba(46, 45, 42, 0.85);
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-cc.tooltipster-left .tooltipster-arrow-border {
    border-left-color: $tooltip-bg;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-cc.tooltipster-right .tooltipster-arrow-border {
    border-right-color: $tooltip-bg;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-top .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-cc.tooltipster-top .tooltipster-arrow-border {
    border-top-color: $tooltip-bg;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-arrow-uncropped,
.tooltipster-sidetip.tooltipster-cc.tooltipster-bottom .tooltipster-arrow-uncropped {
    top: -8px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow-uncropped,
.tooltipster-sidetip.tooltipster-cc.tooltipster-right .tooltipster-arrow-uncropped {
    left: -8px;
}

.tooltipster-base.tooltipster-cvn {
    text-align: center;
    height: auto !important;

    .tooltipster-box {
        padding: 0.875rem 2.375rem;
    }
}
