.pt_gift-registry {
    .create-new-registry {
        margin: 1em 0;
        button {
            float: right;
        }
    }
    .account-header {
        margin-bottom: 43px;

        @include bp(max-width, $bp-large) {
            margin-bottom: 28px;
        }
    }
    .hdr-options {
        width: 9%;
    }
    .registry-results-table {
        @include bp(max-width, $bp-medium) {
            td {
                padding: 4px;
            }
        }

        @include bp(max-width, $bp-custom-small) {
            td {
                display: block;
                float: left;
                width: 50%;
            }
        }
    }

    .login-search-gift {
        @include bp(max-width, $bp-large) {
            .form-row {
                width: 100%;
            }
        }
    }
}

.page-content-tab-navigaton button {
    @include plain-button;
    color: $brand-primary-light;

    &.selected {
        color: $black;
    }

    &:first-child {
        @include bp(min-width, $bp-medium) {
            padding-left: 0;
        }
    }
}
.page-content-tab-wrapper {
    padding: 2em 0;
}

.gift-reg-purchases {
    .item-purchased-by {
        dd {
            float: left;
            font-weight: bold;
            margin: 0 1em 0 0;
            position: relative;
            width: auto;
        }
        dt {
            clear: both;
            float: left;
            margin: 0 1em 0 0;
            position: relative;
            width: auto;
        }
    }
    .quantity-purchased {
        color: $sangria;
    }
}

.gift-registry-advanced {
    @extend %clearfix;
    clear: both;

    .toggle-content {
        display: none;
        margin-top: 30px;
    }
}

.login-search-gift {
    margin-top: 30px;
}

.gift-registry-form {
    .participant-header {
        clear: both;
    }
}
.registry-footer {
    @include clearfix;

    .cancel,
    .previous {
        float: left;
    }
    .continue {
        float: right;
    }
}
.add-gift-cert {
    text-align: center;
    padding: 2em 0;
}
.item-list {
    margin-top: 37px;

    .item-row {
        border-bottom: 1px solid $very-light-gray;
        padding-bottom: 14px;
        margin-bottom: 14px;

        &:last-of-type {
            border-bottom: 0;
        }
    }
    .row-top,
    .row-bottom {
        .row-item {
            float: left;
            padding: 7px;

            .price-standard {
                position: relative;
                display: inline-block;

                &::after {
                    @include position(absolute, 10px 5px null 5px);
                    content: "";
                    display: block;
                    border-top: 1px solid $brand-primary-dark;
                }
            }

            .price-sales {
                color: $c-error;
            }

            .name,
            .name a {
                text-transform: uppercase;
            }
        }

        @include bp(min-width, $bp-custom-small) {
            display: table-row;
            width: 100%;

            .row-item {
                display: table-cell;
                float: none;
                padding: 14px;
                vertical-align: top;
            }
        }
    }
    .item-image,
    .item-availability {
        width: 25%;

        img {
            max-width: 100%;
        }
    }
    .item-image {
        @include bp(max-width, $bp-custom-small) {
            width: 80px;
        }
    }
    .item-details {
        width: 50%;

        @include bp(max-width, $bp-custom-small) {
            display: block;
            width: calc(100% - 80px);
        }
    }
    .row-item.item-availability {
        @include bp(max-width, $bp-custom-small) {
            display: block;
            clear: left;
            width: 100%;
            text-align: left;
        }
    }
    .field-wrapper {
        clear: left;
    }
    .product-availability-list {
        margin: 0;
        padding: 0;
        list-style: none;

        @include bp(min-width, $bp-custom-small) {
            text-align: right;
        }
    }
    .option-update {
        clear: left;
        float: left;
    }
}
.registry-table {
    tr {
        border-bottom: 1px solid $very-light-gray;

        &:last-of-type {
            border-bottom: 0;
        }
    }
    td {
        padding: 1em 0;
        vertical-align: middle;
    }

    @include bp(max-width, $bp-small) {
        padding: 28px 0;

        td {
            display: block;
            float: left;
            padding: 0;

            &.event-type {
                clear: left;
                margin-right: 28px;
            }
        }
    }
}

.option-controls {
    width: 47.5%;
    float: left;

    @include bp(max-width, $bp-custom-small) {
        width: 100%;
        float: none;
    }
}

.option-add-to-cart {
    @include clearfix;
    float: right;
    width: 68%;
    // magic number alert: if the width of the add to cart button changes,
    // this number will need to change
    max-width: 293px;

    @include bp(max-width, $bp-custom-small) {
        clear: both;
        width: 100%;
        float: none;
        max-width: none;

        button {
            float: right;
        }
    }

    .field-wrapper,
    button,
    .option-quantity-wrap,
    label {
        display: inline-block;
        vertical-align: bottom;
    }

    .field-wrapper {
        width: 60px;
        margin-right: 20px;
        margin-bottom: 0;
    }
}
